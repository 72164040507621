import type { ContactOtherDataNodeLeaf, ContactOtherDataNodeDir } from './core';


export type ApplyOrderingInOtherDataArg = {
  node: ContactOtherDataNodeLeaf | ContactOtherDataNodeDir;
}
export type ApplyOrderingIdInOtherDataRtrn = ContactOtherDataNodeLeaf | ContactOtherDataNodeDir;

export const applyOrderingInOtherData = (arg: ApplyOrderingInOtherDataArg): ApplyOrderingIdInOtherDataRtrn => {
  const { node } = arg;
  if(node.type === 'leaf') return node;

  const leafChildren: ContactOtherDataNodeDir['children'] = node.children.filter(it => it.type === 'leaf');
  const dirChildren = node.children.filter(it => it.type === 'dir');
  const dirChildrenRecursed = dirChildren
    .map(child => applyOrderingInOtherData({node: child}))
    .sort((a, b) => {
      if(a.type !== 'dir' || b.type !== 'dir') return 1;

      const { pathArr: pathArrA } = a;
      const { pathArr: pathArrB } = b;
      if(pathArrA.length > pathArrB.length) return 1;
      if(pathArrA.length < pathArrB.length) return -1;


      // paths are of equal length
      for (let i = 0; i < pathArrA.length; i++) {
        const pathASegment = pathArrA[i];
        const pathASegmentNum = Number(pathASegment);
        const pathBSegment = pathArrB[i];
        const pathBSegmentNum = Number(pathBSegment);

        let rtrnOrder = ((): 1 | -1 => {
          // if both are numbers - compare them as numbers
          if(Number.isNaN(pathASegmentNum) === false && Number.isNaN(pathBSegmentNum) === false) {
            return pathASegmentNum >= pathBSegmentNum ? 1 : -1;
          }

          // both are most likely just plain strings - compare as such
          return pathASegment >= pathBSegment ? 1 : -1;
        })();


        // if rtrnOrder is already -1 that means that segment in A is less than
        // segment in B and no matter the outcome of other segments afterwards
        // A < B
        if(rtrnOrder === -1) return -1;

        // if it's 1 - we need to continue as that might change
      }

      // if we got here - rtrnOrder always ended up as 1
      return 1;
    });

  const nextChildren: ContactOtherDataNodeDir['children'] = leafChildren
    .sort((a, b) => a.label > b.label ? 1 : -1)
    .concat(dirChildrenRecursed);

  return { ...node, children: nextChildren };
};
