import type { ContactOtherDataNodeLeaf, ContactOtherDataNodeDir } from './core';
import { findByPredicateInOtherData } from './findByPredicate';


export type FindByIdInOtherDataArg = {
  node: ContactOtherDataNodeLeaf | ContactOtherDataNodeDir;
  id: string;
}
export type FindByIdInOtherDataRtrn = null | ContactOtherDataNodeLeaf | ContactOtherDataNodeDir;

export const findByIdInOtherData = (arg: FindByIdInOtherDataArg): FindByIdInOtherDataRtrn => (
  findByPredicateInOtherData({ node: arg.node, predicate: it => it.id === arg.id })
);
