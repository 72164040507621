import { scrollableMixin } from "@common/scrollbar";
import { createGlobalStyle } from "styled-components";
import { hexToHSL, system } from "theme";

export const EditorStyles = createGlobalStyle`
  [data-slate-editor="true"] {
    font-family: 'Libre Baskerville', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 2rem;

    &[data-slate-editor-id="rules"] {
      font-family: "Montserrat", 'Open Sans', sans-serif;
    }

    // NOTE for some reason this breaks arrow keys in firefox
    /*
    display: flex;
    flex-direction: column;
    */
    display: flex;
    flex-direction: column;
    gap: 1rem;

    /* min-height: 0; // allows flexbox to auto fill height */

    /* & > *:not(:last-child) {
      margin-bottom: 1rem;
    } */
    h1, h2, h3, h4, h5, h6 {
      margin: 0 !important
    }

    > * {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    > ol, > ul {
      > li {
        margin-left: 1.5rem;
      }
    }

    hr {
      padding: 0;
      margin: 1rem 0;
      /* background-color: red; */
    }

    ${scrollableMixin};
    overflow-x: hidden;


    --light-blue: rgb(0, 134, 211);
    --dark-blue: rgb(0, 16, 128);
    --blue: ${hexToHSL(system.blueLavendar, 35)};
    --green: ${hexToHSL(system.greenTea, 35)};
    --brown: rgb(121, 94, 38);
    --pink: ${hexToHSL(system.pinkLace)};
    --orange: ${hexToHSL(system.orangeFiesta)};
    --turquoise: ${hexToHSL(system.lilacLatte)};
    --red: ${hexToHSL(system.redBlush, 40)};
    /* --purple: rgb(175, 0, 219); */
    // purple slate 75% #8c8cf3
    --purple: hsl(240, 81%, 85%); //${system.purpleSlate};
    --purple-dark: hsl(240, 81%, 75%);

    @keyframes gradient-animation {
      0% {
        background-position: 0 0;
      }
      25% {
        background-position: 100% 0;
      }
      50% {
        background-position: 100% 100%;
      }
      75% {
        background-position: 0 100%;
      }
      100% {
        background-position: 0 0;
      }
    }

    @keyframes anim {
      0% {
        background-position: 0 0;
      }
      25% {
        background-position: 100% 100%;
      }
      50% {
        background-position: 0 0;
      }
      75% {
        background-position: 0 0;
      }
      100% {
        background-position: 0 0;
      }
    }

    @keyframes animate {
      to {
        background-position: 400% 0;
      }
    }

    .attribute {
      /* color: var(--light-blue); */

      /* Set up a basic appearance for the skeleton text */
      /* font-family: Arial, sans-serif;
      font-size:  16px;
      line-height:  1.5;
      color: transparent;
      background: linear-gradient(90deg, #f0f0f0  25%, #ddd  50%, #f0f0f0  75%);
      background-size:  200%  100%;
      animation: shimmer  2s ease-in-out infinite; */

      --bg-size: 400%;
      --color-stop-1: #0a0a0a; /* Replace with your first gradient color */
      --color-stop-2: #363636; /* Replace with your second gradient color */
      // TODO this will break the cursor indicator
      /* font-size:  2em; */
      /* background: linear-gradient(45deg, var(--color-stop-1), var(--color-stop-2), var(--color-stop-1), var(--color-stop-2), var(--color-stop-1)) 0 0 / var(--bg-size) 100%;
      background-size: var(--bg-size) var(--bg-size);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      animation: animate 10s linear infinite; */

      text-decoration: underline;
      text-decoration-color: #767676;
      text-decoration-style: dashed;
      text-underline-offset: 0.125rem;
      text-decoration-thickness: 1px;
    }

    .command-fn {
      /* color: var(--orange); */
      border-radius: 0.5rem;
      background-color: var(--purple);
      font-size: 0.75rem;
      font-weight: 600;
      color: black;
      padding: 0.1875rem 0.25rem;
      margin-right: 0.25rem;
      text-transform: capitalize;
    }

    .command {
      color: var(--purple-dark);
      font-weight: bold;
    }

    .literal {
      color: var(--red);
    }

    .comment {
      color: var(--green);
    }

    .relationship {
      color: var(--green);
    }

    .alias {
      color: var(--blue);
      font-weight: 500;
    }

    .error {
      // squiggly underline
      text-decoration: underline;
      text-decoration-color: var(--red);
      text-decoration-style: wavy;
      text-decoration-thickness: 1px;
    }

    .highlight {
      background-color: rgba(16, 16, 16, 0.1);
    }

    .tooltip {
      // we want this as the override
      background-color: rgba(0, 255, 0, 0.1) !important;
    }

  }

  .skeleton-text {
    /* Set up a basic appearance for the skeleton text */
    font-family: Arial, sans-serif;
    font-size:  16px;
    line-height:  1.5;
    color: transparent; /* Make the text invisible */
    background: linear-gradient(90deg, #f0f0f0  25%, #ddd  50%, #f0f0f0  75%);
    background-size:  200%  100%;
    animation: shimmer  2s ease-in-out infinite;
  }

  @keyframes shimmer {
    0% {
      background-position:  200%  0;
    }
    100% {
      background-position: -200%  0;
    }
  }

  .animated-gradient-text {
    --color-stop-1: #ff0000; /* Replace with your first gradient color */
    --color-stop-2: #0000ff; /* Replace with your second gradient color */
    font-size:  2em; /* Adjust font size as needed */
    background: linear-gradient(90deg, var(--color-stop-1), var(--color-stop-2));
    background-size:  200% auto;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: gradient-animation  3s ease infinite;
  }

`;
