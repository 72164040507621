import moment from "moment";
import { useTranslation } from "react-i18next";
import MaterialTable, { MTablePagination } from "@material-table/core"
import { Chip, Typography, TypographyProps } from "@material-ui/core";
import { useScope } from "redux/scope";
import { sessionService } from "services";
import { Dropdown, Flex, Label, Tooltip } from "@components";
import styled from "styled-components";
import { useCurrentRelease } from "@common";
import { find } from "lodash";
import AssistantIcon from '@material-ui/icons/Assistant';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ShortID } from "./utils";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { QueryParams } from "@imminently/immi-query";

const TableContainer = styled.div`
  /* height: calc(100% - 6rem); */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  >div {
    flex: 1;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .MuiToolbar-root {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${p => p.theme.palette.background.default};
    padding-left: 2rem;
    padding-right: 1rem;

    [class*="MTableToolbar-title"]>.MuiTypography-root {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      text-transform: none;
    }

    [class*="MTableToolbar-searchField"] {
      order: 2;
    }
    [class*="MTableToolbar-actions"] {
      order: 1;
    }
  }

  .MuiTableRow-root {
    border-top: 1px solid #e5e5e5;
  }

  .MuiTableRow-head {
    .MuiTableCell-head {
      padding: 0.5rem 0;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 200%;
    }
  }

  .MuiTableBody-root .MuiTableCell-root:not(.MuiTableCell-paddingNone) {
    padding: 0.75rem 0;
  }

  .MuiTableCell-root {
    padding: 0;
    border: none;
  }

  .MuiTableCell-root .MuiCollapse-root .MuiPaper-root {
    box-shadow: none;
    background-color: ${p => p.theme.palette.background.light};
    border-radius: 0;
    padding: 1rem;

    table {
      border-collapse: separate; // required for border-radius to work
      border-spacing: 0 0.25rem; // space between rows
    }

    .MuiTableRow-root {
      border-top: none;
      > .MuiTableCell-root:first-child {
        padding-left: 1rem !important;
      }
    }

    .MuiTableHead-root > .MuiTableRow-root {
      > .MuiTableCell-head {
        padding: 0;
        background: none;
      }
    }

    .MuiTableBody-root > .MuiTableRow-root {
      background-color: ${p => p.theme.palette.background.default};

      > td.MuiTableCell-body {
        padding: 0.5rem 0;
        border: 1px solid ${p => p.theme.palette.divider};
        border-right: none;
        border-left: none;

        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          border-left: 1px solid ${p => p.theme.palette.divider};
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          border-right: 1px solid ${p => p.theme.palette.divider};
        }
      }
    }
  }
`;

const OptionSelect = styled(Dropdown)`
  &.MuiInputBase-root {
    /* background: green; */
    padding: 0 0.5rem !important;

    .MuiSelect-root.MuiSelect-select.MuiSelect-outlined {
      padding-right: 24px !important;
    }
  }
`;

const formatDate = (date: string) => {
  // use moment to format date as DD MMM, YYYY
  return moment(date).format("DD MMM, YYYY");
};

const DateCell = (field: string) => (data: any) => {
  return formatDate(data[field]);
};

const LabelCell = (field: string, color: string | ((val: string) => string)) => (data: any) => {
  const { t } = useTranslation();
  const label = data[field];
  return (
    <Label color={typeof color === "string" ? color : color(label)}>
      {t(`tasks.status.${label ?? "in-progress"}`)}
    </Label>
  );
};

const ShortField = ({ value, textWidth, ...props }: { value: string, textWidth?: number } & TypographyProps) => (
  <Tooltip title={value} placement="right">
    <Typography {...props}>
      {`${value.slice(0, textWidth || 8)}...`}
    </Typography>
  </Tooltip>
);

const InteractionTable = ({ sessionId }: { sessionId: string; }) => {
  const { t } = useTranslation();
  const release = useCurrentRelease();

  const { data, isError, isLoading } = sessionService.useGetOne(sessionId);
  if (!release) return;
  if (isError) {
    return <div>Error...</div>
  }
  const rule_graph = release.rule_graph;

  const interactions = data?.interactions || [];
  return (
    <MaterialTable
      columns={[
        {
          title: 'Interaction',
          field: 'mode',
          render: (data) => {
            switch (data.mode) {
              case "interview":
                return "Interview";
              case "api":
                return "API";
              case 'generative':
                return 'Generative Chat';
              default:
                return "Interaction";
            }
          },
        },
        {
          title: 'Last Updated', field: 'created', render: (data) => {
            return (
              <Tooltip title={`Created: ${data.created}`}>
                <span>{moment(data.lastModified).fromNow()}</span>
              </Tooltip>
            )
          }, minWidth: 130
        },
        // { title: 'Last updated', field: 'lastModified' },
        {
          title: 'Type', field: 'mode', render: (data) => {
            let icon;
            switch (data.mode) {
              case 'interview':
                return (
                  <Tooltip title="Interview">
                    <Chip icon={<ListAltIcon />} label={data.interviewName} />
                  </Tooltip>
                );
              // @ts-ignore
              case 'generative':
                icon = <AssistantIcon fontSize="small" />;
              default:
                if (!icon) icon = <SettingsIcon fontSize="small" />;
                let node = find(rule_graph.nodes, { v: data.goal });
                if (node) {
                  let desc = node.value.description;
                  if (desc.length > 32) desc = desc.substr(0, 32) + '...';
                  return (
                    <Tooltip title={`Type: ${data.mode} | Goal: ${node.value.description}`}>
                      <Chip icon={icon} label={desc} />
                    </Tooltip>
                  )
                }
                return data.mode;
            }
          }
        },
        { title: 'Status', field: 'status', render: LabelCell('status', 'purpleSlate'), minWidth: 130 },
      ]}
      data={interactions}
      title={t('monitor.interactions')}
      isLoading={isLoading}
      options={{
        toolbar: false,
        search: false,
        paging: false,
      }}
    />
  );
};

export type SessionTableProps = {
  filter?: QueryParams;
  onSessionClick: (session: any) => void;
};

const SessionID = styled(ShortID)`
  color: ${p => p.theme.palette.secondary.dark};
  text-transform: uppercase;
  font-weight: 600;
`;

export const SessionTable = ({ filter, onSessionClick }: SessionTableProps) => {
  const { project, release } = useScope();
  const fullRelease = useFullRelease();

  const { data: sessions, total, query, pagination } = sessionService.useResources({
    filter: filter ?? {
      model: project,
      release,
      sort: { field: "lastModified", order: "desc" } as const,
    }
  });

  const columns = [
    { title: 'Session', field: 'id', render: (data) => <SessionID value={data.id} prefix="#" />, minWidth: 100 },
    //{ title: 'Created', field: 'created', render: DateCell('created'), minWidth: 130 },
    {
      title: 'Last updated', field: 'lastModified', render: (data) => {
        //DateCell('lastModified')
        return (
          <Tooltip title={`Created: ${data.created}`}>
            <span>{moment(data.lastModified).fromNow()}</span>
          </Tooltip>
        )
      }, minWidth: 130
    },
    {
      title: 'Interactions', field: 'lastInteractionName', render: (data) => {
        return (
          <Flex gridGap="0.5rem">
            <Tooltip title="Total Interactions">
              <Chip label={data.totalInteractions} />
            </Tooltip>
            <Tooltip title="Total Active Interactions">
              <Chip color="primary" label={data.totalActiveInteractions} />
            </Tooltip>
          </Flex>
        )
      }
    },
    {
      title: 'Goal', field: 'id', render: (data) => {
        if (!fullRelease) return '';
        if (!data.goal) return 'No goal';
        let goal = data.goal.includes('/') ? data.goal.split('/').pop() : data.goal;
        let node = find(fullRelease.rule_graph.nodes, { v: goal });
        if (node) {
          return (
            <ShortField value={node.value.description ?? node.value.id} textWidth={32} />
          );
        }
        return '';
      }, minWidth: 300
    },
    { title: 'Status', field: 'status', render: LabelCell('status', 'purpleSlate'), minWidth: 130 },
  ];

  const components = {
    Pagination: ({ classes, ...props }: any) => (
      <Flex justifyContent="flex-end" alignItems="center" padding="0.5rem">
        <OptionSelect
          value={pagination.pagination.perPage}
          items={props.rowsPerPageOptions}
          onChange={(e) => pagination.setPerPage(e.target.value)}
        />
        <MTablePagination
          {...props}
          count={total}
          page={pagination.pagination.page}
          rowsPerPage={pagination.pagination.perPage}
          onPageChange={(_, page) => {
            pagination.setPage(page);
          }}
        />
      </Flex>
    )
  };

  return (
    <TableContainer>
      <MaterialTable
        columns={columns}
        data={sessions}
        title="Sessions"
        isLoading={!fullRelease || query.isLoading || query.isFetching}
        detailPanel={data => <InteractionTable sessionId={data.rowData.id} />}
        onRowClick={(_, rowData) => {
          onSessionClick(rowData);
        }}
        page={pagination.pagination.page}
        options={{
          pageSize: pagination.pagination.perPage,
          searchFieldVariant: "outlined",
          detailPanelType: "single",
        }}
        components={components}
        actions={[{
          icon: () => <RefreshIcon />,
          onClick: () => query.refetch(),
          isFreeAction: true,
        }]}
      />
    </TableContainer>
  )
}
