import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sessionService } from 'services';
import styled from 'styled-components';
import { colors } from 'theme';

import { buildGraph, EmptyContent, JsonEditorV2, scrollableMixin, uncompressGraph } from '@common';
import { useFullScope } from '@common/hooks';
import { useNotification } from '@common/notifications';
import { StatusLabel } from '@common/tasks/TaskComponents';
import { Button, Flex, Label, Stack } from '@components';
import { Tabs, TabsContent, TabsList, TabsTrigger, TabValue } from '@components/radix';
import graphlib from '@dagrejs/graphlib';
import {
  BuildIcon, CitizenshipIcon, DocumentsIcon, ExternallinkIcon, InteractionsIcon, LoadingDots, SourcecodeIcon
} from '@icons';
import { Breadcrumbs, Chip, IconButton, Typography } from '@material-ui/core';
import { DialogClose, DialogResize, useBasicModal, useModalContext } from '@modals/Modals';
import { ParsedGraphContext, useParsedGraph } from '@pages/models/release/GraphContext';
import { DiscIcon } from '@radix-ui/react-icons';

import { DateTime, ShortID } from './components';
import { ContactsPanel } from './components/ContactsPanel';
import { DecisionPanel } from './components/DecisionPanel';
import { InteractionsPanel } from './components/InteractionsPanel';
import { VisualisationsPanel } from './components/VisualisationsPanel';
import { DataPanel } from './components/DataPanel';

const TabToolbar = styled(TabsList)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1rem 0 1rem;
  /* padding-top: 1.5rem; */
  border-bottom: 1px solid ${(p) => p.theme.palette.divider};

  button {
    position: relative;
    border-radius: 0.25rem;

    &:hover {
      background-color: ${(p) => p.theme.palette.background.hover};
    }

    &[data-state="active"] {
      ::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${(p) => p.theme.palette.primary.main};
      }
    }
  }
`;

const TabRoot = styled(Tabs)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabContent = styled(TabsContent)`
  flex-direction: column;
  padding: 0;
  outline: none;
  position: relative;

  ${scrollableMixin};

  &[data-state="active"] {
    display: flex;
  }
`;

// styled vertical separator
const Separator = styled.div`
  width: 1px;
  height: 1rem;
  background-color: ${(p) => p.theme.palette.background.darkBorder};
  margin: 0 0.5rem;
`;

const ScopeLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #000;
  font-size: 0.875rem;
  font-family: 'Nunito';
  font-weight: 500;
  line-height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const useSessionGraph = (session: any) => {
  const ruleGraph = useParsedGraph();
  // initially start with rule graph, that way its not null
  const [graph, setGraph] = useState<any>(null);

  useEffect(() => {
    console.log('useSessionGraph', session);
    if (session && session.graph && !session.graph._nodes) {
      try {
        const uncompressed = uncompressGraph(session.graph);
        const sessionGraph = graphlib.json.read(uncompressed);
        const combined = buildGraph(ruleGraph as any, sessionGraph as any);
        console.log('combining', { uncompressed, sessionGraph, ruleGraph, combined });
        setGraph(combined);
      } catch (error) {
        console.error('Failed to build graph', error);
      }
    }
  }, [session]);

  return graph;
};

const ShowRawSessionBtn = styled(IconButton)`
  margin-left: auto;
`;

export const SessionModal = ({ sessionId }: { sessionId: string }) => {
  const notify = useNotification();
  const { t } = useTranslation();
  const { workspace, project, release } = useFullScope();
  const { fullScreen } = useModalContext();

  const { data: session, isError, isLoading } = sessionService.useGetOne(sessionId);
  const sessionGraph = useSessionGraph(session);
  if (session && sessionGraph) {
    session.graph = sessionGraph;
  }
  const pushBasicModal = useBasicModal();
  const openRawSessionModal = useCallback(() => {
    pushBasicModal({
      title: 'Session',
      maxWidth: 'xl',
      children: (
        <Flex style={{ padding: '1rem' }}>
          <JsonEditorV2
            v={JSON.stringify(session, null, 2)}
            readOnly
            withCopy={true}
          />
        </Flex>
      ),
      dialogContentStyle: { display: 'flex' },
    });
  }, [pushBasicModal, session]);

  if (isLoading || sessionGraph === null) {
    return <EmptyContent messages={["Loading session", <LoadingDots key="loading" />]} img="" style={{ width: "400px", height: "300px" }} />;
  }

  if (isError) {
    return <EmptyContent messages={["Failed to load session"]} img="" style={{ width: "400px", height: "300px" }} />;
  }

  const tabs: TabValue[] = [
    // {
    //   value: "raw",
    //   label: "Raw data",
    //   content: <Box>
    //     <pre>
    //       {JSON.stringify(session, null, 2)}
    //     </pre>
    //   </Box>,
    // },
    {
      value: "decision",
      label: "Decision",
      content: <DecisionPanel session={session} />,
    },
    {
      value: "interactions",
      label: "Tasks",
      content: <InteractionsPanel session={session} />,
    },
    {
      value: "contacts",
      label: "Contacts",
      content: <ContactsPanel session={session} />,
    },
    {
      value: "data",
      label: "Data",
      content: <DataPanel session={session} />,
    },
    {
      value: "visuals",
      label: "Visualisations",
      content: <VisualisationsPanel session={session} />,
    }
  ];

  const lastInteraction = session.interactions.find((i: any) => i.id === session.lastInteractionId);

  // TODO is there a better way to calculate the height? modals are hard
  const headerHeight = 40 + 62 + 58 + 50; // combined size in px of the header, info, status bar and tabs
  // of not fullscreen, include the 2rem margin around the modal
  const height = fullScreen ? `calc(100vh - ${headerHeight}px)` : `calc(100vh - 4rem - ${headerHeight}px)`;

  return (
    <ParsedGraphContext.Provider value={sessionGraph}>
      <Flex justifyContent="space-between" padding="0.5rem 1rem 0 1rem">
        <Breadcrumbs aria-label="breadcrumb">
          <ScopeLabel>
            <CitizenshipIcon />
            <span>{workspace?.name}</span>
          </ScopeLabel>
          <ScopeLabel>
            <BuildIcon />
            <span>{project?.name}</span>
          </ScopeLabel>
          <ScopeLabel>
            <DocumentsIcon />
            <span>Release {release?.releaseNo}</span>
          </ScopeLabel>
        </Breadcrumbs>
        <Flex>
          <DialogResize />
          <DialogClose />
        </Flex>
      </Flex>
      <Stack minWidth="calc(100vw - 4rem)">
        <Flex gridGap="1rem" paddingY="1rem" paddingX="1rem" style={{ color: colors.ultimateGrey, alignItems: "baseline", borderTop: "1px solid #e5e5e5", maxHeight: "4rem" }}>
          <Typography variant="h3" color="textPrimary">Application</Typography>
          <ShortID value={session.id} prefix="#" variant="h6" color="inherit" />
          <ExternallinkIcon style={{ color: colors.kindaBlack, alignSelf: "center" }} />
          <DateTime hideIcon value={session.lastModified} prefix="Last update:" />
          <ShowRawSessionBtn onClick={openRawSessionModal}>
            <SourcecodeIcon />
          </ShowRawSessionBtn>
        </Flex>

        <Flex gridGap="0.5rem" paddingY="1rem" paddingX="1rem" style={{ color: colors.ultimateGrey, borderTop: "1px solid #e5e5e5" }}>
          <DiscIcon width={24} height={24} />
          <Typography color="inherit">Status:</Typography>
          <StatusLabel status={session.status} />
          <Separator />
          <InteractionsIcon />
          <Typography color="inherit">Interactions:</Typography>
          <Label color={colors.moreGrey} >{session.totalInteractions}</Label>
          <Separator />
          {
            session.lastInteractionId && session.interactions?.length > 0
              ? (
                <>
                  <Typography color="inherit">Latest interaction:</Typography>
                  <Chip
                    variant='outlined'
                    style={{ borderColor: colors.kindaBlack, fontWeight: 600 }}
                    label={lastInteraction?.mode ?? "Invalid last interaction"} />
                </>
              )
              : null
          }
        </Flex>
        <TabRoot defaultValue="decision">
          <TabToolbar style={{ borderTop: "1px solid #e5e5e5" }}>
            {tabs.map((tab) => (
              <TabsTrigger asChild key={`tab-${tab.value}`} value={tab.value}>
                <Button type="tertiary" size="small">{tab.label}</Button>
              </TabsTrigger>
            ))}
          </TabToolbar>
          {
            tabs.map((tab) => (
              <TabContent key={`content-${tab.value}`} value={tab.value} style={{ height }}>
                {tab.content}
              </TabContent>
            ))
          }
        </TabRoot>
      </Stack>
    </ParsedGraphContext.Provider>
  );
};

export const useSessionModal = () => {
  const openBasicModal = useBasicModal();

  return (session: any) => {
    openBasicModal({
      header: null, // use custom header
      maxWidth: false,
      allowResize: true,
      // fullScreen: true,
      children: <SessionModal sessionId={session.id} />,
      // dialog: { style: { padding: 0 } }
    });
  }
};
