import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Contact, contactsService } from 'services';
import styled from 'styled-components';

import { useFullRelease } from '@common/hooks_useFullRelease';
import { Dropdown, Flex, Label, Stack, Tooltip } from '@components';
import MaterialTable, { MTablePagination } from '@material-table/core';
import { Typography, TypographyProps } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { formatUserFullName, getValueAtDate } from '@common';

const TableContainer = styled.div`
  /* height: calc(100% - 6rem); */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  flex: 1;

  >div {
    flex: 1;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .MuiToolbar-root {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${p => p.theme.palette.background.default};
    padding-left: 2rem;
    padding-right: 1rem;

    [class*="MTableToolbar-title"]>.MuiTypography-root {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      text-transform: none;
    }

    [class*="MTableToolbar-searchField"] {
      order: 2;
    }
    [class*="MTableToolbar-actions"] {
      order: 1;
    }
  }

  .MuiTableRow-root {
    border-top: 1px solid #e5e5e5;
  }

  .MuiTableRow-head {
    .MuiTableCell-head {
      padding: 0.5rem 0;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 200%;
      &:first-child {
        padding-left: 2rem;
      }
    }
  }

  .MuiTableBody-root .MuiTableCell-root:not(.MuiTableCell-paddingNone) {
    padding: 0.75rem 0;
    &:first-child {
        padding-left: 2rem;
      }
  }

  .MuiTableCell-root {
    padding: 0;
    border: none;
  }

  .MuiTableCell-root .MuiCollapse-root .MuiPaper-root {
    box-shadow: none;
    background-color: ${p => p.theme.palette.background.light};
    border-radius: 0;
    padding: 1rem;

    table {
      border-collapse: separate; // required for border-radius to work
      border-spacing: 0 0.25rem; // space between rows
    }

    .MuiTableRow-root {
      border-top: none;
      > .MuiTableCell-root:first-child {
        padding-left: 1rem !important;
      }
    }

    .MuiTableHead-root > .MuiTableRow-root {
      > .MuiTableCell-head {
        padding: 0;
        background: none;
      }
    }

    .MuiTableBody-root > .MuiTableRow-root {
      background-color: ${p => p.theme.palette.background.default};

      > td.MuiTableCell-body {
        padding: 0.5rem 0;
        border: 1px solid ${p => p.theme.palette.divider};
        border-right: none;
        border-left: none;

        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          border-left: 1px solid ${p => p.theme.palette.divider};
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          border-right: 1px solid ${p => p.theme.palette.divider};
        }
      }
    }
  }
`;

const OptionSelect = styled(Dropdown)`
  &.MuiInputBase-root {
    /* background: green; */
    padding: 0 0.5rem !important;

    .MuiSelect-root.MuiSelect-select.MuiSelect-outlined {
      padding-right: 24px !important;
    }
  }
`;

const formatDate = (date: string) => {
  // use moment to format date as DD MMM, YYYY
  return moment(date).format("DD MMM, YYYY");
};

const NameCell = (data: any) => {
  const contact: Contact = data;
  // these may not exist
  const firstName = getValueAtDate(contact.firstName, new Date()) as string;
  const lastName = getValueAtDate(contact.lastName, new Date()) as string;
  const fullName = formatUserFullName({ firstName, lastName }).trim();
  const hasName = fullName.length > 0;
  return (
    <Stack>
      <Typography>{hasName ? fullName : contact.id}</Typography>
      {
        hasName ? (
          <Typography variant="caption" color="textSecondary">{contact.id}</Typography>
        ) : null
      }
    </Stack>
  );
};

const DateCell = (field: string, label: string) => (data: any) => {
  const date = data[field];
  return (
    <Tooltip title={`${label}: ${date}`}>
      <span>{moment(date).fromNow()}</span>
    </Tooltip>
  )
};

export type ContactTableProps = {
  onClick: (contact: any) => void;
};

export const ContactTable = ({ onClick }: ContactTableProps) => {
  const fullRelease = useFullRelease();

  const { data: contacts, total, query, pagination } = contactsService.useResources({
    filter: {
      sort: { field: "lastModified", order: "desc" } as const,
    }
  });

  const columns = [
    {
      title: 'Contact', field: 'id', render: NameCell, minWidth: 100
    },
    {
      title: 'Created', field: 'created', render: DateCell('created', "Created"), minWidth: 130
    },
    {
      title: 'Last updated', field: 'lastModified', render: DateCell('lastModified', "Last updated"), minWidth: 130
    },
  ];

  const components = {
    Pagination: ({ classes, ...props }: any) => (
      <Flex justifyContent="flex-end" alignItems="center" padding="0.5rem">
        <OptionSelect
          value={pagination.pagination.perPage}
          items={props.rowsPerPageOptions}
          onChange={(e) => pagination.setPerPage(e.target.value)}
        />
        <MTablePagination
          {...props}
          count={total}
          page={pagination.pagination.page}
          rowsPerPage={pagination.pagination.perPage}
          onPageChange={(_, page) => {
            pagination.setPage(page);
          }}
        />
      </Flex>
    )
  };

  return (
    <TableContainer>
      <MaterialTable
        columns={columns}
        data={contacts}
        title="Contacts"
        isLoading={!fullRelease || query.isLoading || query.isFetching}
        onRowClick={(_, rowData) => {
          onClick(rowData);
        }}
        page={pagination.pagination.page}
        options={{
          pageSize: pagination.pagination.perPage,
          searchFieldVariant: "outlined",
          detailPanelType: "single",
        }}
        components={components}
        actions={[{
          icon: () => <RefreshIcon />,
          onClick: () => query.refetch(),
          isFreeAction: true,
        }]}
      />
    </TableContainer>
  )
};
