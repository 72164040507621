/* eslint-disable react/require-default-props */
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import cn from 'clsx';
import { TabPanel } from './TabPanel';
import { withStyles } from '../../common/Styling';
import * as CommonStyling from '../../common/styles';

import stl from './tabs.module.scss';

interface TabViewProps {
  selectedTab?: number;
  content: {label: string, component: React.ComponentType | React.ReactNode, disabled?: boolean}[];
  containerClass?: string;
  onSelectedTabChange?: (index: number) => void;
  tabPanelClassName?: string;
}

const TabView = (props: TabViewProps) => {

  const { content, onSelectedTabChange, selectedTab: propsSelectedTab, containerClass, tabPanelClassName } = props;
  const classes = (props as any).classes;
  const [stateSelectedTab, setStateSelectedTab] = React.useState<number>(0);
  const numTabs = content?.length ?? 1;

  const selectedTab = propsSelectedTab ?? stateSelectedTab;

  const tabProps = (index: number) => ({
    id: `simple-tab-${ index }`,
    'aria-controls': `simple-tabpanel-${ index }`,
    disableRipple: true,
    className: cn(classes.tab, stl.tab),
    component: 'div',
    style: { width: `calc(100% / ${ numTabs })` },
  });

  const changeTab = (event: React.ChangeEvent<Record<string, never>>, value: number) => {
    setStateSelectedTab(value);
    onSelectedTabChange?.(value);
  };

  //
  // rendering

  return(
    <>
      <div role="tablist" className={cn(classes.root, stl.root, containerClass)}>
        <Tabs
          value={selectedTab}
          onChange={changeTab}
          aria-label='tabs'
          TabIndicatorProps={{ className: classes.indicator }}
          className={cn(classes.tabContainer, stl.tabs)}
        >
          {
            content.map(({ label, disabled }, idx) => <Tab disabled={disabled} key={idx} label={label} {...tabProps(idx)} />)
          }
        </Tabs>
      </div>
      {
        content.map(({ component }, idx) => (
          <TabPanel
            key={idx}
            index={idx}
            currIndex={selectedTab}
            className={tabPanelClassName}
          >
            {component as any}
          </TabPanel>
        ),
        )
      }
    </>
  );
};

export default withStyles(CommonStyling.MuiTabStyle)(TabView);
