import { useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Contact } from 'services';
import styled from 'styled-components';

import { ContactPanel } from './ContactPanel';
import { ContactTable } from './ContactTable';
import { ContactMappingProvider } from './MappingContext';

const PanelGroupView = styled(PanelGroup)`
  background-color: ${p => p.theme.palette.background.default};
  border-top: 1px solid ${p => p.theme.palette.background.border};

  [data-resize-handle] {
    justify-content: stretch;
    align-items: stretch;
    display: flex;
    outline: none;
    flex: 0 0 1px;
    transition: background-color 0.2s linear 0s;
    background-color: ${p => p.theme.palette.background.border};
  }

  [data-resize-handle-active="pointer"] {
    background-color: ${p => p.theme.palette.primary.main};
    flex: 0 0 0.25rem;
  }

  [data-panel] {
  }
`;

export const Contacts = () => {
  const [contact, setContact] = useState<Contact | null>(null);

  const onContactClick = (contact: Contact) => {
    console.log('Contact clicked:', contact);
    setContact(contact);
  };

  const onClose = () => {
    setContact(null);
  }

  return (
    <ContactMappingProvider>
      <PanelGroupView autoSaveId="immi.contacts" direction="horizontal">
        <Panel id="contacts-table" order={1}>
          <ContactTable onClick={onContactClick} />
        </Panel>
        {
          contact ? (
            <>
              <PanelResizeHandle />
              <Panel id="contacts-panel" order={2} minSize={30} maxSize={80} style={{ display: "flex", flexDirection: "column", position: "relative", overflow: "visible" }}>
                <ContactPanel contact={contact} onClose={onClose} />
              </Panel>
            </>
          ) : null
        }
      </PanelGroupView>
    </ContactMappingProvider>
  );
};
