import React from "react";
import {
  useOpenContactsMappingModal,
  OpenContactsMappingModalFArg,
} from '../../components/ContactsMappingModal'
import { PrimaryButton } from "@components";


export const JSX = React.createElement(() => {
  const openContactsMappingModal = useOpenContactsMappingModal();

  return (
    <PrimaryButton
      onClick={() => openContactsMappingModal({
        fieldsOnContactObject: [],
        getMapping: () => Promise.resolve({ entity: '', fields: {} }),
        save: console.info,
      })}
      style={{ alignSelf: 'flex-start' }}
    >
      Open Contacts Mapping
    </PrimaryButton>
  );
});
