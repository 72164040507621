import { useState } from 'react';
import { UseQueryResult } from 'react-query';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Contact, contactsService } from 'services';
import styled from 'styled-components';

import {
  formatUserFullName, getValueAtDate, isTemporal, PrimitiveValue, ScopePath, scrollableMixin,
  TemporalValue
} from '@common';
import { Button } from '@components';
import { ContactDataTree } from '@components/CaseViewContacts/OtherDataTree';
import { ContactDataTreeCtxValue } from '@components/CaseViewContacts/OtherDataTree_Ctx';
import { Flex, Stack } from '@components/meta';
import { Tabs, TabsContent, TabsList, TabsTrigger, TabValue } from '@components/radix';
import { TemporalValueTable } from '@components/TemporalValue/TemporalValueModal';
import { CloseIcon } from '@icons';
import { BackendResource } from '@imminently/immi-query';
import { IconButton, Typography } from '@material-ui/core';
import { SessionTable } from '@pages/sessions/components/SessionTable';
import { useSessionModal } from '@pages/sessions/SessionModal';

import { useContactMappings } from './MappingContext';

const TabToolbar = styled(TabsList)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1rem 0 1rem;
  /* padding-top: 1.5rem; */
  border-bottom: 1px solid ${(p) => p.theme.palette.divider};

  button {
    position: relative;
    border-radius: 0.25rem;

    &:hover {
      background-color: ${(p) => p.theme.palette.background.hover};
    }

    &[data-state="active"] {
      ::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${(p) => p.theme.palette.primary.main};
      }
    }
  }
`;

const TabRoot = styled(Tabs)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabContent = styled(TabsContent)`
  flex-direction: column;
  padding: 0;
  outline: none;
  position: relative;

  ${scrollableMixin};

  &[data-state="active"] {
    display: flex;
  }
`;

type ContactData = Record<string, TemporalValue | PrimitiveValue>;
type ContactResource = BackendResource<Contact & ContactData>;

const Header = styled(Flex)`
  gap: 0.5rem;
  padding: 1rem 1rem;
  height: calc(4rem + 1px); // random +1 to line up with table
  border-bottom: 1px solid ${p => p.theme.palette.background.border};
  button:last-child {
    margin-left: auto;
  }
`;

const PanelGroupView = styled(PanelGroup)`
  background-color: ${p => p.theme.palette.background.default};

  [data-resize-handle] {
    justify-content: stretch;
    align-items: stretch;
    display: flex;
    outline: none;
    flex: 0 0 1px;
    transition: background-color 0.2s linear 0s;
    background-color: ${p => p.theme.palette.background.border};
  }

  [data-resize-handle-active="pointer"] {
    background-color: ${p => p.theme.palette.primary.main};
    flex: 0 0 0.25rem;
  }

  [data-panel] {
  }
`;

const getContactName = (contact: Contact) => {
  // these may not exist
  const firstName = getValueAtDate(contact.firstName, new Date()) as string;
  const lastName = getValueAtDate(contact.lastName, new Date()) as string;
  return formatUserFullName({ firstName, lastName }).trim();
};

const ContactHeader = ({ contact, onClose }: { contact: Contact; onClose: () => void; }) => {
  const name = getContactName(contact);
  const hasName = name.length > 0;
  return (
    <Header>
      <Typography variant="h5">{hasName ? name : contact.id}</Typography>
      {
        hasName ? (
          <Typography variant="caption" color="textSecondary">{contact.id}</Typography>
        ) : null
      }
      <IconButton onClick={onClose}><CloseIcon /></IconButton>
    </Header>
  );
};

const useFullContact = (contact: Contact) => {
  return contactsService.useGetOne(contact.id) as UseQueryResult<ContactResource>;
};

const getTemporalValues = (contact: ContactResource, omit: string[] = []) => {
  const values: Record<string, TemporalValue> = {};
  for (const key in contact) {
    if (omit.includes(key)) continue;
    const value = contact[key];
    if (value && isTemporal(value)) {
      // we know its a TemporalValue as thats what the contact is giving
      values[key] = value;
    }
  }
  return values;
};

const FieldMetadata = ({ attribute, value }: { attribute: string; value: TemporalValue; }) => {
  const { getFieldMapping } = useContactMappings();
  const mapping = getFieldMapping(attribute);

  return (
    <Stack my="1rem" gridGap="1rem" px="1rem">
      <Typography variant="h6">History</Typography>
      <TemporalValueTable value={value} />
      {
        mapping !== null ? (
          <>
            <Typography variant="h6">Sources</Typography>
            <table>
              <tbody>
                {mapping.from.map((m) => (
                  <tr key={m.release.id}>
                    <td><ScopePath link workspace={m.workspace} project={m.project} release={m.release} /></td>
                    <td>{attribute}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : null
      }
    </Stack>
  );
};

const ContactDataTable = ({ contact }: { contact: Contact; }) => {
  const { data: fullContact, isLoading, isError } = useFullContact(contact);
  const [selectedField, setSelectedField] = useState<string | null>(null);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError) return <Typography>Error loading contact</Typography>;

  const values = getTemporalValues(fullContact as ContactResource, ['firstName', 'lastName']);
  // const { width, height, ref } = useResizeObserver();
  const tree: ContactDataTreeCtxValue['tree'] = {
    id: '',
    label: '',
    path: '', // global
    pathArr: [],
    children: Object.entries(values).map(([key, value]) => ({
      id: key,
      label: key,
      type: 'leaf',
      value,
    })),
    type: 'dir',
  };

  const onSelectField = (field: string) => {
    console.log('Selected:', field);
    setSelectedField(field);
  };

  const close = () => {
    setSelectedField(null);
  };

  return (
    // <div ref={ref} >
    <PanelGroupView direction="horizontal">
      <Panel id="contacts-tree" order={1}>
        <ContactDataTree tree={tree} onSelectField={onSelectField} />
      </Panel>
      {
        selectedField ? (
          <>
            <PanelResizeHandle disabled={true} />
            <Panel id="field-info" order={2} defaultSize={50} style={{ display: "flex", flexDirection: "column", position: "relative", overflow: "visible" }}>
              <Stack>
                <Header>
                  <Typography variant="h5">{selectedField}</Typography>
                  <IconButton onClick={close}><CloseIcon /></IconButton>
                </Header>
              </Stack>
              <FieldMetadata attribute={selectedField} value={values[selectedField]} />
            </Panel>
          </>
        ) : null
      }
    </PanelGroupView>
    // </div>
  );
};

export const ContactPanel = ({ contact, onClose }: { contact: Contact; onClose: () => void; }) => {
  const openSessionModal = useSessionModal();

  const tabs: TabValue[] = [
    {
      value: 'data',
      label: 'Data',
      content: (
        <ContactDataTable contact={contact} />
      ),
    },
    {
      value: 'sessions',
      label: 'Sessions',
      content: <SessionTable filter={{ contacts: `$member=${contact.id}` }} onSessionClick={openSessionModal} />,
    }
  ];

  // ref={ref} style={{ width, height }}
  return (
    <Stack key={contact.id}>
      <ContactHeader contact={contact} onClose={onClose} />
      <TabRoot defaultValue={tabs[0].value}>
        <TabToolbar>
          {tabs.map((tab) => (
            <TabsTrigger asChild key={`tab-${tab.value}`} value={tab.value}>
              <Button type="tertiary" size="small">{tab.label}</Button>
            </TabsTrigger>
          ))}
        </TabToolbar>
        {
          tabs.map((tab) => (
            <TabContent key={`content-${tab.value}`} value={tab.value}>
              {tab.content}
            </TabContent>
          ))
        }
      </TabRoot>
    </Stack>
  );
};