/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable key-spacing */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable computed-property-spacing */
/* eslint-disable no-multi-spaces */
import React      from 'react';
import Box        from '@material-ui/core/Box';

interface TabPanelProps {
  index     : number;
  currIndex : number;
  children? : React.ReactNode
  className?: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ index, currIndex, children, className }): JSX.Element => {

  return(
    <div
      role='tabpanel'
      hidden={currIndex !== index}
      id={`simple-tabpanel-${ index }`}
      aria-labelledby={`simple-tab-${ index }`}
      style={{ width: '100%', flexGrow: 1 }}
      className={className}
    >
      {currIndex === index && (
        <Box
          component='div'
          mt={4}
          width='100%'
          height='calc(100% - 1rem)'
        >
          {children}
        </Box>
      )}
    </div>
  );
};
