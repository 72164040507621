import produce from "immer";
import type { GraphNode } from "@packages/commons";
import type { ContactOtherDataNodeDir, ContactOtherDataNodeLeaf } from "./core"
import { findByPathInOtherData } from "./findByPath";
import { START_TIME } from "@components/TemporalValue/TempTimeline/redux";


export type AddNodesToOtherDataArg = {
  node: ContactOtherDataNodeDir;
  graphNodes: GraphNode[];
};
export type AddNodesToOtherDataRtrn = ContactOtherDataNodeDir;

export const addNodesToOtherData = (arg: AddNodesToOtherDataArg): AddNodesToOtherDataRtrn => {
  const { node, graphNodes } = arg;

  return produce(node, draft => {
    graphNodes.forEach(graphNode => {
      const { path } = graphNode;
      const value = graphNode.derived || graphNode.input;
      const newNode: ContactOtherDataNodeLeaf = {
        id: graphNode.id,
        label: graphNode.description || '',
        type: 'leaf',
        value: value,
      };

      if(!path || path === 'global') {
        draft.children.push(newNode);

        return;
      }

      const maybeMatched = findByPathInOtherData({ node: draft, path: path.split('/').slice(0, -1).join('/') });
      if(maybeMatched === null) return;

      maybeMatched.children.push(newNode);
    });
  });
}
