import {
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TD,
  type HotkeyPlugin,
  Plate,
  type PlatePlugin,
  type TEditableProps,
  TEditor,
  type TNode,
  createComboboxPlugin,
  createExitBreakPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createPlateUI,
  createPluginFactory,
  createPlugins,
  getNextNode,
  getNode,
  removeNodes,
  withPlaceholders,
} from "@udecode/plate";
import React from "react";
import styles from "./RuleEditor.module.scss";

import { v4 as uuidv4 } from "uuid";

import { createCopyPastePlugin } from "@common/editor/components/copyPaste/copyPaste";
import clsx from "clsx";
import i18next from "i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { type BaseEditor, Transforms } from "slate";
import { ELEMENT_RULE, Rule, createOperatorPlugin, createRulePlugin } from "../components";
import { TableCell, TableElem, createDecisionTablesPlugin } from "../components/decisionTable";
import { createConnectorPlugin } from "../components/operator/connector";
import { paragraphEnter, paragraphShiftEnter, paragraphTabbing } from "../components/paragraph/plugins";
import { createRuleBreakPlugin } from "../components/ruleBreak";
import { useSyntaxHighlighter } from "../components/syntax";
import { EditorActiveContext } from "../components/syntax/EditorActiveContext";
import {
  AttributeCombobox,
  EnumerationCombobox,
  FunctionCombobox,
  RelationshipCombobox,
  createAttributesPlugin,
  createEnumerationsPlugin,
  createFunctionsPlugin,
  createRelationshipsPlugin,
} from "../plugins/mentions";
import { type HotkeysPlugin, withHotKeys } from "../plugins/util";

const exitBreak = {
  options: {
    rules: [
      {
        hotkey: "mod+enter",
      },
      {
        hotkey: "mod+shift+enter",
        before: true,
      },
    ],
  },
};

// don't need to define these here, but if we want to use withHOC they need to be here
let components = createPlateUI({
  [ELEMENT_RULE]: Rule,
  [ELEMENT_TD]: TableCell,
  [ELEMENT_TABLE]: TableElem,
});

// withDraggables(..., draggableOptions)
// withDebugPaths(..., {})

// plugin to ensure rules all have ids
const createRuleIdPlugin = createPluginFactory({
  key: "rule-id",
  normalizeInitialValue: (initialValue) => {
    // recursively iterate over initialValue and add a id using uuid to each node of type rule
    const addId = (node: any) => {
      if (node.type === ELEMENT_RULE) {
        node.id = uuidv4();
      }
      if (node.children) {
        node.children.forEach(addId);
      }
    };
    addId({ children: initialValue });
    return initialValue;
  },
  withOverrides: (editor) => {
    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
      normalizeNode([node, path]);
      const normalizedNode = getNode(editor, path);
      if (normalizedNode?.type === ELEMENT_RULE) {
        if (!normalizedNode.id) {
          normalizedNode.id = uuidv4();
          Transforms.setNodes<TNode>(editor as BaseEditor, { ...normalizedNode }, { at: path });
        }
      }
      // remove annoying unicode characters
      if (normalizedNode && typeof normalizedNode.text === "string") {
        normalizedNode.text = normalizedNode.text
          //.replace(/[\u2018\u2019]/g, "'")
          // fancy dashes
          .replace(/[\u2011\u2012\u2013\u2014]/g, "-");
      }
    };
    return editor;
  },
});

export const createRulePlugins = (plugins: PlatePlugin[] = []) => {
  //const { t } = useTranslation();
  // We have to call i18next direclty here as this isn't a hook
  components = withPlaceholders(components, [
    { key: ELEMENT_PARAGRAPH, placeholder: i18next.t("documents.enter_text"), hideOnBlur: true },
    { key: ELEMENT_RULE, placeholder: i18next.t("documents.enter_condition"), hideOnBlur: false },
  ]);

  return createPlugins(
    [
      createRuleIdPlugin(),
      createNodeIdPlugin({ options: { idKey: "id", idCreator: uuidv4, allow: [ELEMENT_RULE] } }),
      // ensure its before the rules, as its an override that needs to run first
      createRuleBreakPlugin(),
      createOperatorPlugin(),
      createConnectorPlugin(),
      createRulePlugin(),
      // ensure paragraph comes after rules, as we want it as a fallback
      // @ts-ignore
      createParagraphPlugin<HotkeyPlugin & HotkeysPlugin>({
        ...withHotKeys([paragraphTabbing, paragraphEnter, paragraphShiftEnter]),
        withOverrides: (editor) => {
          const { deleteForward } = editor;
          // an override function to allow custom delete
          editor.deleteForward = (unit) => {
            const { selection } = editor;
            if (!selection) return deleteForward(unit);
            const basePath = selection.focus.path.slice(0, -1);
            const next = getNextNode(editor, { at: basePath });
            if (next) {
              const [node, path] = next;
              // if the next node is not a paragraph, do our special case and delete the paragraph instead
              if (node.type !== ELEMENT_PARAGRAPH) {
                debugger;
                removeNodes(editor as TEditor, { at: basePath });
                return;
              }
            }
            deleteForward(unit);
          };
          return editor;
        }
      }),
      createExitBreakPlugin(exitBreak),
      createComboboxPlugin(),
      createFunctionsPlugin(),
      createAttributesPlugin(),
      createRelationshipsPlugin(),
      createEnumerationsPlugin(),
      createDecisionTablesPlugin(),
      // put it at the end as a fallback
      createCopyPastePlugin(),
      ...plugins,
    ],
    {
      components,
    },
  );
};

export type EditorProps = React.PropsWithChildren & {
  id: string;
  editableProps?: TEditableProps;
};

export const RuleEditor = ({ id, editableProps, children }: EditorProps) => {
  const { decorate, renderLeaf } = useSyntaxHighlighter(id);
  const [active, setActive] = React.useState(false);

  const editProps = {
    ...editableProps,
    decorate,
    className: clsx(styles.editor, editableProps?.className),
    // TODO what was this trying to achieve???
    // ISSUE: using this blocks cursor selection and makes authoring feel awful
    // onMouseDownCapture: () => {
    //   setActive(true);
    // },
    // onMouseUpCapture: () => {
    //   setActive(false);
    // },
    scrollSelectionIntoView: (editor, domRange) => {
      // disable autoscroll to selection
      // is annoying when we are working with 2 editors and large documents
      // also solves insert section scrolling back to top issue
      return;
    },
    renderLeaf,
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <EditorActiveContext.Provider value={active}>
        <Plate
          id={id}
          editableProps={editProps}
        >
          <FunctionCombobox />
          <AttributeCombobox />
          <RelationshipCombobox />
          <EnumerationCombobox />
          {children}
        </Plate>
      </EditorActiveContext.Provider>
    </DndProvider>
  );
};
