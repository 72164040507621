/* eslint-disable camelcase */
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';
import { useList, crudGetOne } from '@imminently/imminently_platform';
import * as reduxScopeNS from '../redux/scope';
import { useNotify } from './hooks/HooksNotification';
import get from 'lodash/get';


const useData = (resource, id) => {
  return useSelector(state => state?.resources?.[ resource ]?.data?.[ id ] || null);
};

export const useIsLoading = (resource, id) => {
  return useSelector(state => state?.resources?.[ resource ]?.loading?.[ id ] || null);
};

export const useVersion = () => useSelector(state => state.ui.viewVersion);

export const useWorkspace = id => useData('workspaces', id);
export const useProject = id => useData('models', id);

export const useRelease = id => useData('releases', id);
export const useCurrentRelease = () => {
  const releaseId = useSelector(s => s.scope.release);

  return useRelease(releaseId);
};

export const useUser = id => useSelector(state => {
  const users = Object.values(state?.resources?.users?.data || {});
  if(users.length > 0) {
    return users.find(u => u.identity_id === id);
  }

  return null;
});
export const useDecision = id => useData('decisionreports', id);

export const useWorkspaces = (...args) => useList('workspaces', ...args);
export const useProjects = (...args) => useList('models', ...args);
export const useReleases = (...args) => useList('releases', ...args);
export const useUsers = (...args) => useList('users', ...args);


// ===================================================================================


export const useGetFullRelease = () => {
  const dispatch = useDispatch();
  const maybeRelease = useCurrentRelease();
  const { rule_graph, id } = maybeRelease || { rule_graph: {}, id: '' };

  useEffect(() => {
    if(rule_graph) return;

    dispatch(crudGetOne('releases', id));
  }, [id, rule_graph, dispatch]);
};

export const useFullScope = () => {
  const scope = reduxScopeNS.useScope();
  if(!scope || !scope.workspace || !scope.project || !scope.release) {
    throw new Error('useFullScope: Usage requires a full scope to exist.');
  }
  const workspace = useWorkspace(scope.workspace);
  const project = useProject(scope.project);
  const release = useRelease(scope.release);
  return { workspace, project, release };
};


// ===================================================================================

/** @type { (s: any) => boolean } */
export const getIsReleaseValidAndFull = s => {
  /** @type { reduxScopeNS.Scope } */
  const scope = s.scope;
  const { project, workspace, release } = scope;
  if(!project || !release || !workspace) return false;

  const maybeCurrentRelease = s.resources?.releases?.data?.[ release ];

  return Boolean(maybeCurrentRelease) && maybeCurrentRelease.isFull;
};


export const useEnforceFullRelease = () => {
  const dispatch = useDispatch();
  const { release } = reduxScopeNS.useScope();
  const isReleaseValidAndFull = useSelector(s => getIsReleaseValidAndFull(s));


  useEffect(() => {
    if(!release || isReleaseValidAndFull) return;

    dispatch(crudGetOne('releases', release));
  }, [isReleaseValidAndFull, dispatch, release]);
};

//# region useShowError

/**
 * e is expected to be like { message: string }
 * @type { () => ( e: unknown, errId: string ) => unknown }
 */
export const useShowError = () => {
  const { error: errNotify } = useNotify();
  const errNotifyRef = useRef(errNotify);
  if(errNotifyRef.current !== errNotify) errNotifyRef.current = errNotify;

  return useCallback(( e, errId ) => {
    const msg = String(get(e, 'message', '')) || 'Error. Please contact support.';
    console.error(`${ errId } | error:`, e);

    errNotifyRef.current(msg);
  }, []);
}

//# endregion
