import { DocumentType, ReleaseDocument, ReleaseDocuments } from "@packages/commons";
import { DocumentsIcon, FileIcon, DatasheetIcon, TableIcon, FolderIcon, AlertIcon } from "@icons";
import { Tooltip } from "@components";
import { FileType, FileNode, TreeData } from "./types";

// export type BlankFolder = { blank: true };

// const isBlankFolder = (node: File | Folder | BlankFolder): node is BlankFolder => (
//   Object.keys(node).length === 1 &&
//   (node as BlankFolder).blank === true
// );

export const isFile = (node: ReleaseDocument): boolean => (
  Object.prototype.hasOwnProperty.call(node, "name") ||
  Object.prototype.hasOwnProperty.call(node, "reference") ||
  Object.prototype.hasOwnProperty.call(node, "type")
);

/** Utility to determine if a file is a phantom (reference exists but name has been is removed) */
export const isPhantomFile = (node: ReleaseDocument): boolean => (
  Object.prototype.hasOwnProperty.call(node, "reference") &&
  !Object.prototype.hasOwnProperty.call(node, "name")
);

export const getFileIcon = (doc: ReleaseDocument) => {
  if (isPhantomFile(doc)) return (
    <Tooltip title="Corrupt document - use 'View' to see the contents or 'Delete' the document">
      <AlertIcon />
    </Tooltip>
  );
  if (!isFile(doc)) return <FolderIcon />;
  if (doc.type === "rulesheet") return <DatasheetIcon />;
  if (doc.type === "datatable") return <TableIcon />;
  if (doc.version > 1) return <DocumentsIcon />;
  return <FileIcon />;
};

type Frontier<T = TreeData> = [string, { item: ReleaseDocument, parent?: T }][];

export const buildTree = (documents: ReleaseDocuments): FileNode[] => {
  const root: FileNode = {
    type: FileType.FOLDER,
    name: "Documents",
    children: [],
    id: "/",
  };
  let id = 0;
  // build a frontier where we iterate over all documents to build out the tree
  // should reduce memory footprint and be faster than a recursive solution
  const frontier: Frontier<FileNode> = Object.entries(documents).map(([path, item]) => [path, { item }]);
  while (frontier.length > 0) {
    const _n = frontier.shift(); // pop off the stack
    if (!_n) continue; // we are done
    const [path, { item, parent }] = _n;
    const children = parent?.children as FileNode[] ?? root.children;
    // if its a file
    if (isFile(item as ReleaseDocument)) {
      // files are leaf nodes, add them to the parent and continue
      const file = item as ReleaseDocument;
      const title = file.name
        ? file.name.split("/").pop()
        : path.split("/").pop(); // file is corrupt, try to recover the name
      children.push({
        id: file.reference ?? path,
        name: title && title.length > 0 ? title : "Corrupt document",
        type: FileType.FILE,
        document: file,
      } as FileNode);
      continue;
    }
    // if its a folder, coerce the type
    const folder = item as unknown as ReleaseDocuments;
    const fNode = {
      id: path,
      name: path.split("/").pop(),
      children: [],
      type: FileType.FOLDER,
      folder: folder, // keep reference for easy look up
    } as FileNode;
    children.push(fNode);
    // add children to the frontier
    Object.keys(folder).forEach(child => {
      frontier.push([`${path}/${child}`, { item: folder[child], parent: fNode }]);
    });
  }

  return root.children as FileNode[];
};

export const sortChildren = (node: TreeData): TreeData => {
  if (!node.children) return node;
  const copy = [...node.children];
  copy.sort((a, b) => {
    if (!!a.children && !b.children) return -1;
    if (!!b.children && !a.children) return 1;
    return a.name < b.name ? -1 : 1;
  });
  const children = copy.map(sortChildren);
  return { ...node, children };
};

export const BLANK_DOC = [];

export const BLANK_SHEET = {
  declarations: [],
  sheets: [],
};

export const BLANK_DOC_NEW = {
  rules: [{ type: "p", text: "" }],
  sections: [],
  source: [{ type: "p", children: [{ text: "" }] }],
};

export const BLANK_DATA_TABLE = {
  headers: ['ID', 'ColName'],
  rows: [
    ['1', '']
  ]
};

export const getNewFileContents = (type: DocumentType, version: number = 3) => {
  if (type === "rulesheet") return BLANK_SHEET;
  if (type === "ruledoc" && version > 1) return BLANK_DOC_NEW;
  if (type === "datatable") return BLANK_DATA_TABLE;
  return BLANK_DOC;
};