import cn from 'clsx';
import escapeRegExp from 'lodash/escapeRegExp';
import styled from 'styled-components';
import { system } from 'theme';

const HSpan = styled.span`
  &.highlight {
    font-weight: 600;
    color: ${p => p.color};
  }
`;

export type HighlightedTextProps = {
  text: string;
  highlight: string;
  color?: string;
};

/**
 * Wraps any matching text in a span with a class name
 */
export const HighlightedText = ({ text, highlight, color = system.trafficGreen }: HighlightedTextProps) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, "gi"));
  return (
    <>
      {
        parts.map((part, i) => (
          <HSpan
            key={i}
            color={color}
            className={cn({
              "highlight": part.toLowerCase() === highlight.toLowerCase()
            })}
          >
            {part}
          </HSpan>
        ))
      }
    </>
  );
};