import { useMemo } from "react";

import { NAMESPACE_OPT_IN } from "@packages/commons";
import { createCompilerContext, parse } from "@packages/compiler";
import { useParsedGraph } from "@pages/models/release/GraphContext";

import type { CommandExpression, Expression } from "@packages/compiler";

export const parseExpressions = (text: string, expressions: Expression[], depth = 0) => {
  return expressions
    .flatMap((expr) => {
      switch (expr.type) {
        case "literal":
        case "attribute":
        case "relationship":
        case "alias":
          // case "comment":
          return {
            type: expr.type,
            token: expr,
            start: expr.start,
            end: expr.end,
            depth,
          };
        case "command": {
          const nodes: any[] = [];
          nodes.push({
            type: expr.type,
            token: expr,
            start: expr.start,
            end: expr.end,
            depth,
          });
          const fnText = text.substring(expr.start, expr.end);
          // if using foo(...) notation
          if (!expr.natural) {
            nodes.push({
              type: "command-fn",
              token: expr,
              start: expr.start,
              end: expr.start + fnText.indexOf("("),
              depth,
            });
          }
          nodes.push(...parseExpressions(text, (expr as CommandExpression).arguments, depth + 1));
          return nodes;
        }
        default:
          console.log("UNSUPPORTED SYNTAX EXPRESSION", expr);
          return null;
      }
    })
    .filter((n) => !!n);
};

export const useSyntaxHighlight = (release: any, documentName: string | undefined) => {
  const graph = useParsedGraph();
  const enableNamespaces = release?.model_name?.includes(NAMESPACE_OPT_IN);

  const context = useMemo(
    () =>
      createCompilerContext({
        graph: graph || undefined,
        relationships: release?.relationships ?? [],
        // @ts-ignore
        currentDocument: document,
        documentTree: release?.documents,
        enableNamespaces,
      }),
    [release, document, graph],
  );

  return (input: string) => parse({ input, context });
};
