import { ReleaseDocument, ReleaseDocuments } from "@packages/commons";

export const FileType = {
  FILE: "file",
  FOLDER: "folder",
} as const;

export interface TreeData {
  id: string;
  name: string;
  children?: TreeData[]
};

export interface FileNode extends TreeData {
  // additional data
  type: typeof FileType[keyof typeof FileType];
  document?: ReleaseDocument;
  folder?: ReleaseDocuments;
};