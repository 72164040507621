import React from 'react';

import { PageWrapper } from '@imminently/imminently_platform';

import { Divider, Grid, Radio, RadioGroup, Typography, useTheme, FormLabel, FormControlLabel, FormControl } from '@material-ui/core';

import {
  AccountIcon,
  AddIcon,
  AddcolumnleftIcon,
  AddcolumnrightIcon,
  AddfileIcon,
  AddgroupIcon,
  AddheaderIcon,
  AddrowbelowIcon,
  AddrowtopIcon,
  AddruleIcon,
  AddtableIcon,
  AlertIcon,
  AnalystIcon,
  ArrowdownrightIcon,
  ArrowleftIcon,
  ArrowrightIcon,
  AutoIcon,
  BillingIcon,
  BooleanIcon,
  BrowseIcon,
  BrowseallIcon,
  BuildIcon,
  CalendarIcon,
  CalendartimeIcon,
  CheckIcon,
  CheckboxIcon,
  ChevrondownIcon,
  ChevronleftIcon,
  ChevronrightIcon,
  ChevronupIcon,
  ClearIcon,
  CloseIcon,
  CogIcon,
  CompareIcon,
  CurrencyIcon,
  DashboardIcon,
  DatasheetIcon,
  DebugIcon,
  DefinedInIcon,
  DeleteIcon,
  DeletecolumnIcon,
  DeleterowIcon,
  DependencyIcon,
  DesignIcon,
  DesignerIcon,
  DeveloperIcon,
  DocumentsIcon,
  DragIcon,
  DropdownIcon,
  DuplicateIcon,
  EnlargeIcon,
  EqualIcon,
  FileIcon,
  FolderIcon,
  FormscreenIcon,
  FormstageIcon,
  FunctionsIcon,
  GlossaryIcon,
  GuidIcon,
  HideIcon,
  HidefilesIcon,
  ImageIcon,
  ImplementationIcon,
  IndeterminateIcon,
  IngestIcon,
  InnovateIcon,
  InstancesIcon,
  InterviewIcon,
  LabelIcon,
  LayoutIcon,
  LessequalIcon,
  LessthanIcon,
  Level1Icon,
  Level2Icon,
  Level3Icon,
  Level4Icon,
  Level5Icon,
  Level6Icon,
  LinkingIcon,
  LockedIcon,
  MapIcon,
  MatrixIcon,
  MenudotsIcon,
  MergeIcon,
  MonitorIcon,
  MoreequalIcon,
  MorethanIcon,
  MoveIcon,
  NonruleIcon,
  NotequalIcon,
  NotificationIcon,
  OutcomeIcon,
  PallettIcon,
  PinemptyIcon,
  PinfilledIcon,
  PrivatelinkIcon,
  ProjectsIcon,
  PubliclinkIcon,
  RadiobuttonIcon,
  RedoIcon,
  ReportsIcon,
  ResetgraphIcon,
  RetryIcon,
  SaveIcon,
  SearchIcon,
  ShowfilesIcon,
  ShrinkIcon,
  SignatureIcon,
  SliderIcon,
  SnippetIcon,
  SourcecodeIcon,
  SplitIcon,
  StaremptyIcon,
  StarfilledIcon,
  SubtractIcon,
  SystemfileIcon,
  TableIcon,
  TablesettingsIcon,
  TagIcon,
  TargetIcon,
  TestingIcon,
  TextfieldIcon,
  TimeIcon,
  TooltipIcon,
  TypographyIcon,
  UncertainIcon,
  UndoIcon,
  UnknownIcon,
  UnlockedIcon,
  UploadfileIcon,
  UserrolesIcon,
  UsersIcon,
  VerbIcon,
  VerifyIcon,
  VersionhistoryIcon,
  ViewIcon,
  WarningIcon,
  ZoominIcon,
  ZoomoutIcon,
} from '@icons';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Favorite,
  Badge,
  Label,
  Tag,
  TextField,
  Dropdown,
  Avatar,
  Menu,
  Checkbox,
  // AsyncSelect,
  ManageTestScenario,
  Collapsable,
} from '@components';
import { Scope, MemberList } from '@common';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { Widget, addResponseMessage, renderCustomComponent } from 'react-chat-widget';
import { DottedTreeJSX } from './DottedTree';
import * as chatUiReact from './Chat-ui-react';
import * as CompactContolsNS from './CompactControls';
import * as TwoPaneDottedTreeNS from './TwoPaneDottedTree';
import * as InterviewDesignDatePickerNS from './InterviewDesignDatePicker';
import * as JSpreadsheetDataTableNS from './JSpreadsheetDataTable';
import * as TestCasesTreeNS from './TestCasesTree';
import * as CaseViewContactsNS from './CaseViewContacts';
import * as ContactsMappingModalNS from './ContactsMappingModal';

const gap = { gap: '1rem' };
const row = { display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '1rem', flexFlow: 'row wrap' };
const inputrow = { display: 'flex', gap: '1rem', alignItems: 'start', marginBottom: '1rem', flexFlow: 'row wrap' };

const labels = [
  'Custom',
  'All members',
  'Public',
  'Invite only',
  'Private',
  'Developer',
  'Author',
  'Reviewer',
  'Admin',
];

// const AsyncSelectJSX = React.createElement(() => {
//   const dispatch = useDispatch();
//   React.useEffect(() => {
//     dispatch(AsyncSelect.ActionCreators.load({
//       getFilterForSearch: name => ({ name: `*${ name }*` }),
//       location: 'default',
//       perPage: 6,
//       resourceType: 'workspaces',
//       search: '',
//       type: 'newSearch',
//     }));
//   }, [dispatch]);
//   // const [searchStr, setSearchStr] = React.useState('');

//   // const search = React.useMemo(() => {
//   //   /** @type { AsyncSelect.Core.IProps[ 'search' ] } */
//   //   const rtrn = {
//   //     hidden: false,
//   //     placeholder: 'Search me...',
//   //     set: setSearchStr,
//   //     onClear: () => setSearchStr(''),
//   //     value: searchStr,
//   //   };

//   //   return rtrn;
//   // }, [searchStr, setSearchStr]);

//   // const actions = React.useMemo(() => (
//   //   [{ name: 'test', icon: <DashboardIcon />, onClick: () => {} }]
//   // ), []);

//   // const upper = React.useMemo(() => {
//   //   /** @type { AsyncSelect.Core.IProps[ 'upper' ] } */
//   //   const rtrn = {
//   //     heading: 'Favourites',
//   //     hasMore: true,
//   //     loadMore: console.log,
//   //     items: [
//   //       // { i: <span>1</span>, key: '1' },
//   //       // { i: <span>2</span>, key: '2' },
//   //       // { i: <span>3</span>, key: '3' },
//   //       // { i: <span>4</span>, key: '4' },
//   //       // { i: <span>5</span>, key: '5' },
//   //       // { i: <span>6</span>, key: '6' },
//   //     ],
//   //     onClick: console.log,
//   //   };

//   //   return rtrn;
//   // }, []);

//   // const lower = React.useMemo(() => {
//   //   /** @type { AsyncSelect.Core.IProps[ 'lower' ] } */
//   //   const rtrn = {
//   //     heading: 'Workspaces',
//   //     loadMore: console.log,
//   //     items: [
//   //       { i: <span>1</span>, key: '1' },
//   //       { i: <span>2</span>, key: '2' },
//   //       { i: <span>3</span>, key: '3' },
//   //       { i: <span>4</span>, key: '4' },
//   //       // { i: <span>5</span>, key: '5' },
//   //       // { i: <span>6</span>, key: '6' },
//   //       // { i: <span>7</span>, key: '7' },
//   //       // { i: <span>8</span>, key: '8' },
//   //       // { i: <span>11</span>, key: '11' },
//   //       // { i: <span>12</span>, key: '12' },
//   //       // { i: <span>13</span>, key: '13' },
//   //       // { i: <span>14</span>, key: '14' },
//   //     ],
//   //     onClick: console.log,
//   //   };

//   //   return rtrn;
//   // }, []);

//   return (
//     <AsyncSelect._ {...{
//       location: 'default',
//       resourceType: 'workspaces',
//       resourceEntryToOption: e => ({ name: e.name, id: e.id }),
//       perPage: 6,
//       getFilterForSearch: name => ({ name: `*${ name }*` }),
//       actions: [{ name: 'test', icon: <DashboardIcon />, onClick: () => { /**  */ } }],
//       onOptionClick: console.log,
//       lowerHeading: 'Workspaces',
//       upperHeading: 'Favourites',
//     }}
//     />
//   );
// });

const ManageTestScenarioJsx = (() => {
  const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
      height:  700px;
      padding: 1rem 2rem;
    }
  `;

  return React.createElement(() => {
    const [isOpen, setIsOpen] = React.useState(false);
    const open = React.useCallback(() => setIsOpen(true), [setIsOpen]);
    const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);

    return (
      <>
        <PrimaryButton onClick={open}>
          Show ManageTestScenario
        </PrimaryButton>
        <StyledDialog open={isOpen} onClose={close}>
          <ManageTestScenario._
            scenario={{
              goal: null,
              name: '',
              outcome: '{}',
              payload: '{}',
            }}
            onSubmit={console.log}
          />
        </StyledDialog>
      </>
    );
  });
})();

const ChatWidgetJSX = React.createElement(() => {
  React.useEffect(() => {
    addResponseMessage('Welcome to this awesome chat!');
    renderCustomComponent(
      () => <h1>123</h1>,
      {},
    );
  }, []);

  const handleNewUserMessage = newMessage => {
    const response = `New message incoming! ${ newMessage }`;
    addResponseMessage(response);
  };

  return (
    <div className='App'>
      <Widget
        handleNewUserMessage={handleNewUserMessage}

      />
    </div>
  );
});


const StyledCollapsable = styled(Collapsable._)`
  width: 16.625rem;
  padding: 0.5rem;
  background-color: rgba(155,155,155,0.3);
`;

const CollapsableJSX = React.createElement(() => {
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <StyledCollapsable
      heading='reporting attributes'
      content={<h1>Some content</h1>}
      collapsed={collapsed}
      toggleCollapsed={React.useCallback(() => setCollapsed(s => !s), [])}
    />
  );
});


const DesignSystem = () => {
  const theme = useTheme();
  // const { release: releaseId } = useScope();
  // const release = useRelease(releaseId);

  // const [text, setText] = useState("editable text");

  const renderIcon = (Icon, label) =>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <Icon />
      <Typography variant='caption'>{label}</Typography>
    </div>

  return (
    <PageWrapper style={{ paddingBottom: 64 }}>
      <Grid container direction='column' style={gap}>
        {/* <EditableText variant="h6" value={text} onEdited={setText} />
        <Typography variant="body1">Text state: {text}</Typography> */}
        <Typography variant='h2'>Typography</Typography>
        <Divider />
        <Grid item>
          <Typography variant='h1' gutterBottom>
            h1. Heading - Montserrat
          </Typography>
          <Typography variant='h2' gutterBottom>
            h2. Heading - Montserrat
          </Typography>
          <Typography variant='h3' gutterBottom>
            h3. Heading - Montserrat
          </Typography>
          <Typography variant='h4' gutterBottom>
            h4. Heading - Montserrat
          </Typography>
          <Typography variant='h5' gutterBottom>
            h5. Heading - Montserrat
          </Typography>
          <Typography variant='h6' gutterBottom>
            h6. Heading - Montserrat
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          </Typography>
          <Typography variant='body1' gutterBottom>
            body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
            beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti?
            Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant='body2' gutterBottom>
            body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
            beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti?
            Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant='button' display='block' gutterBottom>
            button text
          </Typography>
          <Typography variant='caption' display='block' gutterBottom>
            caption text
          </Typography>
          <Typography variant='overline' display='block' gutterBottom>
            overline text
          </Typography>
        </Grid>

        <Typography variant='h2'>Buttons</Typography>
        <Divider />
        <Grid container style={gap}>
          <Grid item>
            <Grid container direction='column' style={gap}>
              <div>
                <PrimaryButton>Primary button</PrimaryButton>
              </div>
              <div>
                <PrimaryButton size='small'>Primary button</PrimaryButton>
              </div>
              <div>
                <PrimaryButton startIcon={<DashboardIcon />}>Primary button</PrimaryButton>
              </div>
              <div>
                <PrimaryButton disabled>Primary button</PrimaryButton>
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='column' style={gap}>
              <div>
                <SecondaryButton>Secondary button</SecondaryButton>
              </div>
              <div>
                <SecondaryButton size='small'>Secondary button</SecondaryButton>
              </div>
              <div>
                <SecondaryButton startIcon={<DashboardIcon />}>Secondary button</SecondaryButton>
              </div>
              <div>
                <SecondaryButton disabled>Secondary button</SecondaryButton>
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='column' style={gap}>
              <div>
                <TertiaryButton>Tertiary button</TertiaryButton>
              </div>
              <div>
                <TertiaryButton size='small'>Tertiary button</TertiaryButton>
              </div>
              <div>
                <TertiaryButton startIcon={<DashboardIcon />}>Tertiary button</TertiaryButton>
              </div>
              <div>
                <TertiaryButton disabled>Tertiary button</TertiaryButton>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Typography variant='h2'>Badges, Tags and Labels</Typography>
        <Divider />
        <Grid item>
          <div style={row}>
            <Badge size='small'>0</Badge>
            <Badge size='small'>12</Badge>
            <Badge size='small'>35655</Badge>
            <Badge>0</Badge>
            <Badge>12</Badge>
            <Badge>35655</Badge>
            <Badge size='large'>0</Badge>
            <Badge size='large'>12</Badge>
            <Badge size='large'>35655</Badge>
          </div>
          <div style={row}>
            {labels.map(label => (
              <Label key={label} color={theme.labels[ label ]}>{label}</Label>
            ))}
          </div>
          <div style={row}>
            <Tag label='Tag' />
            <Tag label='Tag' onDelete={() => { /**  */ }} />
          </div>
        </Grid>

        <Typography variant='h2'>Inputs</Typography>
        <Divider />
        <Grid item>
          <div style={inputrow}>
            <TextField label='Label' />
            <TextField label='Label' defaultValue='Input Text' />
            <TextField label='Label' icon={<DashboardIcon />} />
            <TextField label='Label' defaultValue='Input text' helperText='Assistive text' icon={<DashboardIcon />} />
          </div>

          <div style={inputrow}>
            <TextField label='Label' error />
            <TextField label='Label' error defaultValue='Input Text' />
            <TextField label='Label' error helperText='Assistive text' />
          </div>

          <div style={inputrow}>
            <TextField label='Label' multiline minRows='3' defaultValue='Notes' />
            <TextField
              label='Label'
              multiline
              minRows='3'
              icon={<DashboardIcon />}
              value='Notes that are really long that wrap over multiple lines'
            />
            <TextField
              label='Label'
              multiline
              minRows='3'
              helperText='Assistive text'
              value='Notes that are really long that wrap over multiple lines and go past the min row count'
            />
            <TextField
              label='Label'
              multiline
              maxRows='3'
              helperText='Assistive text'
              value='Notes that are really long that wrap over multiple lines and go past the max row count'
            />
          </div>

          <div style={inputrow}>
            <Dropdown id='outlined-select' label='Select' defaultValue='default' items={['default', 'test', 'qwerty']} />
            <Dropdown
              style={{ width: 180 }}
              id='outlined-select'
              label='Key Value pair'
              defaultValue='default'
              items={[{ name: 'Default', value: 'default' }, { name: 'Test', value: 'test' }, { name: 'Qwerty', value: 'qwerty' }]}
              map={v => ({ key: v.name, value: v.value })}
            />
            <Dropdown
              style={{ width: 180 }}
              id='outlined-select'
              label='Select'
              defaultValue='default'
              icon={<DashboardIcon />}
              items={['default', 'test', 'qwerty']}
            />
          </div>

          <div style={inputrow}>
            <FormControl>
              <FormLabel component='legend'>Form radio options:</FormLabel>
              <RadioGroup value={1}>
                {[{ label: 'option 1', value: 1 }, { label: 'option 2', value: 2 }].map(it => (
                  <FormControlLabel
                    key={String(it.value)}
                    value={it.value}
                    control={<Radio />}
                    label={it.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>

          <div style={{ ...inputrow, padding: '1rem', backgroundColor: 'white' }}>
            <Checkbox />
            <Checkbox checked />
            <Checkbox indeterminate />
          </div>
        </Grid>

        <Typography variant='h2'>Avatars</Typography>
        <Divider />
        <Grid item>
          <div style={row}>
            <Avatar size='large' alt='Adam' src='/avatar.png' />
            <Avatar size='large' alt='Adam' src='/avatar.png' mono />
            <Avatar size='large' alt='Adam' src='/' />
            <Avatar size='large' alt='Adam' src='/' invert />
          </div>
          <div style={row}>
            <Avatar alt='Adam' src='/avatar.png' />
            <Avatar alt='Adam' src='/avatar.png' mono />
            <Avatar alt='Adam' src='/' />
            <Avatar alt='Adam' src='/' invert />
          </div>
          <div style={row}>
            <Avatar size='medium' alt='Adam' src='/avatar.png' />
            <Avatar size='medium' alt='Adam' src='/avatar.png' mono />
            <Avatar size='medium' alt='Adam' src='/' />
            <Avatar size='medium' alt='Adam' src='/' invert />
          </div>
          <div style={row}>
            <Avatar size='small' alt='Adam' src='/avatar.png' />
            <Avatar size='small' alt='Adam' src='/avatar.png' mono />
            <Avatar size='small' alt='Adam' src='/' />
            <Avatar size='small' alt='Adam' src='/' invert />
          </div>
        </Grid>

        <Typography variant='h2'>Icons</Typography>
        <Divider />
        <Grid item>
          <div style={row}>
            {renderIcon(AccountIcon, 'AccountIcon')}
            {renderIcon(AddIcon, 'AddIcon')}
            {renderIcon(AddcolumnleftIcon, 'AddcolumnleftIcon')}
            {renderIcon(AddcolumnrightIcon, 'AddcolumnrightIcon')}
            {renderIcon(AddfileIcon, 'AddfileIcon')}
            {renderIcon(AddgroupIcon, 'AddgroupIcon')}
            {renderIcon(AddheaderIcon, 'AddheaderIcon')}
            {renderIcon(AddrowbelowIcon, 'AddrowbelowIcon')}
            {renderIcon(AddrowtopIcon, 'AddrowtopIcon')}
            {renderIcon(AddruleIcon, 'AddruleIcon')}
            {renderIcon(AddtableIcon, 'AddtableIcon')}
            {renderIcon(AlertIcon, 'AlertIcon')}
            {renderIcon(AnalystIcon, 'AnalystIcon')}
            {renderIcon(ArrowdownrightIcon, 'ArrowdownrightIcon')}
            {renderIcon(ArrowleftIcon, 'ArrowleftIcon')}
            {renderIcon(ArrowrightIcon, 'ArrowrightIcon')}
            {renderIcon(AutoIcon, 'AutoIcon')}
            {renderIcon(BillingIcon, 'BillingIcon')}
            {renderIcon(BooleanIcon, 'BooleanIcon')}
            {renderIcon(BrowseIcon, 'BrowseIcon')}
            {renderIcon(BrowseallIcon, 'BrowseallIcon')}
            {renderIcon(BuildIcon, 'BuildIcon')}
            {renderIcon(CalendarIcon, 'CalendarIcon')}
            {renderIcon(CalendartimeIcon, 'CalendartimeIcon')}
            {renderIcon(CheckIcon, 'CheckIcon')}
            {renderIcon(CheckboxIcon, 'CheckboxIcon')}
            {renderIcon(ChevrondownIcon, 'ChevrondownIcon')}
            {renderIcon(ChevronleftIcon, 'ChevronleftIcon')}
            {renderIcon(ChevronrightIcon, 'ChevronrightIcon')}
            {renderIcon(ChevronupIcon, 'ChevronupIcon')}
            {renderIcon(ClearIcon, 'ClearIcon')}
            {renderIcon(CloseIcon, 'CloseIcon')}
            {renderIcon(CogIcon, 'CogIcon')}
            {renderIcon(CompareIcon, 'CompareIcon')}
            {renderIcon(CurrencyIcon, 'CurrencyIcon')}
            {renderIcon(DashboardIcon, 'DashboardIcon')}
            {renderIcon(DatasheetIcon, 'DatasheetIcon')}
            {renderIcon(DebugIcon, 'DebugIcon')}
            {renderIcon(DefinedInIcon, 'DefinedInIcon')}
            {renderIcon(DeleteIcon, 'DeleteIcon')}
            {renderIcon(DeletecolumnIcon, 'DeletecolumnIcon')}
            {renderIcon(DeleterowIcon, 'DeleterowIcon')}
            {renderIcon(DependencyIcon, 'DependencyIcon')}
            {renderIcon(DesignIcon, 'DesignIcon')}
            {renderIcon(DesignerIcon, 'DesignerIcon')}
            {renderIcon(DeveloperIcon, 'DeveloperIcon')}
            {renderIcon(DocumentsIcon, 'DocumentsIcon')}
            {renderIcon(DragIcon, 'DragIcon')}
            {renderIcon(DropdownIcon, 'DropdownIcon')}
            {renderIcon(DuplicateIcon, 'DuplicateIcon')}
            {renderIcon(EnlargeIcon, 'EnlargeIcon')}
            {renderIcon(EqualIcon, 'EqualIcon')}
            {renderIcon(FileIcon, 'FileIcon')}
            {renderIcon(FolderIcon, 'FolderIcon')}
            {renderIcon(FormscreenIcon, 'FormscreenIcon')}
            {renderIcon(FormstageIcon, 'FormstageIcon')}
            {renderIcon(FunctionsIcon, 'FunctionsIcon')}
            {renderIcon(GlossaryIcon, 'GlossaryIcon')}
            {renderIcon(GuidIcon, 'GuidIcon')}
            {renderIcon(HideIcon, 'HideIcon')}
            {renderIcon(HidefilesIcon, 'HidefilesIcon')}
            {renderIcon(ImageIcon, 'ImageIcon')}
            {renderIcon(ImplementationIcon, 'ImplementationIcon')}
            {renderIcon(IndeterminateIcon, 'IndeterminateIcon')}
            {renderIcon(IngestIcon, 'IngestIcon')}
            {renderIcon(InnovateIcon, 'InnovateIcon')}
            {renderIcon(InstancesIcon, 'InstancesIcon')}
            {renderIcon(InterviewIcon, 'InterviewIcon')}
            {renderIcon(LabelIcon, 'LabelIcon')}
            {renderIcon(LayoutIcon, 'LayoutIcon')}
            {renderIcon(LessequalIcon, 'LessequalIcon')}
            {renderIcon(LessthanIcon, 'LessthanIcon')}
            {renderIcon(Level1Icon, 'Level1Icon')}
            {renderIcon(Level2Icon, 'Level2Icon')}
            {renderIcon(Level3Icon, 'Level3Icon')}
            {renderIcon(Level4Icon, 'Level4Icon')}
            {renderIcon(Level5Icon, 'Level5Icon')}
            {renderIcon(Level6Icon, 'Level6Icon')}
            {renderIcon(LinkingIcon, 'LinkingIcon')}
            {renderIcon(LockedIcon, 'LockedIcon')}
            {renderIcon(MapIcon, 'MapIcon')}
            {renderIcon(MatrixIcon, 'MatrixIcon')}
            {renderIcon(MenudotsIcon, 'MenudotsIcon')}
            {renderIcon(MergeIcon, 'MergeIcon')}
            {renderIcon(MonitorIcon, 'MonitorIcon')}
            {renderIcon(MoreequalIcon, 'MoreequalIcon')}
            {renderIcon(MorethanIcon, 'MorethanIcon')}
            {renderIcon(MoveIcon, 'MoveIcon')}
            {renderIcon(NonruleIcon, 'NonruleIcon')}
            {renderIcon(NotequalIcon, 'NotequalIcon')}
            {renderIcon(NotificationIcon, 'NotificationIcon')}
            {renderIcon(OutcomeIcon, 'OutcomeIcon')}
            {renderIcon(PallettIcon, 'PallettIcon')}
            {renderIcon(PinemptyIcon, 'PinemptyIcon')}
            {renderIcon(PinfilledIcon, 'PinfilledIcon')}
            {renderIcon(PrivatelinkIcon, 'PrivatelinkIcon')}
            {renderIcon(ProjectsIcon, 'ProjectsIcon')}
            {renderIcon(PubliclinkIcon, 'PubliclinkIcon')}
            {renderIcon(RadiobuttonIcon, 'RadiobuttonIcon')}
            {renderIcon(RedoIcon, 'RedoIcon')}
            {renderIcon(ReportsIcon, 'ReportsIcon')}
            {renderIcon(ResetgraphIcon, 'ResetgraphIcon')}
            {renderIcon(RetryIcon, 'RetryIcon')}
            {renderIcon(SaveIcon, 'SaveIcon')}
            {renderIcon(SearchIcon, 'SearchIcon')}
            {renderIcon(ShowfilesIcon, 'ShowfilesIcon')}
            {renderIcon(ShrinkIcon, 'ShrinkIcon')}
            {renderIcon(SignatureIcon, 'SignatureIcon')}
            {renderIcon(SliderIcon, 'SliderIcon')}
            {renderIcon(SnippetIcon, 'SnippetIcon')}
            {renderIcon(SourcecodeIcon, 'SourcecodeIcon')}
            {renderIcon(SplitIcon, 'SplitIcon')}
            {renderIcon(StaremptyIcon, 'StaremptyIcon')}
            {renderIcon(StarfilledIcon, 'StarfilledIcon')}
            {renderIcon(SubtractIcon, 'SubtractIcon')}
            {renderIcon(SystemfileIcon, 'SystemfileIcon')}
            {renderIcon(TableIcon, 'TableIcon')}
            {renderIcon(TablesettingsIcon, 'TablesettingsIcon')}
            {renderIcon(TagIcon, 'TagIcon')}
            {renderIcon(TargetIcon, 'TargetIcon')}
            {renderIcon(TestingIcon, 'TestingIcon')}
            {renderIcon(TextfieldIcon, 'TextfieldIcon')}
            {renderIcon(TimeIcon, 'TimeIcon')}
            {renderIcon(TooltipIcon, 'TooltipIcon')}
            {renderIcon(TypographyIcon, 'TypographyIcon')}
            {renderIcon(UncertainIcon, 'UncertainIcon')}
            {renderIcon(UndoIcon, 'UndoIcon')}
            {renderIcon(UnknownIcon, 'UnknownIcon')}
            {renderIcon(UnlockedIcon, 'UnlockedIcon')}
            {renderIcon(UploadfileIcon, 'UploadfileIcon')}
            {renderIcon(UserrolesIcon, 'UserrolesIcon')}
            {renderIcon(UsersIcon, 'UsersIcon')}
            {renderIcon(VerbIcon, 'VerbIcon')}
            {renderIcon(VerifyIcon, 'VerifyIcon')}
            {renderIcon(VersionhistoryIcon, 'VersionhistoryIcon')}
            {renderIcon(ViewIcon, 'ViewIcon')}
            {renderIcon(WarningIcon, 'WarningIcon')}
            {renderIcon(ZoominIcon, 'ZoominIcon')}
            {renderIcon(ZoomoutIcon, 'ZoomoutIcon')}
          </div>
        </Grid>

        <Typography variant='h2'>Scope selector</Typography>
        <Divider />
        <Grid item>
          <Scope />
        </Grid>

        <Typography variant='h2'>Menu</Typography>
        <Divider />
        <Grid item>
          <div style={inputrow}>
            <div style={{ width: 300, border: '1px solid grey' }}>
              <Menu items={[{ name: 'test' }, { name: 'asd' }]} />
            </div>
            <div style={{ width: 300, border: '1px solid grey' }}>
              <Menu items={[{ name: 'test' }, { name: 'asd' }]} search='Search ...' />
            </div>
            <div style={{ width: 300, border: '1px solid grey' }}>
              <Menu
                items={[
                  { name: 'test' },
                  {
                    name: 'some really long menu item name',
                    icon: <Avatar size='small' alt='asd' src='/' />,
                    action: <Favorite style={{ padding: 0 }} size='small' />,
                  },
                ]}
                search='Search ...'
                actions={[{ name: 'test', icon: <DashboardIcon />, onClick: () => { /** */ } }]}
              />
            </div>
          </div>
        </Grid>


        {/* <AsyncSelect._
          resourceType='workspaces'
          location='default'
          resourceEntryToOption={e => ({ name: e.name, id: e.id })}
          perPage={10}
          getFilterForSearch={name => ({ name })}
          actions={[{ name: 'test', icon: <DashboardIcon />, onClick: () => {} }]}
          groupBy={[
            { name: 'favourites', filter: () => false },
            { name: 'my workspaces', filter: () => true },
          ]}
          onOptionClick={console.log}
        /> */}

        {/* {AsyncSelectJSX} */}

        {/* <Typography variant="h2">Documents</Typography>
        <Divider />
        <Grid item>
          {release?.documents && (
            <DirectoryTree documents={release.documents} onSelectFile={(node) => alert(`selected ${node.name}`)} />
          )}
        </Grid> */}
        <div style={{ backgroundColor: '#fff', padding: '2rem', height: '300px' }}>
          <MemberList />
        </div>

        {ManageTestScenarioJsx}

        {CollapsableJSX}

        {ChatWidgetJSX}

        {/* {chatUiReact._} */}

        {DottedTreeJSX}

        {CompactContolsNS.JSX}

        { TwoPaneDottedTreeNS.JSX }

        { InterviewDesignDatePickerNS.JSX }

        { JSpreadsheetDataTableNS.JSX }

        { TestCasesTreeNS.JSX }

        { CaseViewContactsNS.JSX }

        { ContactsMappingModalNS.JSX }
      </Grid>
    </PageWrapper>
  );
};

export default DesignSystem;
