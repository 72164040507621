import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ContextMenuItem } from '@components';


//# region LangOption

const LangOptionWrap = styled(ContextMenuItem)`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .MuiTypography-root {
    font-weight: 700;
  }
`;

type LangOption = {
  abbr: string;
  name?: string;
  icon?: React.ReactNode;
};
type LangOptionProps = LangOption & {
  onClick: (abbr: LangOption['abbr']) => unknown;
};

const LangOption = (p: LangOptionProps) => {
  return (
    <LangOptionWrap onClick={() => p.onClick(p.abbr)}>
      {p.icon}

      <Typography variant='caption'>
        {p.name || p.abbr}
      </Typography>
    </LangOptionWrap>
  );
}

const useLangOptions = (): LangOption[] => {
  const { t } = useTranslation();

  return React.useMemo<LangOption[]>(
    () => [
      {
        abbr: 'en',
        icon: (
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 36 36"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
          >
            <path fill="#00247D" d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z" />
            <path fill="#CF1B2B" d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z" />
            <path fill="#EEE" d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z" />
            <path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z" />
          </svg>
        ),
        name: "English",
      },
      {
        abbr: 'it',
        icon: (
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 36 36"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
          >
            <path fill="#CE2B37" d="M36 27a4 4 0 0 1-4 4h-8V5h8a4 4 0 0 1 4 4v18z" />
            <path fill="#009246" d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5H4z" />
            <path fill="#EEE" d="M12 5h12v26H12z" />
          </svg>
        ),
        name: "Italiano",
      },
      {
        abbr: 'th',
        icon: (
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 36 36"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
          >
            <path fill="#A7122D" d="M0 26.518V27a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-.482H0z" />
            <path fill="#EEE" d="M0 22.181h36v4.485H0z" />
            <path fill="#292648" d="M0 13.513h36v8.821H0z" />
            <path fill="#EEE" d="M0 9.181h36v4.485H0z" />
            <path fill="#A7122D" d="M0 9.333V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v.333H0z" />
          </svg>
        ),
        name: "ภาษาไทย",
      },
    ].sort((a, b) => a.abbr > b.abbr ? 1 : -1),
    [t]
  );
}

//# endregion


export const LangDropdown: React.FC<{ close: () => unknown; }> = ({ close }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = React.useState(i18n.language);


  const langOptions = useLangOptions();

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState< HTMLElement | null >(null);

  const setLangHandler = React.useCallback((v: LangOption['abbr']) => {
    const matched = langOptions.find(it => it.abbr === v);
    if(matched === undefined) return;

    const newLang = matched.abbr;
    console.log('fmpSJUXRUc | toggling - was', lang, 'now', newLang);
    i18n.changeLanguage(newLang);
    setLang(newLang);
    setPopoverAnchorEl(null);
    close();
  }, [langOptions, i18n, setLang, setPopoverAnchorEl, close]);

  return (
    <>
      <ContextMenuItem onClick={e => setPopoverAnchorEl(e.currentTarget)}>
        {t('language_lang', { lang })}
      </ContextMenuItem>

      <Popover
        open={Boolean(popoverAnchorEl)}
        onClose={() => setPopoverAnchorEl(null)}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {langOptions.map(it => (
          <LangOption
            key={it.abbr}
            {...it}
            onClick={setLangHandler}
          />
        ))}
      </Popover>
    </>
  );
}
