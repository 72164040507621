import { useMemo } from 'react';
import styled from 'styled-components';
import { css } from "@emotion/react";
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { useProject } from '@common';
import { AddIcon, BrowseallIcon, BuildIcon, ChevrondownIcon, CitizenshipIcon, DocumentsIcon } from '@icons';
import { addProject } from '@modals';
import { Avatar, DropdownMenu, SelectButton, AsyncSelectItemTooltipTitle, ContextMenuItem, AsyncSelectItem, LinkButton } from '@components';
import { AsyncSelectCore } from "@components/asyncSelect/Core";
import { useScope, setProject, setRelease, setWorkspace, setScope } from '../redux/scope';
import { getReleaseUiName } from './util';
import SearchDocuments from "../components/search/search-documents/SearchDocuments";
import { Breadcrumbs, Tooltip } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { showModal } from "@modals";
import ChangeHistoryModal from '@components/changeHistory/ChangeHistoryModal';
import { ProjectGuard } from 'auth/ComponentGuard';
import { projectService, releaseService } from 'services';
import { useRouting } from './hooks/HooksRouting';
import { ImmiCombobox } from '@components/radix/UserSelect/AsyncSelect';
import { useOpenReleaseCenter } from '@pages/ProjectsReleaseCentre';
import { useDuplicateRelease } from '@pages/ProjectsReleaseCentre/DuplicateReleaseModal';
import { useTranslation } from 'react-i18next';
import { useFullRelease } from './hooks_useFullRelease';
import { Project, Release, Workspace } from '@packages/commons';
import { Link } from 'react-router-dom';

// TODO: fix up this into a nicer common style instead of exporting later -- JW
export const ScopeLoading = styled(Skeleton)`
  border-radius: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  align-items: center;
`;

const styles = {
  popover: (theme: any) => css`
    .MuiPaper-root.MuiPopover-paper {
      box-shadow: none;
      border: 1px solid ${theme.palette.background.border};
    }
  `,
};

const MenuItem = styled(ContextMenuItem)`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5rem;
  border-radius: 0;
  min-height: 2.5rem;
`;

const ScopeLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #000;
  font-size: 0.875rem;
  font-family: 'Nunito';
  font-weight: 500;
  line-height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export type ScopePathProps = {
  workspace: Partial<Workspace>;
  project: Partial<Project>;
  release: Partial<Release>;
  link?: boolean;
};

// TODO add support for links
export const ScopePath = ({ workspace, project, release, link = false }: ScopePathProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { goToPage } = useRouting();
  const Comp = link ? LinkButton : 'span';

  const openWorkspace = () => {
    if (!link) return;
    dispatch(setWorkspace(workspace.id));
    goToPage('/projects');
  };

  const openProject = () => {
    if (!link) return;
    dispatch(setScope({ workspace: workspace.id, project: project.id, release: release.id }));
    goToPage('/build');
  };

  const openRelease = () => {
    if (!link) return;
    dispatch(setScope({ workspace: workspace.id, project: project.id, release: release.id }));
    goToPage('/build');
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <ScopeLabel>
        <CitizenshipIcon />
        <Comp onClick={openWorkspace}>{workspace.name}</Comp>
      </ScopeLabel>
      <ScopeLabel>
        <BuildIcon />
        <Comp onClick={openProject}>{project.name}</Comp>
      </ScopeLabel>
      <ScopeLabel>
        <DocumentsIcon />
        <Comp onClick={openRelease}>{t('release_label')} {release.releaseNo}</Comp>
      </ScopeLabel>
    </Breadcrumbs>
  );
};

const ProjectSelector = () => {
  const dispatch = useDispatch();
  const { goToPage } = useRouting();
  const { workspace, project: projectId } = useScope();
  const project = useProject(projectId);
  const { t } = useTranslation();

  const comboProps = {
    label: t('projects_label'),
    service: projectService,
    filter: {
      workspace,
      sort: { field: "created", order: "desc" } as const,
    },
    onSelect: (item) => dispatch(setProject(item.id, { resetRelease: true })),
    renderItem: (item) => (
      <Tooltip title={<AsyncSelectItemTooltipTitle name={item.name} description={item.description} />} placement='right'>
        <MenuItem selected={item.id === projectId} >
          <Avatar size='small'>{item.name.slice(0, 1)}</Avatar>
          <Typography display="block" noWrap>
            {item.name}
          </Typography>
        </MenuItem>
      </Tooltip>
    ),
    actions: {
      primary: {
        name: t('browse_all'),
        icon: <BrowseallIcon />,
        type: 'tertiary',
        size: 'small' as const,
        onClick: () => {
          goToPage('/projects');
        },
      },
      secondary: [{
        name: t('projects.create_label'),
        icon: <AddIcon />,
        type: 'tertiary',
        size: 'small' as const,
        onClick: () => {
          dispatch(addProject(t));
        },
      }],
    },
  };

  return (
    <ImmiCombobox {...comboProps}>
      <SelectButton style={{ width: '15rem' }} disableRipple color="secondary" variant="contained" endIcon={<ChevrondownIcon />}>
        <Typography variant="button" display="block" noWrap>
          {project.name}
        </Typography>
      </SelectButton>
    </ImmiCombobox>
  );
};

const useCurrentReleases = () => {
  const { project } = useScope();
  const { data: releases, total, query } = releaseService.useResources({
    enabled: Boolean(project), // only query if we have a project id
    filter: {
      model: project, // filter by current project
      activeVersionFlag: true, // this is what flags current releases
      pagination: { perPage: 2, page: 0 }, // only need the first two
      fields: ["id", "releaseNo", "env", "description"],
    }
  });
  return { releases, total, query };
};

const useRecentReleases = () => {
  const { project } = useScope();
  const { data: releases, total, query, loadMore } = releaseService.useInfiniteResources({
    perPage: 10,
    enabled: Boolean(project), // only query if we have a project id
    filter: {
      model: project, // filter by current project
      activeVersionFlag: false, // don't include current releases
      sort: { field: 'releaseNo', order: 'desc' }, // sort by release number
      fields: ["id", "releaseNo", "env", "description"],
    }
  });
  return { releases, total, query, loadMore };
};

const ReleaseSelector = ({ project }) => {
  const dispatch = useDispatch();
  const openReleaseCenter = useOpenReleaseCenter();
  const openDuplicateRelease = useDuplicateRelease();
  const { release: releaseId } = useScope();
  const release = useFullRelease();
  const releaseName = useMemo(() => (release ? getReleaseUiName(release) : ''), [release]);
  const { releases: currentReleases, query: currentQuery } = useCurrentReleases();
  const { releases: recentReleases, query: recentQuery, loadMore: loadMoreRecent } = useRecentReleases();
  const { t } = useTranslation();

  if (!release) return <ScopeLoading variant='rect' width='15rem' height='2.5rem' />;

  const showChangeHistory = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(showModal({
      open: true,
      title: t('build.change_history'),
      maxWidth: false,
      contentStyle: {
        display: "flex",
        flexDirection: "column",
      },
      PaperProps: { style: { height: '90vh' } }
    }, <ChangeHistoryModal release={release} />))
  };

  const changeHistoryButton = (
    <Tooltip title={t('build.change_history')}>
      <HistoryIcon onClick={showChangeHistory} />
    </Tooltip>
  );

  const ENVIRONMENT_LABELS = {
    test: t('release_centre.development_environment'),
    prod: t('release_centre.production_environment'),
  };

  return (
    <DropdownMenu popoverProps={{ css: styles.popover }} style={{ width: '15rem' }} value={releaseName} icon={changeHistoryButton}>
      {({ close }) => {
        const onReleaseSelect = (id) => {
          close();
          dispatch(setRelease(id));
        };

        const upperProp = {
          heading: t('current'),
          isLoading: currentQuery.isLoading,
          onClick: onReleaseSelect,
          items: currentReleases.map(
            (release) => ({
              key: release.id,
              i: (
                <AsyncSelectItem
                  isRelease={true}
                  releaseEnv={ENVIRONMENT_LABELS[release.env as string]}
                  isCurrent={Boolean(releaseId === release.id)}
                  name={getReleaseUiName(release)}
                  description={release.description}
                  icon={<Avatar size="small">{release.env === 'test' ? 'D' : (release.env as string)[0]}</Avatar>}
                />
              )
            }),
          ),
        };

        const lowerProp = {
          heading: t('recent'),
          isLoading: recentQuery.isFetchingNextPage,
          loadMore: loadMoreRecent,
          hasMore: !recentQuery.isError && !recentQuery.isFetching && (recentQuery.hasNextPage ?? false),
          onClick: onReleaseSelect,
          items: recentReleases.map(
            (release) => ({
              key: release.id,
              i: (
                <AsyncSelectItem
                  isRelease={true}
                  releaseEnv={ENVIRONMENT_LABELS[release.env as string]}
                  isCurrent={Boolean(releaseId === release.id)}
                  name={getReleaseUiName(release)}
                  description={release.description}
                  icon={<Avatar size="small">{release.env === 'test' ? 'D' : (release.env as string)[0]}</Avatar>}
                />
              )
            }),
          ),
        };

        const actions = [
          {
            name: t('release_centre.new_release'),
            icon: <AddIcon />,
            onClick: () => {
              openDuplicateRelease(release);
              close();
            },
          },
          {
            name: t('browse_all'),
            icon: <BrowseallIcon />,
            onClick: () => {
              openReleaseCenter(project);
              close();
            },
          },
        ];

        return (
          <AsyncSelectCore
            upper={upperProp}
            lower={lowerProp}
            actions={actions}
          />
        );
      }}
    </DropdownMenu>
  );
}

export type ScopeProps = React.PropsWithChildren<{
  hideSearch?: boolean;
  childrenBeforeSearch?: boolean;
}>;

export const Scope = ({ hideSearch, children, childrenBeforeSearch }: ScopeProps) => {
  const { project: projectId, release: releaseId } = useScope();
  const project = useProject(projectId);

  return (
    <Container>
      {
        project
          ? <ProjectSelector />
          : <ScopeLoading variant='rect' width='15rem' height='2.5rem' />
      }

      {
        project
          ? <ReleaseSelector project={project} />
          : <ScopeLoading variant='rect' width='15rem' height='2.5rem' />
      }

      {childrenBeforeSearch ? children : null}

      {
        hideSearch ? null :
          <ProjectGuard>
            <SearchDocuments key={releaseId || ""} />
          </ProjectGuard>
      }

      {childrenBeforeSearch ? null : children}

    </Container>
  );
};
