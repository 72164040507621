import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useFullRelease } from "@common/hooks_useFullRelease";
import { useNotify } from "@common/notifications";
import { findReleaseDocument, normaliseText } from "@packages/commons";
import { setDocument } from "@pages/models/release/Documents/documents.slice";

import { useCurrentRelease } from "../hooks";
import { useRouting } from "./HooksRouting";

// a regex check for \ / : * ? " < > | characters
export const DISALLOWED_CHARACTERS_REGEX = /[\\/:*?"|]|(<[^>]*>)/;
export const DISALLOWED_CHARACTERS_MESSAGE = '\\ / : * ? " < > |';

export const validateDocName = (name: string) => name && !DISALLOWED_CHARACTERS_REGEX.test(name);

/** Sanitizes a document name by removing html tags and entities, and replacing non-breaking space */
export const sanitizeDocName = (name: string) => {
  const sanitized = name
    .replace(/<[^>]*>/g, "") // remove html tags
    .replace(/&nbsp;/g, " ") // replace non-breaking space
    .replace(/&[a-z]+;/g, "") // remove html entities, eg &amp; &gt; etc
    .replace(/\r\n|\r|\n/g, "") // replace return and carriage return with empty string
    .trim();
  // normalise to remove special characters and extra whitespace
  return normaliseText(sanitized);
};

/** Trims the extension from a document name, html tags and trims whitespace. Note this name is also normalised */
export const trimDocName = (name: string) => sanitizeDocName(name.replace(/\.(docx|xlsx)$/i, "").trim());

/** A hook to open a document in a new window */
export const viewDocument = (id: string) => {
  window.open(`/doc/${id}`, "_blank", "scrollbars=yes,resizable=yes,top=500,left=500,width=1280,height=720");
};

/** A hook to allow navigating to a document based on path only */
export const useNavigateToDocument = () => {
  const release = useCurrentRelease();
  const { goToPage } = useRouting();
  const { error } = useNotify();

  return (path: string, newWind = false) => {
    // first, find the document. Make sure to normalise both paths
    const nPath = normaliseText(path);
    const document = findReleaseDocument(release?.documents, (doc) => nPath === normaliseText(doc.name));
    if (document) {
      // then navigate to it
      if (newWind) {
        viewDocument(document.reference);
      } else {
        goToPage("/build", { documentPath: path, documentId: document.reference });
      }
    } else {
      error(`Could not find document for ${path}. It has likely been renamed or deleted.`);
    }
  };
};

export const useDocumentRouting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notify = useNotify();
  const { routingState, clearState } = useRouting();
  const release = useFullRelease();

  useEffect(() => {
    const { documentId, documentPath } = routingState as any;
    if (documentId && documentPath) {
      console.log("Auto-open document", routingState);
      const doc = findReleaseDocument(release?.documents, (doc) => doc.reference === documentId);
      if (!doc) {
        // if it fails, clear routing state so we don't keep trying
        clearState();
        notify.error(t("errors.cannot_view_document"));
        return;
      }
      dispatch(setDocument(doc));
    }
  }, [routingState]);
};
