import { lazy } from "react";

import { DocumentsIcon, CogIcon, DesignerIcon, ImplementationIcon } from "@icons";

import { BuildPage } from "../pages/Build";
import ReleaseWrapper from "../pages/models/release/ReleaseWrapper";
import { Roles } from "auth/permissions";
import { useTranslation } from "react-i18next";

// This is the ideal code split flow as it will cache the result of the import
// the parent layout handles the react suspense fallback
const Documents = lazy(() => import("../pages/models/release/Documents/ReleaseDocuments"));
const DocumentsView = lazy(() => import("../pages/documents/FileExplorer/FileExplorer"));
const RuleGraph = lazy(() => import("../pages/models/release/Graph/ReleaseRuleGraph"));
const Debugger = lazy(() => import("../pages/models/release/Debug"));
const DocumentView = lazy(() => import("../pages/documents/DocumentView"));
const Entities = lazy(() => import("../pages/models/release/DataModel/route/Entities"));
const Attributes = lazy(() => import("@pages/models/release/DataModel/route/Attributes/Attributes"));
const Enumerations = lazy(() => import("../pages/models/release/DataModel/route/Enumerations"));

const InterviewDesigner = lazy(() => import("../pages/models/release/Interview/Designer/InterviewDesigner"));
const Conversation = lazy(() => import("../pages/models/release/Conversation"));
const Decision = lazy(() => import("../pages/models/release/Decision"));
const BotPage = lazy(() => import("../pages/bots/BotPage"));

const ReleaseApi = lazy(() => import("../pages/models/release/ReleaseApi"));
const Mapping = lazy(() => import("../pages/models/release/Technical/Mapping"));
const Connections = lazy(() => import("../pages/models/release/Technical/Connections"));
const Reporting = lazy(() => import("../pages/models/release/Technical/Reporting"));

const RulesGroup = {
  name: "Rules",
  icon: <DocumentsIcon />,
  open: true,
};

const DataModelGroup = {
  name: "Data model",
  icon: <ImplementationIcon />,
  open: true,
};

const DesignGroup = {
  name: "Designs",
  icon: <DesignerIcon />,
  open: true,
};

const AboutGroup = {
  name: "Technical",
  icon: <CogIcon />,
  open: true,
};

interface SubrouteProps {
  name: string;
  icon?: JSX.Element;
  group: any;
  comp: string | JSX.Element;
  path?: string | string[];
  skeleton?: JSX.Element;
  scopeComponent?: string | JSX.Element;
  translationKey?: string;
}

const subroute = ({ name, icon, group, comp, path, ...props }: SubrouteProps) => ({
  path: path || `/build/${name.toLowerCase().replace(" ", "-")}`,
  component: () => {
    const { t } = useTranslation();
    if (!comp) return <BuildPage name={name} />;
    return <ReleaseWrapper title={props.translationKey ? t(props.translationKey) : name} {...props}>{comp}</ReleaseWrapper>;
  },
  exact: true,
  group,
  sidebar: {
    name,
    translationKey: props.translationKey,
    icon,
  },
});

export const build = [
  {
    path: "/doc/:doc",
    role: [Roles.ProjectReader, Roles.ProjectWriter],
    layout: "async",
    component: DocumentView,
    exact: true,
  },
  {
    path: ["/build", "/models"],
    layout: "decisively",
    role: [Roles.ProjectReader, Roles.ProjectWriter],
    children: [
      // Rules
      //subroute({ path: "/build", name: "Documents", translationKey: 'documents.title', group: RulesGroup, comp: <DocumentsView />, skeleton: undefined }),
      subroute({ path: ["/build", "/models"], name: "Documents", group: RulesGroup, comp: <Documents />, skeleton: undefined }),
      subroute({ name: "Explore", group: RulesGroup, comp: <RuleGraph />, translationKey: 'build.explore' }),
      // will be moved to full screen modal based on context
      subroute({ name: "Debugger", group: RulesGroup, comp: <Debugger />, translationKey: 'build.debugger' }),

      // Data model
      subroute({ name: "Entities", group: DataModelGroup, comp: <Entities />, translationKey: "build.entities" }),
      subroute({ name: "Attributes", group: DataModelGroup, comp: <Attributes />, translationKey: "build.attributes" }),
      subroute({ name: "Enumerations", group: DataModelGroup, comp: <Enumerations />, translationKey: "build.enumerations" }),

      // Designers
      {
        path: "/build/design",
        exact: true,
        sidebar: { name: "Interview", translationKey: "build.interview" },
        group: DesignGroup,
        component: InterviewDesigner,
      },
      {
        path: "/build/conversation",
        exact: true,
        sidebar: { name: "Conversation", translationKey: "build.conversation" },
        group: DesignGroup,
        component: Conversation,
      },
      {
        path: "/build/reports",
        exact: true,
        sidebar: { name: "Reports", translationKey: "build.reports" },
        group: DesignGroup,
        component: Decision,
      },
      {
        path: "/build/bots",
        exact: true,
        sidebar: { name: "Bots", translationKey: "build.bots" },
        group: DesignGroup,
        component: BotPage,
      },
      // subroute({ name: 'Reports', group: DesignGroup, comp: () => null }),

      // About
      subroute({ name: "API", translationKey: "build.api", group: AboutGroup, comp: <ReleaseApi /> }),
      subroute({ name: "Mapping", translationKey: "build.mapping", group: AboutGroup, comp: <Mapping /> }),
      subroute({ name: "Connections", translationKey: "build.connections", group: AboutGroup, comp: <Connections /> }),
      subroute({ name: "Reporting", translationKey: "build.reporting", group: AboutGroup, comp: <Reporting /> }),

    ],
  },
];
