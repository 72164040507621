import type { ContactOtherDataNodeLeaf, ContactOtherDataNodeDir } from './core';
import { findByPredicateInOtherData } from './findByPredicate';


export type FindByPathInOtherDataArg = {
  node: ContactOtherDataNodeLeaf | ContactOtherDataNodeDir;
  path: string;
}
export type FindByPathInOtherDataRtrn = null | ContactOtherDataNodeDir;

export const findByPathInOtherData = (arg: FindByPathInOtherDataArg): FindByPathInOtherDataRtrn => {
  const { node, path } = arg;

  const maybeMatch = findByPredicateInOtherData({ node, predicate: it => it.type === 'dir' && it.path === path })

  return maybeMatch === null || maybeMatch.type !== 'dir' ? null : maybeMatch;
}
