import "./bootstrap";

import ReactDOM from "react-dom/client";

// load simplebar styling
import "simplebar/dist/simplebar.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.scss";
import "react-chat-widget/lib/styles.css";
import "material-icons/iconfont/material-icons.css";
import "@joint/plus/joint-plus.css";
import "react-sortable-tree/style.css";
import 'react-toastify/dist/ReactToastify.min.css';

import { App } from "./App";
import './i18n';
import { initErrorReporting } from "./ErrorReporting";
initErrorReporting();

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
