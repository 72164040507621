import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import { CaseViewContact } from '@components/CaseViewContacts';
import { PrimaryButton } from '@components';

const Wrap = styled.div`
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;

  >* {
    width: calc(50% - 1.25rem/2);
  }
`;

export const JSX = React.createElement(() => {
  const [ open, setOpen ] = React.useState(false);

  return (
    <div style={{ margin: 16 }}>
      <PrimaryButton onClick={React.useCallback(() => setOpen(true), [])}>
        Show CaseViewContact
      </PrimaryButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            padding: '1rem',
            width: '98vw',
            height: '98vh',
            backgroundColor: '#FBFBFB',
          }
        }}
        maxWidth={false}
      >
        <Wrap>
          <CaseViewContact
            name='John Smith'
            id='00234'
            data={[
              { id: '1', label: 'First names', value: 'John Micheal' },
              { id: '2', label: 'Last name', value: 'Smith' },
              { id: '3', label: 'Email', value: 'John.m.smith@email.com' },
              { id: '4', label: 'Phone', value: '+61 490123 456' },
              { id: '5', label: 'Suburb', value: 'Curtin' },
            ]}
            tree={{id: '', label: '', type: 'dir', children: [], path: '', pathArr: []}}
            // otherData={[
            //   { id: '1', label: 'Street Address', value: 'xx Street Avenue' },
            //   { id: '2', label: 'City', value: 'Sydney' },
            //   { id: '3', label: 'Postal / Zip Code ', value: '1234' },
            //   { id: '4', label: 'Country', value: 'Australia' },
            //   { id: '5', label: 'Passport Number', value: 'xxxxxxxx' },
            //   { id: '6', label: 'Date of Birth', value: 'xxxxxxxx' },
            //   { id: '7', label: 'Street Address', value: 'xx Street Avenue' },
            //   { id: '8', label: 'City', value: 'Sydney' },
            //   { id: '9', label: 'Postal / Zip Code ', value: '1234' },
            //   { id: '10', label: 'Country', value: 'Australia' },
            //   { id: '11', label: 'Passport Number', value: 'xxxxxxxx' },
            //   { id: '12', label: 'Date of Birth', value: 'xxxxxxxx' },
            //   { id: '13', label: 'Street Address', value: 'xx Street Avenue' },
            //   { id: '14', label: 'City', value: 'Sydney' },
            //   { id: '15', label: 'Postal / Zip Code ', value: '1234' },
            //   { id: '16', label: 'Country', value: 'Australia' },
            //   { id: '17', label: 'Passport Number', value: 'xxxxxxxx' },
            //   { id: '18', label: 'Date of Birth', value: 'xxxxxxxx' },
            // ]}
            otherCases={{
              perPage: 2,
              total: 5,
            }}
          />

          <CaseViewContact
            name='Jane Smith'
            id='00235'
            data={[
              { id: '1', label: 'First names', value: 'Jane Michelle' },
              { id: '2', label: 'Last name', value: 'Smith' },
              { id: '3', label: 'Email', value: 'Jane.m.smith@email.com' },
              { id: '4', label: 'Phone', value: '+61 490123 457' },
              { id: '5', label: 'Suburb', value: 'Curtin' },
            ]}
            tree={{id: '', label: '', type: 'dir', children: [], path: '', pathArr: []}}
            // otherData={[
            //   { id: '1', label: 'Street Address', value: 'xx Street Avenue' },
            //   { id: '2', label: 'City', value: 'Sydney' },
            //   { id: '3', label: 'Postal / Zip Code ', value: '1234' },
            //   { id: '4', label: 'Country', value: 'Australia' },
            //   { id: '5', label: 'Passport Number', value: 'xxxxxxxx' },
            //   { id: '6', label: 'Date of Birth', value: 'xxxxxxxx' },
            //   { id: '7', label: 'Street Address', value: 'xx Street Avenue' },
            //   { id: '8', label: 'City', value: 'Sydney' },
            //   { id: '9', label: 'Postal / Zip Code ', value: '1234' },
            //   { id: '10', label: 'Country', value: 'Australia' },
            //   { id: '11', label: 'Passport Number', value: 'xxxxxxxx' },
            //   { id: '12', label: 'Date of Birth', value: 'xxxxxxxx' },
            //   { id: '13', label: 'Street Address', value: 'xx Street Avenue' },
            //   { id: '14', label: 'City', value: 'Sydney' },
            //   { id: '15', label: 'Postal / Zip Code ', value: '1234' },
            //   { id: '16', label: 'Country', value: 'Australia' },
            //   { id: '17', label: 'Passport Number', value: 'xxxxxxxx' },
            //   { id: '18', label: 'Date of Birth', value: 'xxxxxxxx' },
            // ]}
            otherCases={{
              perPage: 3,
              total: 5,
            }}
          />
        </Wrap>
      </Dialog>
    </div>
  );
});
