import { type MouseEventHandler, type PropsWithChildren, type ReactNode, useContext, useState } from "react";
import { useCopyToClipboard, useKey, useLocalStorage } from "react-use";
import { type BaseEditor, Path } from "slate";
import styled from "styled-components";

import { Tooltip, Typography } from "@material-ui/core";
import IconButton, { type IconButtonProps } from "@material-ui/core/IconButton";
import {
  BookmarkBorder,
  FileCopyOutlined,
  FormatBold,
  FormatIndentIncreaseOutlined,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  KeyboardReturn,
  StrikethroughS,
} from "@material-ui/icons";
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_LIC,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_UL,
  FloatingVerticalDivider,
  LinkToolbarButton,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  type PlateEditor,
  type TElement,
  findNodePath,
  getNodeTexts,
  insertNodes,
  removeNodes,
  replaceNodeChildren,
  setNodes,
  someNode,
  usePlateEditorRef,
  usePlateEditorState,
  withoutNormalizing,
} from "@udecode/plate";

import {
  AddgroupIcon,
  AddruleIcon,
  AddtableIcon,
  AutogenpenIcon,
  ChevrondownIcon,
  CogIcon,
  DebugIcon,
  Level1Icon,
  Level2Icon,
  Level3Icon,
  Level4Icon,
  Level5Icon,
  Level6Icon,
  MenudotsIcon,
  NonruleIcon,
  OutcomeIcon,
  PubliclinkIcon,
  RedoIcon,
  TooltipIcon,
  UndoIcon,
} from "@icons";
import {
  ELEMENT_BANNER,
  ELEMENT_CONCLUSION,
  ELEMENT_CONDITION,
  ELEMENT_OPERATOR,
  ELEMENT_RULE,
  ELEMENT_SECTION,
  type SectionData,
  createSection,
  createSectionFromNodes,
  getRuleLevel,
  insertOperator,
  isWithinOperator,
  paragraphToRule,
  ruleToParagraph,
} from "../../components";
import { isLastTableRow, isRule, isTableHeaderRow } from "../../components/rule/ruleTransforms";
import {
  BlockToolbarButton,
  EditorToolbarButton,
  Group,
  MarkToolbarButton,
  ToolbarDropdown,
} from "../../plugins/muiToolbar";
import { createParagraph, getSelectedNode } from "../../plugins/util";
import { EditorState } from "../multiEditor";

import { hotkeyTooltip } from "@common/HotkeyTooltip";
import { ELEMENT_CONNECTOR, insertConnector } from "@common/editor/components/operator/connector";
import type { TRule } from "@common/editor/components/rule/rule.types";
import { useAutoSectionModal } from "@common/editor/components/suggest/AutoSectionModal";
import { type SuggestSection, useOpenSuggestRulesModal } from "@common/editor/components/suggest/SuggestRulesModal";
import { useCurrentRelease } from "@common/hooks";
import { useNotification } from "@common/notifications";
import { Avatar, AvatarGroup } from "@components";
import { RuleDocumentContext, useRuleDocument, useSections } from "@pages/documents";
import { useDocumentMetadataModal } from "@pages/documents/DocumentMetadataModal";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useDocumentEditors } from "services";
import { Banner, getBannerColour } from "../../components/banner";
import { TablesToolbar } from "../../components/decisionTable";
import { insertRuleBreak } from "../../components/ruleBreak";
import { showHelp } from "../HelpModal";

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
`;

const DropdownButton = styled(IconButton)`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.25rem;
  .MuiIconButton-label {
    gap: 0.5rem;
  }
`;

// eslint-disable-next-line complexity
const getElementLabel = (element: TElement) => {
  const { t } = useTranslation();
  switch (element.type) {
    case ELEMENT_PARAGRAPH:
    case ELEMENT_LIC: // list elements are also paragraphs
      return t("documents.paragraph_label");
    case ELEMENT_H1:
      return t("documents.heading_1_label");
    case ELEMENT_H2:
      return t("documents.heading_2_label");
    case ELEMENT_H3:
      return t("documents.heading_3_label");
    case ELEMENT_H4:
      return t("documents.heading_4_label");
    case ELEMENT_H5:
      return t("documents.heading_5_label");
    case ELEMENT_H6:
      return t("documents.heading_6_label");
    case ELEMENT_OL:
      return t("documents.numbered_list_tooltip");
    case ELEMENT_UL:
      return t("documents.bullet_tooltip");
    case ELEMENT_CONCLUSION:
      return t("documents.conclusion_label");
    case ELEMENT_CONDITION:
      return t("documents.condition_label");
    case ELEMENT_SECTION:
      return t("documents.section_tooltip");
    case ELEMENT_TABLE:
      return t("documents.decision_table_label");
    case ELEMENT_BANNER:
      return t(`documents.${element.banner}_banner_label`);
    case ELEMENT_RULE:
      return t(`documents.${element.expression}_label`);
    default:
      return t("documents.unknown_label");
  }
};

// eslint-disable-next-line complexity
const getElementIcon = (element: TElement) => {
  switch (element.type) {
    case ELEMENT_PARAGRAPH:
      return <NonruleIcon />;
    case ELEMENT_H1:
      return <Level1Icon />;
    case ELEMENT_H2:
      return <Level2Icon />;
    case ELEMENT_H3:
      return <Level3Icon />;
    case ELEMENT_H4:
      return <Level4Icon />;
    case ELEMENT_H5:
      return <Level5Icon />;
    case ELEMENT_H6:
      return <Level6Icon />;
    case ELEMENT_OL:
      return <FormatListNumbered />;
    case ELEMENT_UL:
      return <FormatListBulleted />;
    case ELEMENT_CONCLUSION:
      return <OutcomeIcon />;
    case ELEMENT_CONDITION:
      return <AddruleIcon />;
    case ELEMENT_SECTION:
      return <AddgroupIcon />;
    case ELEMENT_TABLE:
      return <AddtableIcon />;
    case ELEMENT_BANNER:
      return <BookmarkBorder />;
    case ELEMENT_RULE:
      return <OutcomeIcon />;
    default:
      return <>🤷</>;
  }
};

const AvatarPopperProps = { modifiers: { offset: { offset: "0, -10" }, flip: { enabled: false } } };

const MenuIconItem = ({ icon, name, ...props }: { icon: ReactNode; name: string } & Record<string, any>) => (
  <Heading {...props}>
    {icon}
    <Typography variant="caption">{capitalize(name)}</Typography>
  </Heading>
);

const HeadingIcon = ({ element, name, ...props }: { element: string; name?: string } & Record<string, any>) => (
  <MenuIconItem
    icon={getElementIcon({ type: element } as TElement)}
    name={name ?? getElementLabel({ type: element, children: [] })}
    {...props}
  />
);

const BannerHeading = styled(HeadingIcon)`
  > svg {
    color: ${(props) => getBannerColour(props.banner, props.theme)};
  }
`;

const DropdownIcon = ({ children, ...props }: IconButtonProps) => (
  <DropdownButton
    style={{ minWidth: "8rem", padding: "0.25rem 0.5rem" }}
    {...props}
  >
    {children}
    <ChevrondownIcon />
  </DropdownButton>
);

const isActive = (type: string) => (editor) => !!editor?.selection && someNode(editor, { match: { type } });

const useShiftKey = () => {
  const [shiftKey, setShiftKey] = useState(false);

  useKey("Shift", () => setShiftKey(true), { event: "keydown" });
  useKey("Shift", () => setShiftKey(false), { event: "keyup" });

  return shiftKey;
};

const convertBanner = (banner: Banner) => (editor: PlateEditor) => {
  const node = getSelectedNode(editor as BaseEditor);
  if (node) {
    setNodes(editor, { type: ELEMENT_BANNER, banner }, { at: node[1] });
  }
};

const isActiveBanner = (banner: Banner) => (editor: PlateEditor) => {
  return !!editor?.selection && someNode(editor, { match: (e) => e.type === ELEMENT_BANNER && e.banner === banner });
};

const useToolbarDropdown = () => {
  const [isOpen, setOpen] = useState(false);
  const toggle: MouseEventHandler<HTMLButtonElement> = (e) => {
    e?.preventDefault();
    setOpen(!isOpen);
  };
  const open = () => setOpen(true);
  const close = () => setOpen(false);
  return { isOpen, toggle, open, close };
};

export const HistoryToolbar = () => {
  return (
    <>
      <EditorToolbarButton
        key="undo"
        icon={<UndoIcon />}
        tooltip={hotkeyTooltip("Undo", ["mod", "z"])}
        onClick={(e) => e.undo()}
        disable={(e) => e.history.undos.length === 0}
      />
      <EditorToolbarButton
        key="redo"
        icon={<RedoIcon />}
        tooltip={hotkeyTooltip("Redo", ["mod", "shift", "z"])}
        onClick={(e) => e.redo()}
        disable={(e) => e.history.redos.length === 0}
      />
    </>
  );
};

export const TurnIntoDropdownMenu = () => {
  const { isOpen, close, toggle } = useToolbarDropdown();
  const source = usePlateEditorState(EditorState.source);
  const [sourceNode] = getSelectedNode(source as BaseEditor);
  const { t } = useTranslation();

  const control = (
    <DropdownIcon>
      <Tooltip title={t("release_centre.turn_into_tooltip")}>
        <Typography variant="body1">
          {getElementLabel((sourceNode as TElement) ?? { type: ELEMENT_PARAGRAPH })}
        </Typography>
      </Tooltip>
    </DropdownIcon>
  );

  return (
    <ToolbarDropdown
      control={control}
      open={isOpen}
      onOpen={toggle}
      onClose={close}
    >
      <Typography
        variant="caption"
        style={{ fontWeight: "bold", padding: "0.25rem", paddingBottom: 0 }}
      >
        {t("release_centre.turn_into_tooltip")}
      </Typography>
      <BlockToolbarButton
        icon={<HeadingIcon element={ELEMENT_PARAGRAPH} />}
        // WIP - trying to fix turn into menu
        // type={[ELEMENT_PARAGRAPH, ELEMENT_LIC]}
        type={ELEMENT_PARAGRAPH}
      />
      <BlockToolbarButton
        icon={<HeadingIcon element={ELEMENT_H1} />}
        type={ELEMENT_H1}
      />
      <BlockToolbarButton
        icon={<HeadingIcon element={ELEMENT_H2} />}
        type={ELEMENT_H2}
      />
      <BlockToolbarButton
        icon={<HeadingIcon element={ELEMENT_H3} />}
        type={ELEMENT_H3}
      />
      <BlockToolbarButton
        icon={<HeadingIcon element={ELEMENT_H4} />}
        type={ELEMENT_H4}
      />
      <BlockToolbarButton
        icon={<HeadingIcon element={ELEMENT_H5} />}
        type={ELEMENT_H5}
      />
      <BlockToolbarButton
        icon={<HeadingIcon element={ELEMENT_H6} />}
        type={ELEMENT_H6}
      />
      {...Object.values(Banner).map((banner) => (
        <BlockToolbarButton
          key={`btn-${banner}`}
          icon={
            <BannerHeading
              banner={banner}
              element={ELEMENT_BANNER}
              name={t(`documents.${banner}_banner_label`)}
            />
          }
          type={ELEMENT_BANNER}
          active={isActiveBanner(banner)}
          toggle={convertBanner(banner)}
        />
      ))}
    </ToolbarDropdown>
  );
};

export const MoreDropdownMenu = () => {
  const dispatch = useDispatch();
  const [, setClipboard] = useCopyToClipboard();
  const notify = useNotification();
  const [debug, setDebug] = useLocalStorage("rule-author-debug", false);
  const openMetadataModal = useDocumentMetadataModal();
  // this SHOULD only be used within a rule document context
  // if this changes, change to useCurrentDocument
  const { document } = useRuleDocument();
  const { t } = useTranslation();

  const { isOpen, close, toggle } = useToolbarDropdown();

  const control = (
    <DropdownButton>
      <MenudotsIcon />
    </DropdownButton>
  );

  return (
    <ToolbarDropdown
      control={control}
      open={isOpen}
      onOpen={toggle}
      onClose={close}
    >
      <EditorToolbarButton
        icon={
          <MenuIconItem
            icon={<CogIcon />}
            name={t("documents.metadata")}
          />
        }
        onClick={() => {
          if (document.document.id) {
            openMetadataModal(document.document.id);
          }
          close();
        }}
      />
      <EditorToolbarButton
        icon={
          <MenuIconItem
            icon={<TooltipIcon />}
            name={t("help")}
          />
        }
        onClick={() => {
          dispatch(showHelp());
          close();
        }}
      />
      <EditorToolbarButton
        id={EditorState.rules}
        icon={
          <MenuIconItem
            icon={<FileCopyOutlined />}
            name={t("documents.debug_rules")}
          />
        }
        tooltip={hotkeyTooltip(t("documents.debug_rules_tooltip"))}
        onClick={(editor) => {
          setClipboard(JSON.stringify(editor.children, null, 2));
          notify(t("documents.rules_copied_message"), "info", 1.5);
          close();
        }}
      />
      <EditorToolbarButton
        icon={
          <MenuIconItem
            icon={<DebugIcon />}
            name={t("documents.debug_mode")}
          />
        }
        active={debug}
        tooltip={hotkeyTooltip(t("documents.debug_mode_tooltip"))}
        onClick={() => {
          setDebug(!debug);
          close();
        }}
      />
    </ToolbarDropdown>
  );
};

export const FontToolbar = () => {
  const { t } = useTranslation();
  return (
    <>
      <MarkToolbarButton
        icon={<FormatBold />}
        type={MARK_BOLD}
        tooltip={hotkeyTooltip(t("documents.bold_tooltip"), ["mod", "b"])}
      />
      <MarkToolbarButton
        icon={<FormatItalic />}
        type={MARK_ITALIC}
        tooltip={hotkeyTooltip(t("documents.italic_tooltip"), ["mod", "i"])}
      />
      <MarkToolbarButton
        icon={<FormatUnderlined />}
        type={MARK_UNDERLINE}
        tooltip={hotkeyTooltip(t("documents.underline_tooltip"), ["mod", "u"])}
      />
      <MarkToolbarButton
        icon={<StrikethroughS />}
        type={MARK_STRIKETHROUGH}
        tooltip={hotkeyTooltip(t("documents.strikethrough_tooltip"), ["mod", "shift", "x"])}
      />
    </>
  );
};

export const AddSectionToolbarButton = () => {
  const { t } = useTranslation();
  return (
    <EditorToolbarButton
      id={EditorState.source}
      icon={<AddgroupIcon />}
      type={ELEMENT_SECTION}
      tooltip={hotkeyTooltip(t("documents.section_tooltip"), ["mod", "alt", "s"])}
      active={isActive(ELEMENT_SECTION)}
      onClick={(editor) => {
        createSectionFromNodes(editor);
      }}
    />
  );
};

export const AutogenRulesToolbarButton = () => {
  const { document, id: documentId } = useRuleDocument();
  const openSuggestRulesModal = useOpenSuggestRulesModal();
  const { getSection } = useSections(documentId);
  const { t } = useTranslation();
  const sourceEditor = usePlateEditorRef(EditorState.source);
  const release = useCurrentRelease();
  return (
    <EditorToolbarButton
      id={EditorState.rules}
      icon={<AutogenpenIcon />}
      tooltip={hotkeyTooltip(t("documents.autogen_rules_tooltip"))}
      onClick={(rulesEditor) => {
        const sections = sourceEditor.children.reduce((acc, element) => {
          if (element.type === ELEMENT_SECTION) {
            const section = getSection((element as any).id);
            if (section) {
              acc.push({ ...section, source: element });
            }
            return acc;
          }
          return acc;
        }, [] as SuggestSection[]);

        const onInsert = (section: SuggestSection, rules: TElement[]) => {
          const existingSection = rulesEditor.children.find((e) => e.id === section.id);
          if (existingSection) {
            const path = findNodePath(rulesEditor, existingSection);
            if (path) {
              replaceNodeChildren(rulesEditor, { at: path, nodes: rules });
            }
          } else {
            insertNodes(rulesEditor, createSection(section.id, rules), {
              at: [rulesEditor.children.length],
            });
          }
        };
        openSuggestRulesModal({
          releaseId: release.id,
          model: release.model,
          sections: sections,
          onInsert,
          // releaseId: document.document.release as string,
        });
      }}
    />
  );
};

/** WARNING - this toolbar button only works in the full RuleAuthor context */
export const AutogenSectionToolbarButton = () => {
  const { document } = useRuleDocument();
  const rulesEditor = usePlateEditorRef(EditorState.rules);
  const openAutoSectionModal = useAutoSectionModal();
  const { t } = useTranslation();
  return (
    <EditorToolbarButton
      id={EditorState.source}
      icon={<AutogenpenIcon />}
      tooltip={hotkeyTooltip(t("documents.auto_section_tooltip"))}
      onClick={(editor) => {
        const onInsert = (content: TElement | TElement[], sections: SectionData[]) => {
          replaceNodeChildren(editor, { at: [], nodes: content });
          // we also need to add the sections into rules
          const ruleSections = sections.map((s) => createSection(s.id));
          replaceNodeChildren(rulesEditor, { at: [], nodes: ruleSections });
        };
        openAutoSectionModal({
          source: editor.children,
          onInsert,
          documentId: document.document.id,
        });
      }}
    />
  );
};

export const ListToolbarButtons = () => {
  const { t } = useTranslation();
  return (
    <>
      <BlockToolbarButton
        icon={<FormatListBulleted />}
        type={ELEMENT_UL}
        tooltip={t("documents.bullet_tooltip")}
      />
      <BlockToolbarButton
        icon={<FormatListNumbered />}
        type={ELEMENT_OL}
        tooltip={t("documents.numbered_list_tooltip")}
      />
    </>
  );
};

export const ActiveUsers = ({ documentId }: { documentId: string }) => {
  const editors = useDocumentEditors(documentId);

  if (editors.length === 0) return null;

  return (
    // @ts-ignore TODO fix the typing
    <AvatarGroup size="medium">
      {editors?.map((e) => (
        <Tooltip
          key={e.identity_id}
          title={e.full_name}
          placement="bottom"
          PopperProps={AvatarPopperProps}
        >
          <Avatar
            alt={e.full_name}
            src={"/"}
            size="small"
          />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

export const RichTextToollbar = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Group>
        <TurnIntoDropdownMenu />
        <FontToolbar />
      </Group>
      <FloatingVerticalDivider />
      <Group>
        <ListToolbarButtons />
        <LinkToolbarButton icon={<PubliclinkIcon />} />
      </Group>
      {children}
    </>
  );
};

export const SourceToolbar = ({ hideSection, hideAutogen }: { hideSection?: boolean; hideAutogen?: boolean }) => {
  // alex - this is a hack because the autogen section stuff broke task management
  const context = useContext(RuleDocumentContext);
  const optional = [
    hideSection ? null : <AddSectionToolbarButton key="section" />,
    hideAutogen || !context ? null : <AutogenSectionToolbarButton key="autosection" />,
  ].filter(Boolean);
  return (
    <>
      <Group>
        <TurnIntoDropdownMenu />
        <FontToolbar />
      </Group>
      <FloatingVerticalDivider />
      <Group>
        <ListToolbarButtons />
        <LinkToolbarButton icon={<PubliclinkIcon />} />
      </Group>
      {optional.length === 0 ? null : (
        <>
          <FloatingVerticalDivider />
          {optional}
        </>
      )}
    </>
  );
};

export const RulesToolbar = ({
  editorId = EditorState.rules,
  children,
}: { editorId?: string; children?: ReactNode }) => {
  const rules = usePlateEditorState(editorId);
  const { t } = useTranslation();
  const { isOpen, close, toggle } = useToolbarDropdown();
  const [ruleNode] = getSelectedNode(rules as BaseEditor);
  const disableTurnInto =
    rules.selection &&
    ruleNode &&
    (isTableHeaderRow(rules, rules.selection.anchor.path) ||
      isLastTableRow(rules, rules.selection.anchor.path) ||
      ![ELEMENT_RULE, ELEMENT_PARAGRAPH].includes((ruleNode as TElement).type));

  const control = (
    <DropdownIcon style={disableTurnInto ? { display: "none" } : undefined}>
      <Tooltip title={t("release_centre.turn_into_tooltip")}>
        <Typography variant="body1">
          {getElementLabel((ruleNode as TElement) ?? { type: ELEMENT_PARAGRAPH })}
        </Typography>
      </Tooltip>
    </DropdownIcon>
  );

  const canRuleEdit = true; // isWithinSection(rules);
  const level = getRuleLevel(rules, rules.selection?.anchor.path ?? []);
  const isInOperator = isWithinOperator(rules); //getOperatorLevel(rules, rules.selection?.anchor.path ?? []) > 0;

  return (
    <>
      <Group>
        <ToolbarDropdown
          control={control}
          open={isOpen}
          onOpen={toggle}
          onClose={close}
        >
          <BlockToolbarButton
            icon={
              <MenuIconItem
                icon={<OutcomeIcon />}
                name={t("documents.conclusion_label")}
              />
            }
            type={ELEMENT_CONCLUSION}
            // disable={(editor) => {
            //   // also if level > 1
            //   const [, path] = getSelectedNode(editor);
            //   const level = path ? getRuleLevel(editor, path) : 0;
            //   return !canRuleEdit || level > 1;
            // }}
            active={(editor) => {
              const [node] = getSelectedNode(editor);
              return node?.expression === ELEMENT_CONCLUSION;
            }}
            toggle={(editor) => {
              // convert node based on type
              // TODO what if its a range?
              const [node, path] = getSelectedNode(editor);
              // const level = path ? getRuleLevel(editor, path) : 0;
              if (node && path) {
                // if (node.type === ELEMENT_CONCLUSION) {
                //   // a rule has a ref and body, we want to convert to a paragraph
                //   // we need to get all text and insert it into a new paragraph
                //   ruleToParagraph(editor, path);
                // }
                if (node.expression === ELEMENT_CONDITION) {
                  // convert to conclusion
                  // const [, rulePath] = getNearestRule(editor, path);
                  // handleTabCondition(editor, rulePath, true);
                  // just set it to a conclusion
                  setNodes(editor, { expression: ELEMENT_CONCLUSION }, { at: path });
                } else {
                  // elem is a paragraph, get the text and insert into a new rule
                  paragraphToRule(editor, path, ELEMENT_CONCLUSION);
                  // if (level === 1) {
                  //   moveNodes(editor, { at: path, to: Path.next(Path.parent(path)) });
                  // }
                }
              }
              return;
            }}
          />
          <BlockToolbarButton
            icon={
              <MenuIconItem
                icon={<AddruleIcon />}
                name={t("documents.condition_label")}
              />
            }
            type={ELEMENT_CONDITION}
            active={(editor) => {
              const [node] = getSelectedNode(editor);
              return node?.expression === ELEMENT_CONDITION;
            }}
            // disable={(editor) => {
            //   const [node, path] = getSelectedNode(editor);
            //   const level = path ? getRuleLevel(editor, path) : 0;
            //   return !canRuleEdit || (node?.type === ELEMENT_PARAGRAPH && level < 1);
            // }}
            toggle={(editor) => {
              const [node, path] = getSelectedNode(editor);
              if (node && path) {
                if (node.type === ELEMENT_PARAGRAPH) {
                  // convert to condition
                  paragraphToRule(editor, path, ELEMENT_CONDITION);
                } else if (node.expression === ELEMENT_CONCLUSION) {
                  // handleTab(editor, false);
                  setNodes(editor, { expression: ELEMENT_CONDITION }, { at: path });
                }
              }
            }}
          />
          <BlockToolbarButton
            icon={
              <MenuIconItem
                icon={<NonruleIcon />}
                name={t("documents.paragraph_label")}
              />
            }
            /*disable={(editor) => {
              const [node, path] = getSelectedNode(editor);
              return !canRuleEdit || Boolean(path && getRuleLevel(editor, path) > 0);
            }}*/
            type={ELEMENT_PARAGRAPH}
            // disable={(editor) => !editor.selection || (!isInOperator && isWithinSection(editor))}
            toggle={(editor) => {
              // if paragraph, do nothing
              // if rule, need to convert to paragraph
              const [node, path] = getSelectedNode(editor);
              if (node && path) {
                if (node.type === ELEMENT_PARAGRAPH) return;
                if (isRule(editor, path)) {
                  ruleToParagraph(editor, path);
                  return;
                }
                // else no idea what it is, just hard text convert it
                const text = Array.from(getNodeTexts(node))
                  .map(([n]) => n.text)
                  .filter((t) => t.trim().length > 0)
                  .join(" ");
                withoutNormalizing(editor, () => {
                  removeNodes(editor, { at: path });
                  insertNodes(editor, createParagraph(text), { at: path, select: true });
                });
              }
            }}
          />
        </ToolbarDropdown>
        <EditorToolbarButton
          id={EditorState.rules}
          icon={<AddruleIcon />}
          type={ELEMENT_OPERATOR}
          tooltip={hotkeyTooltip(t("documents.operator_tooltip"), ["mod", "o"])}
          // disable if no selection, or selected node is paragraph
          disable={(editor) => !editor.selection || level < 1 || getSelectedNode(editor)[0]?.type === ELEMENT_PARAGRAPH}
          onClick={(editor) => {
            insertOperator(editor);
          }}
        />
        <EditorToolbarButton
          id={EditorState.rules}
          icon={<FormatIndentIncreaseOutlined />}
          type={ELEMENT_CONNECTOR}
          tooltip={hotkeyTooltip(t("documents.indent_tooltip"), ["mod", "g"])}
          // disable if no selection, or selected node is paragraph
          disable={(editor) => !editor.selection || level < 1 || getSelectedNode(editor)[0]?.type === ELEMENT_PARAGRAPH}
          onClick={(editor) => {
            const range = editor.selection;
            if (range) {
              insertConnector(editor, range);
            }
          }}
        />
        <EditorToolbarButton
          id={EditorState.rules}
          icon={<KeyboardReturn />}
          type={ELEMENT_HR}
          tooltip={hotkeyTooltip(t("documents.rule_break_tooltip"), ["mod", "shift", "enter"])}
          disable={(editor) => !editor.selection || !isInOperator}
          onClick={(editor) => editor.selection && insertRuleBreak(editor, Path.parent(editor.selection.anchor.path))}
        />
        {/* <BlockToolbarButton icon={<FormatListBulleted />} type={ELEMENT_UL} tooltip='Bullet list' /> */}
        {/* TODO actually implement tables */}
        {/* <BlockToolbarButton icon={<AddtableIcon />} type={ELEMENT_TABLE} tooltip='Insert Decision Table' disable={true} /> */}
        <TablesToolbar />
        {children}
      </Group>
    </>
  );
};
