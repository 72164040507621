import { ContextMenuItem, MenuC as Menu, useContextMenu, ContextMenuItemNested } from "@components";
import { MenudotsIcon } from "@icons";
import { useEditAttributeModal } from "@pages/models/release/DataModel/route/Attributes/AttributeEditModal";
import { useCurrentRelease } from "@common";
import { GraphNode } from "@packages/commons";
import { isStrNotNullOrBlank } from "../../util/UtilString";
import { useNavigateToDocument } from "../../../common/hooks/HooksDocuments";
import { useRouting } from "../../../common/hooks/HooksRouting";
import { useGraphVisualisation } from "../../GraphVisualisation/hooks/useGraphVisualisation";
import { GraphVizType } from "../../GraphVisualisation/providers/RuleGraphProvider";

import stl from "./GraphNode.module.scss";

interface GraphNodeContextMenuProps {
  node: GraphNode;
  isGoal?: boolean;
  isTargetNode: boolean;
}

const GraphNodeContextMenu = (props: GraphNodeContextMenuProps) => {
  const { node, isGoal, isTargetNode } = props;
  const openEditAttributeModal = useEditAttributeModal();
  const release = useCurrentRelease();
  const navigateToDocument = useNavigateToDocument();
  const isProdRelease = release?.env === "prod";
  const { goToPage } = useRouting();
  const {
    setGraphVizType,
    setSelectedNodeId,
    setRestrictedViewRootPath,
    rstRestrictedView,
  } = useGraphVisualisation();

  const {
    onContextMenu,
    isOpen,
    props: menuProps,
    close: closeMenu,
  } = useContextMenu();

  const {
    definedIn,
    usedIn,
  } = node;
  const hasDefinedIn = definedIn && isStrNotNullOrBlank(definedIn);
  const usedInSansDefinedIn = hasDefinedIn ? (usedIn || []).filter((doc) => doc !== definedIn) : (usedIn || []);
  const hasUsedIn = usedInSansDefinedIn && usedInSansDefinedIn.length > 0;

  // -- menu handlers

  const nop = () => { };

  const handleClickEdit = () => openEditAttributeModal(node.id);

  const handleClickDebug = () => {
    if (node.id) {
      goToPage("/build/debugger", { goalId: node.id });
    }
  };

  const handleClickGoToDefinition = () => {

    if (hasDefinedIn) {
      navigateToDocument(definedIn, false);
    }
  };

  const handleViewInGraphViz = (graphVizType: GraphVizType) => () => {
    setSelectedNodeId(node.id);
    setGraphVizType(graphVizType);
  };

  const handleSetAsFocusNode = () => {
    setRestrictedViewRootPath(node.path || node.id);
  };

  const handleClearFocusNode = () => {
    rstRestrictedView();
  };

  // -- menu items

  const createMenuAction = (label: string, action: () => void) => {

    return (
      <ContextMenuItem
        key={label}
        button={true}
        onClick={() => {
          action();
          closeMenu();
        }}
      >
        {label}
      </ContextMenuItem>
    );
  };

  // --

  const renderUsedInMenuItems = () => {

    const usedInMenuItems = usedInSansDefinedIn.map((doc) => {
      return createMenuAction(doc, doc ? () => {
        navigateToDocument(doc, false);
      } : nop);
    });

    return (
      <ContextMenuItemNested
        label="Used in..."
        parentMenuOpen={isOpen}
        onClick={nop}
      >
        {usedInMenuItems}
      </ContextMenuItemNested>
    );
  };

  const renderViewInMenuItems = () => {
    return (
      <ContextMenuItemNested
        label="View in..."
        parentMenuOpen={isOpen}
        onClick={nop}
      >
        <ContextMenuItem
          button={true}
          onClick={handleViewInGraphViz("nebula")}
        >
          Nebula
        </ContextMenuItem>
      </ContextMenuItemNested>
    );
  }

  return (<>
    <div
      id={`graph_ignoreclick_kebab_menu_${node.id}`}
      className={stl.menu_icon}
      /** clicks are intercepted above in components/RuleGraph_FullRelease/index.tsx onNodeClickHandler(...), so we need to pick another event */
      // onContextMenu={onContextMenu}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onContextMenu(e);
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <MenudotsIcon />
    </div>
    {
      isOpen
        ?
        <Menu
          {...menuProps}
        >
          {isProdRelease ? null : createMenuAction("Edit", handleClickEdit)}
          {isGoal ? createMenuAction("Debug", handleClickDebug) : null}
          {renderViewInMenuItems()}
          {/* {createMenuAction("Verify", nop)} */}
          {/* {createMenuAction("View in...", nop)} */}
          {hasDefinedIn ? createMenuAction("Go to definition", handleClickGoToDefinition) : null}
          {hasUsedIn ? renderUsedInMenuItems() : null}
          {isTargetNode ? createMenuAction("Clear focus", handleClearFocusNode) : createMenuAction("Set as focus node", handleSetAsFocusNode)}
        </Menu>
        :
        null
    }
  </>);
};

export default (GraphNodeContextMenu);