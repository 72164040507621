import { ReactNode, useCallback, useState } from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import debounce from "lodash/debounce";
import { SearchIcon } from "@icons";
import { useTranslation } from "react-i18next";

export type SearchProps = Omit<TextFieldProps, "value" | "onChange"> & {
  value: string;
  setValue: (value: string) => void;
  icon?: ReactNode;
  noSetValueOnClear?: boolean;
  onClear?: () => void;
};

export type SearchPropsWithFilter = SearchProps & {
  /** The debounced value, includes wild cards for fuzzy search. */
  filter: string;
};

export type UseSearchProps = Omit<SearchProps, "value" | "setValue"> & {
  /** Transform function applied to the debounced filter value.
   * Defaults to `*${value.trim()}*`
   */
  transformValue?: (value: string) => string;
  showClear?: boolean;
};

const defaultTransform = (value: string) => `*${value.trim()}*`;

export const useSearch = ({ transformValue = defaultTransform, showClear = false, onClear, ...props }: UseSearchProps = {}): SearchPropsWithFilter => {
  // main value for text field
  const [value, setValue] = useState("");
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();

  const debouncedSearch = useCallback(debounce(setFilter, 200), [setFilter]);

  const setSearch = (value: string) => {
    setValue(value);
    // debounce the filter to avoid too many requests
    debouncedSearch(value.trim().length > 0 ? transformValue(value) : "");
  };

  const innerOnClear = () => {
    if (!props.noSetValueOnClear) {
      setSearch("");
    }
    onClear?.();
  };

  return {
    filter,
    value,
    setValue: setSearch,
    placeholder: t('search') + "...",
    icon: <SearchIcon style={{ marginRight: "0.25rem" }} />,
    onClear: showClear ? innerOnClear : undefined,
    ...props
  };
};