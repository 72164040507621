import { useRef, FunctionComponent, ReactNode } from 'react';
import { Theme } from '@material-ui/core/styles';
import { extendMoment } from "moment-range";
import Moment from "moment";
import { AssigneesData } from '@packages/commons';
import { AuthUser } from 'services';

/**
 * Moment re-exported with range support
 */
// @ts-ignore
export const moment = extendMoment(Moment);

export const filterValues = (filter, ...values) => {
  return values.some(v => v.toLowerCase().includes(filter.toLowerCase()));
};

/*
  Counts number of occurrences of query in array, an integer >= 0
  Uses the javascript == notion of equality.
*/
export const count = (list, query) => {
  let count = 0;
  for (let i = 0; i < list.length; i++) if (typeof query === 'function' ? query(list[i]) : list[i] === query) count++;

  return count;
};

export const countDecimals = (value: number): number => {
  if (isNaN(value)) {
    return 0;
  }

  if (Math.floor(value.valueOf()) === value.valueOf()) {
    return 0;
  }

  var str = value.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return (Number(str.split("-")[1] || 0));
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }

  return (Number(str.split("-")[1] || 0));
};

export const useMultiClick = (onClick, onDoubleClick, debounce = 300) => {
  const timeout = useRef<number | null>(null);

  const singleClick = e => {
    if (timeout.current === null) {
      if (onClick) e.preventDefault();

      timeout.current = window.setTimeout(() => {
        timeout.current = null;
        onClick?.();
      }, debounce);
    }
  };

  const doubleClick = e => {
    if (timeout.current) {
      e.preventDefault();
      window.clearTimeout(timeout.current);
      timeout.current = null;
      onDoubleClick?.();
    }
  };

  const handleClick = e => {
    switch (e.detail) {
      case 1:
        singleClick(e);
        break;
      case 2:
        doubleClick(e);
        break;
      default:

    }
  };

  return handleClick;
};

// default to 1 if it doesn't exist (bad data)
export const getReleaseUiName = e => `Release ${e.releaseNo ?? 1}`;

const boldRegex = /\*\*(?<bold>[^*]*)\*\*/g;
/**
 * Converts a string with markdown style bold flags into a list of elements
 * with bold replaced with HTML <b> tags.
 *
 * Any text with \*\*text\*\* will be replaced with \<b>text\</b>.
 */
export const formatText = (text: string) => {
  const res = [...text.matchAll(boldRegex)];
  const vals = res.map(r => ({ s: r.index, e: (r.index ?? 0) + r[0].length, val: r[1] }));
  const arr: ReactNode[] = [];
  let txt = text;
  for (let i = 0; i < vals.length; i++) {
    const val = vals[i];
    const s = txt.substring(0, val.s);
    txt = txt.substring(val.e);
    arr.push(s);
    arr.push(<b>{val.val}</b>);
    if (i === vals.length - 1) {
      arr.push(txt);
    }
  }

  return arr;
};

function getWait(wait) {
  return (typeof wait === 'function') ? wait() : wait;
}

type Deffered = {
  promise: Promise<any>;
  resolve: (value: any) => void;
  reject: (reason: any) => void;
};

function defer() {
  const deferred: any = {};
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred as Deffered;
}

interface DebounceOptions {
  leading?: boolean;
  accumulate?: boolean;
}

/**
 * Debounce a promise
 *
 * Code from: https://github.com/bjoerge/debounce-promise
 */
export const debounce = (fn: (...args: any) => any, wait = 0, options: DebounceOptions = {}) => {
  let lastCallAt;
  let deferred;
  let timer;
  let pendingArgs: any[] = [];
  return function debounced(this: any, ...args) {
    const currentWait = getWait(wait);
    const currentTime = new Date().getTime();

    const isCold = !lastCallAt || (currentTime - lastCallAt) > currentWait;

    lastCallAt = currentTime;

    if (isCold && options.leading) {
      return options.accumulate
        ? Promise.resolve(fn.call(this, [args])).then(result => result[0])
        : Promise.resolve(fn.call(this, ...args));
    }

    if (deferred) {
      clearTimeout(timer);
    } else {
      deferred = defer();
    }

    pendingArgs.push(args);
    timer = setTimeout(flush.bind(this), currentWait);

    if (options.accumulate) {
      const argsIndex = pendingArgs.length - 1;
      return deferred.promise.then(results => results[argsIndex]);
    }

    return deferred.promise;
  };

  function flush(this: any) {
    const thisDeferred = deferred;
    clearTimeout(timer);

    Promise.resolve(
      options.accumulate
        ? fn.call(this, pendingArgs)
        : fn.apply(this, pendingArgs[pendingArgs.length - 1]),
    )
      .then(thisDeferred.resolve, thisDeferred.reject);

    pendingArgs = [];
    deferred = null;
  }
};

export const getSCTheme = (p: any) => p.theme as Theme;


/**
 * HOC adding default props.
 */
export const withProps: <T>(
  Component: FunctionComponent<T>,
  props: Partial<T>
) => FunctionComponent<any> = (Component, props) => function WithPropsHOC(_props) {
  return <Component {...props} {..._props} />;
};

export const roundToMaxDigitsAfterDot = (v: number, maxDigitsAfterDot = 4): number => (
  Number(v.toFixed(maxDigitsAfterDot))
);

export function autoFormatIgnoreTimezone(datetime) {
  let givenTimezoneOffset = moment.parseZone(datetime).utcOffset()
  let dt;
  if (givenTimezoneOffset !== 0) {
    dt = moment(datetime).utcOffset(givenTimezoneOffset)
  }
  else {
    // big problem: we do not know whether timezone has not been given or it is +00:00
    // if its utc time is the same with itself, it means timezone has been given
    let u = moment(datetime).utc().format('YYYY-MM-DD HH:mm:ss')
    // @ts-ignore
    let t = moment(datetime).parseZone(datetime).format('YYYY-MM-DD HH:mm:ss')
    if (u === t) {
      dt = moment(datetime).utc()
    }
    else {
      dt = moment(datetime)
    }
  }

  if (!dt.isSame(moment(dt).startOf('day'))) return dt.format('YYYY-MM-DD HH:mm:ss');
  else return dt.format('YYYY-MM-DD');
}

export function formatIgnoreTimezone(datetime, formatter, givenFormatter?) {
  let givenTimezoneOffset = moment.parseZone(datetime).utcOffset()

  if (givenTimezoneOffset !== 0) {
    return moment(datetime, givenFormatter).utcOffset(givenTimezoneOffset).format(formatter)
  }
  else {
    // big problem: we do not know whether timezone has not been given or it is +00:00
    // if its utc time is the same with itself, it means timezone has been given
    let u = moment(datetime, givenFormatter).utc().format('YYYY-MM-DD HH:mm:ss')
    // @ts-ignore
    let t = moment(datetime, givenFormatter).parseZone(datetime).format('YYYY-MM-DD HH:mm:ss')
    if (u === t) {
      return moment(datetime, givenFormatter).utc().format(formatter)
    }
    else {
      return moment(datetime, givenFormatter).format(formatter)
    }
  }
};

export const formatUserFullName = (user: Pick<AssigneesData, 'firstName' | 'lastName'>) =>
  // don't trust that these exist, AD can be weird
  [user.firstName ?? "", user.lastName ?? ""]
    .map(s => s.trim())
    .filter(s => Boolean(s))
    .join(" ");

export const userToAssignee = (user: AuthUser): AssigneesData => ({
  id: user.id,
  identity_id: user.identity_id,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  name: formatUserFullName({ firstName: user.first_name, lastName: user.last_name } as AssigneesData),
});