import React from 'react';
import styled from 'styled-components';

import { getValueAtDate, isIncrement } from '@common';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView, { TreeViewProps } from '@material-ui/lab/TreeView';

import {
    ContactDataTreeCtxValue, ContactDataTreeProvider, useContactDataTree, useContactDataTreeNode
} from './OtherDataTree_Ctx';
import { DataLabel, DataValue } from './Texts';
import { ContactOtherDataNodeLeaf } from './types';

const processItemValue = (v: ContactOtherDataNodeLeaf['value']): React.ReactNode => {
  const val = getValueAtDate(v, new Date());

  if (isIncrement(val)) return `Every ${val.increment} starting from ${val.offset}`;
  if (typeof val === 'string' || typeof val === 'number') return String(val);
  if (typeof val === 'boolean') return val ? 'True' : 'False';
  if (val === undefined) return 'Unknown';
  if (val === null) return 'Uncertain';
  console.log('processItemValue', v, val);
  // console.warn('TYU90Wt1oo | Value not supported', v);
  return "UNSUPPORTED VALUE";
};

const LinkSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: 'relative', top: '0.25rem', left: '0.25rem' }}
  >
    <path d="M4.79167 2.5C3.53343 2.5 2.5 3.53343 2.5 4.79167V15.2083C2.5 16.4666 3.53343 17.5 4.79167 17.5H15.2083C16.4666 17.5 17.5 16.4666 17.5 15.2083V4.79167C17.5 3.53343 16.4666 2.5 15.2083 2.5H4.79167ZM4.79167 3.75H15.2083C15.7909 3.75 16.25 4.20907 16.25 4.79167V15.2083C16.25 15.7909 15.7909 16.25 15.2083 16.25H4.79167C4.20907 16.25 3.75 15.7909 3.75 15.2083V4.79167C3.75 4.20907 4.20907 3.75 4.79167 3.75ZM14.3628 4.9943C14.3402 4.99498 14.3176 4.99688 14.2952 5H9.79167C9.70885 4.99883 9.62662 5.01413 9.54976 5.04501C9.47291 5.0759 9.40296 5.12175 9.34398 5.1799C9.285 5.23805 9.23816 5.30735 9.20619 5.38376C9.17423 5.46017 9.15776 5.54217 9.15776 5.625C9.15776 5.70783 9.17423 5.78983 9.20619 5.86624C9.23816 5.94265 9.285 6.01195 9.34398 6.0701C9.40296 6.12825 9.47291 6.1741 9.54976 6.20499C9.62662 6.23587 9.70885 6.25117 9.79167 6.25H12.8662L6.43311 12.6831C6.37312 12.7407 6.32524 12.8097 6.29225 12.886C6.25926 12.9623 6.24183 13.0445 6.24098 13.1276C6.24014 13.2108 6.25589 13.2933 6.28732 13.3702C6.31875 13.4472 6.36523 13.5172 6.42403 13.576C6.48283 13.6348 6.55277 13.6812 6.62975 13.7127C6.70674 13.7441 6.78922 13.7599 6.87237 13.759C6.95552 13.7582 7.03766 13.7407 7.11399 13.7078C7.19032 13.6748 7.2593 13.6269 7.31689 13.5669L13.75 7.13379V10.2083C13.7488 10.2912 13.7641 10.3734 13.795 10.4502C13.8259 10.5271 13.8717 10.597 13.9299 10.656C13.9881 10.715 14.0573 10.7618 14.1338 10.7938C14.2102 10.8258 14.2922 10.8422 14.375 10.8422C14.4578 10.8422 14.5398 10.8258 14.6162 10.7938C14.6927 10.7618 14.7619 10.715 14.8201 10.656C14.8783 10.597 14.9241 10.5271 14.955 10.4502C14.9859 10.3734 15.0012 10.2912 15 10.2083V5.70394C15.0123 5.61402 15.0049 5.52249 14.9783 5.43572C14.9516 5.34895 14.9064 5.26903 14.8458 5.20149C14.7852 5.13396 14.7105 5.08045 14.6271 5.04467C14.5437 5.00889 14.4535 4.9917 14.3628 4.9943Z" fill="#0A0A0A" />
  </svg>
);
const StyledDataLabel = styled(DataLabel)`
  width: 300px;
  padding-right: 1rem;
`;

const StyledDataValue = styled(DataValue)`
  &.linkLike {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledTreeView = styled(TreeView)`
  .MuiTreeItem-group {
    margin-left: 0.5rem;
  }

  .MuiTreeItem-label {
    display: flex;
    padding: 0.25rem 0;
  }
  
  /* .MuiTreeItem-root:hover {
    .MuiTreeItem-content {
      background-color: ${p => p.theme.palette.background.hover};
    }
  } */
`;

const NodeLabel: React.FC<Pick<ContactOtherDataNodeLeaf, 'label' | 'value' | 'allValues'>> = React.memo(p => {
  const { label, value, allValues } = p;
  const processedValue = React.useMemo(() => processItemValue(value), [value]);

  return (
    <>
      <StyledDataLabel>{label}</StyledDataLabel>
      <StyledDataValue className={allValues && 'linkLike'}>
        {processedValue}
        {allValues && <LinkSvg />}
      </StyledDataValue>
    </>
  );
});

const ContactDataTreeNode = React.memo<{ id: string }>(({ id }) => {
  const maybeNode = useContactDataTreeNode(id);
  const label = React.useMemo(() => {
    if (maybeNode === null) return null;

    return maybeNode.type === 'dir'
      ? <DataLabel>{maybeNode.label}</DataLabel>
      : <NodeLabel label={maybeNode.label} value={maybeNode.value} allValues={maybeNode.allValues} />
  }, [maybeNode]);

  const children = React.useMemo(() => {
    if (maybeNode === null || maybeNode.type !== 'dir') return null;

    return maybeNode.children.map(child => <ContactDataTreeNode id={child.id} />);
  }, [maybeNode]);


  if (maybeNode === null) return null;

  return (
    <TreeItem nodeId={maybeNode.id} label={label}>
      {children}
    </TreeItem>
  );
});

const ContactDataTreeCore = (props: TreeViewProps) => {
  const { setExpanded, expanded, tree, onSelectField } = useContactDataTree();

  const onNodeToggleHandler: TreeViewProps['onNodeToggle'] = (_, ids) => {
    setExpanded(() => ids);
  };

  return (
    <StyledTreeView
      expanded={expanded}
      onNodeToggle={onNodeToggleHandler}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={(_, id) => onSelectField(id)}
      {...props}
    >
      {tree.children.map(child => (
        <ContactDataTreeNode id={child.id} />
      ))}
    </StyledTreeView>
  );
};

export type ContactDataTreeProps = {
  tree: ContactDataTreeCtxValue['tree'];
  onSelectField: ContactDataTreeCtxValue['onSelectField'];
} & TreeViewProps;

export const ContactDataTree: React.FC<ContactDataTreeProps> = ({ tree, onSelectField, ...props }) => {
  return (
    <ContactDataTreeProvider tree={tree} onSelectField={onSelectField}>
      <ContactDataTreeCore {...props} />
    </ContactDataTreeProvider>
  );
};
