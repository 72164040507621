import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  width: 100%;
  border: none;
  padding: 0;
  background: none;
`;

export type InlineEditProps = {
  value: string;
  onChange: (v: string) => unknown;
  /** If true, will auto highlight all text. This means its also autoFocus */
  autoHighlight?: boolean;
} & any;

export const InlineEdit = ({ value, onChange, autoHighlight, ...props }: InlineEditProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [state, setState] = useState(value);

  const handleChange = (e) => {
    const { value } = e.currentTarget;
    setState(value);
    onChange(value);
  };

  useEffect(() => {
    if (ref.current) {
      const input = ref.current;
      setTimeout(() => {
        // if input has focus
        const isFocused = document.activeElement === input;
        // make sure we only auto highlight if its not focused
        if (autoHighlight && !isFocused) {
          input.focus();
          input.setSelectionRange(0, input.value.length);
        }
      }, 10);
    }
  }, [ref.current]);

  return <Input ref={ref} value={state} onChange={handleChange} {...props} />;
};
InlineEdit.displayName = 'components/InlineEdit';
