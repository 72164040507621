import { PropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';

import { trimDocName, useNavigateToDocument } from '@common/hooks/HooksDocuments';
import { useFullRelease } from '@common/hooks_useFullRelease';
import { isMod } from '@components/kdb';
import { DocumentLink, Flex, Stack, TextLabel } from '@components/meta';
import { DefinedInIcon, DocumentsIcon, InterviewIcon } from '@icons';
import { Typography } from '@material-ui/core';
import { BoxProps } from '@mui/material';
import { GraphNode } from '@packages/commons';

const processSteps = (steps, id, interviewName, usage) => {
  if (!steps) return;
  steps.forEach(step => {
    if (step.layout) {
      step.layout.forEach((layout, index) => {
        if (layout.attributes && layout.attributes.indexOf(id)) usage.push(step.layout.length > 1 ? `Step "${step.title}" (Layout: ${index}) of interview "${interviewName}"` : `Step "${step.title}" of interview "${interviewName}"`)
      })
    }
    if (step.steps && step.steps.length > 0) processSteps(step.steps, id, interviewName, usage);
  })
}

const useInterviewUsage = (attribute) => {
  const release = useFullRelease();
  let interviewUsage: string[] = [];
  if (release && release.interviews) {
    // Attempt to find if this attribute is used in a screen in this interview
    release.interviews.forEach(interview => {
      processSteps(interview.steps, attribute.id, interview.name, interviewUsage);
    })
  }
  return interviewUsage;
};

export type AttributeUsageProps = {
  node: GraphNode;
  showUsedIn?: boolean;
  showInterviews?: boolean;
  /** If true, remove definedIn from the usedIn list */
  noDuplicates?: boolean;
  /** Return true to override default behaviour, otherwise will open the document */
  onClick?: (e: React.MouseEvent, doc: string) => boolean | void;
} & PropsWithoutRef<Omit<BoxProps, "sx" | "onClick">>;

export const AttributeUsage = ({ node, onClick, showUsedIn = true, showInterviews = false, noDuplicates = false, ...props }: AttributeUsageProps) => {
  const navigateToDocument = useNavigateToDocument();
  const definedIn = node.definedIn ? trimDocName(node.definedIn) : null;
  const usedIn = node.usedIn?.map(trimDocName)
    // if noDuplicates is true, remove definedIn from the usedIn list
    .filter(doc => !noDuplicates || doc !== definedIn);
  const interviewUsage = useInterviewUsage(node);
  const hasUsedIn = usedIn && usedIn.length > 0;
  const displayUsedIn = showUsedIn && hasUsedIn;
  const { t } = useTranslation();

  const onDocumentClick = (e: React.MouseEvent, doc: string) => {
    if (onClick) {
      const res = onClick(e, doc);
      // the consumer can return true to prevent the default behaviour
      if (res === true) return;
    }
    const newWindow = isMod(e);
    navigateToDocument(doc, newWindow);
  };

  return (
    <Stack gridGap="0.5rem" {...props}>
      {definedIn ? (
        <Flex style={{ alignItems: "flex-start", gap: "1rem" }}>
          <TextLabel variant="caption">
            <DefinedInIcon />
            {t('defined_in')}
          </TextLabel>
          <Stack>
            <DocumentLink
              variant="caption"
              onClick={(e) => onDocumentClick(e, definedIn)}
            >
              {definedIn}
            </DocumentLink>
          </Stack>
        </Flex>
      ) : null}
      {displayUsedIn ? (
        <Flex
          style={{
            alignItems: "flex-start",
            gap: "1rem",
            borderTop: definedIn ? "1px solid #e5e5e5" : "none",
            paddingTop: definedIn ? "0.5rem" : "none",
          }}
        >
          <TextLabel variant="caption">
            <DocumentsIcon />
            {t('used_in')}
          </TextLabel>
          <Stack style={{ flex: 1 }}>
            {usedIn.map((doc) => (
              <DocumentLink
                variant="caption"
                key={doc}
                onClick={(e) => onDocumentClick(e, doc)}
              >
                {doc}
              </DocumentLink>
            ))}
          </Stack>
        </Flex>
      ) : null}
      {showInterviews && interviewUsage && interviewUsage.length > 0 ? (
        <Flex
          style={{
            alignItems: "flex-start",
            gap: "1rem",
            borderTop: (definedIn || displayUsedIn) ? "1px solid #e5e5e5" : "none",
            paddingTop: (definedIn || displayUsedIn) ? "0.5rem" : "none",
          }}
        >
          <TextLabel variant="caption">
            <InterviewIcon />
            {t('interviews')}
          </TextLabel>
          <Stack style={{ flex: 1 }}>
            {interviewUsage.map((usage) => (
              <Typography key={usage} variant="caption">
                {usage}
              </Typography>
            ))}
          </Stack>
        </Flex>
      ) : null}
    </Stack>
  );
};