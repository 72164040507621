import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showModal } from "@modals";
import { ContactsMappingModal, ContactsMappingModalProps } from './Modal';
import { useBasicModal } from "@modals/Modals";


export type OpenContactsMappingModalFArg = ContactsMappingModalProps; // Pick< ContactsMappingModalProps, 'mapping' | 'options' | 'save' >;
export type OpenContactsMappingModalF = (arg: OpenContactsMappingModalFArg) => unknown;
export const useOpenContactsMappingModal = (): OpenContactsMappingModalF => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openBasicModal = useBasicModal();

  return React.useCallback(arg => {
    openBasicModal({
      children: <ContactsMappingModal { ...arg }/>,
      title: t('contacts_mapping_modal.title'),
      maxWidth: 'lg',
    });
  }, [dispatch, t, openBasicModal]);
};
