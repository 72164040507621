import { capitalize, find, get } from "lodash";
import { documentService } from "services";
import styled from "styled-components";
import { hexToHSL } from "theme";

import { SourceEditor, createSourcePlugins } from "@common/editor/RuleAuthor/SourceEditor";
import {
  AttributeIcon,
  GraphNodeType,
  type GraphNodeWithType,
  getGraphNodeTypeColor,
  getNodeType,
} from "@common/graph";
import { trimDocName } from "@common/hooks/HooksDocuments";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { scrollableMixin } from "@common/scrollbar";
import { Flex, Label, Stack } from "@components/meta";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/radix";
import {
  BooleanIcon,
  CompareIcon,
  DocumentsIcon,
  ExternallinkIcon,
  GlossaryIcon,
  GuidIcon,
  LabelIcon,
  SourcecodeIcon,
  TooltipIcon,
} from "@icons";
import { IconButton, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useEditAttributeModal } from "@pages/models/release/DataModel/route/Attributes/AttributeEditModal";
import { AttributeExplanation } from "@pages/models/release/DataModel/route/Attributes/AttributeExplanation";
import { AttributeIDField } from "@pages/models/release/DataModel/route/Attributes/AttributeIDField";
import { AttributeUsage } from "@pages/models/release/DataModel/route/Attributes/AttributeUsage";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import { FileTextIcon } from "@radix-ui/react-icons";
import { FloatingVerticalDivider, PlateProvider } from "@udecode/plate";

import { AliasInfo } from "./AliasCard";

import type { Trigger } from "@packages/commons";
import type { AliasExpression, AttributeExpression, BaseExpression } from "@packages/compiler/src/Parser";

const TabToolbar = styled(TabsList)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  gap: 0.25rem;
  /* box-shadow: 0px -5px 10px 0px #0000001A; */

  .MuiIconButton-root {
    background-color: ${(p) => p.theme.palette.background.light};
    border: 1px solid ${(p) => p.theme.palette.background.border};
    &:hover {
      background-color: ${(p) => p.theme.palette.background.hover};
    }
    &[data-state="active"] {
      background-color: ${(p) => p.theme.palette.primary.main};
      ::before {
        content: "";
        position: absolute;
        top: calc(-0.5rem - 2px); // offset padding and border
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${(p) => p.theme.palette.primary.main};
        /* opacity: 0.1; */
      }
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const TabRoot = styled(Tabs)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabContent = styled(TabsContent)`
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid ${(p) => p.theme.palette.background.border};
  outline: none;
  ${scrollableMixin};

  &[data-state="active"] {
    display: flex;
  }
`;

const AttributeContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  align-items: center;
  justify-content: flex-start;
  color: ${(p) => p.theme.palette.text.primary};
  border-top: 1px solid ${(p) => p.theme.palette.background.border};

  [data-icon] {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid ${(p) => p.theme.palette.background.default};
    height: 2rem;
    width: 2rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .MuiTypography-root {
    flex: 1;
    font-style: italic;
    font-size: 1rem;
  }

  [data-actions] {
    [class*=FloatingVerticalDivider] {
      height: 1rem;
      margin: 0 0.25rem;
      background-color: ${(p) => p.theme.palette.background.contrastText};
    }
    .MuiIconButton-root {
      &:hover {
        background-color: #00000010;
      }
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const AttributeDefinedIn = styled(AttributeUsage)`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${(p) => p.theme.palette.background.border};
`;

const RulesContent = ({ node }: { node: GraphNodeWithType }) => {
  return (
    <Stack style={{ fontSize: "0.75rem" }}>
      {node.definedIn ? (
        <AttributeDefinedIn
          node={node}
          showUsedIn={false}
        />
      ) : null}
      <AttributeExplanation node={node} />
    </Stack>
  );
};

const SourceContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  /* editor overrides to clean up padding */
  [data-slate-editor="true"] {
    padding: 0;
    > * {
      padding: 0;
    }
  }
`;

const SOURCE_IN_ATTR_POPUP_ID = "source-in-attr-popup";
const SourceContent = ({ node }) => {
  const release = useFullReleaseStrict();

  // Find the document that this is defined in
  if (!node || !node.definedIn) return <p></p>;
  const path = trimDocName(String(node.definedIn)).split("/");
  path.shift();
  const info = get(release.documents, path);
  const { data, isLoading } = documentService.useGetOne(info.reference, {});

  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (!data) {
    return <span>Error getting document</span>;
  }

  const sourceContents = (() => {
    if (get(data, ["contents", "sections", "length"], null) === 0) {
      // Special case there - are no sections.
      // Show the entire document?
      return get(data, ["contents", "source"], null);
    }

    // Look through the rules and find the conclusion for this node
    const conclusion = find(get(data, ["contents", "rules"], []), (el) => {
      if (el.type === "conclusion" && el.text.includes(node.description)) return true;
      else return false;
    });

    if (conclusion && conclusion.sectionId) {
      // Show this section
      const section = find(get(data, ["contents", "source"], []), { type: "section", id: conclusion.sectionId });
      // unpack the section as we only want the children
      if (section) return section.children;
    }

    return null;
    // return 'Not able to retrieve source';
  })();

  return (
    <SourceContentWrap>
      <AttributeDefinedIn
        node={node}
        showUsedIn={false}
      />

      {sourceContents === null ? null : (
        <PlateProvider
          id={SOURCE_IN_ATTR_POPUP_ID}
          plugins={createSourcePlugins()}
          initialValue={Array.isArray(sourceContents) ? sourceContents : [sourceContents]}
        >
          <SourceEditor
            id={SOURCE_IN_ATTR_POPUP_ID}
            editableProps={{ readOnly: true }}
          />
        </PlateProvider>
      )}
    </SourceContentWrap>
  );
};

export const Attribute = ({ node, parent }: { node: GraphNodeWithType | null; parent: string }) => {
  const editAttributeModal = useEditAttributeModal();

  if (!node) {
    const nodeType = parent === "conclusion" ? GraphNodeType.DERIVED : GraphNodeType.INPUT;
    node = { id: "pending", entity: "Pending document save", nodeType, type: "auto" };
  }

  if (parent === "conclusion" && node.nodeType === GraphNodeType.INPUT) {
    // warnings.push("Derived attribute (pending document save; currently an input attribute)");
    // change it to derived
    // TODO the logic for this is technically not correct, as it would only be derived if it has a child
    // a conclusion by itself could just be an input
    node.nodeType = GraphNodeType.DERIVED;
  }

  const color = getGraphNodeTypeColor(node.nodeType);
  const darkColor = hexToHSL(color, 80);

  return (
    <AttributeContainer style={{ backgroundColor: color }}>
      <Tooltip
        title={node.type}
        placement="right"
      >
        <div
          data-icon
          style={{ backgroundColor: darkColor }}
        >
          <AttributeIcon type={node.type} />
        </div>
      </Tooltip>
      <Typography>{capitalize(node.entity)}</Typography>
      {node.id === "pending" ? null : (
        <Flex data-actions>
          <FloatingVerticalDivider />
          <IconButton onClick={() => node && editAttributeModal(node.id)}>
            <ExternallinkIcon />
          </IconButton>
        </Flex>
      )}
    </AttributeContainer>
  );
};

const TriggerInfo = ({ trigger }: { trigger: Trigger }) => {
  const graph = useParsedGraph();
  switch (trigger.type) {
    case "task": {
      const node = graph.node(trigger.outcome);
      return (
        <Typography variant="caption">
          {trigger.title} ➝ {node?.description}
        </Typography>
      );
    }
    case "enrichment":
      return <Typography variant="caption">{trigger.title}</Typography>;
    case "comms":
      return (
        <Typography variant="caption">
          {trigger.title} ({trigger.method})
        </Typography>
      );
    default:
      // @ts-ignore catch in case we add a type
      return <Typography variant="caption">UNSUPPORTED {trigger.type}</Typography>;
  }
};

const TypeToLabel = {
  task: "Tasks",
  enrichment: "Integrations",
  comms: "Communications",
};

const Triggers = ({ node }: { node: GraphNodeWithType }) => {
  if (!node.triggers) return null;

  // group triggers by type
  const groupedTriggers = node.triggers.reduce(
    (acc, trigger) => {
      if (!acc[trigger.type]) {
        acc[trigger.type] = [];
      }
      acc[trigger.type].push(trigger);
      return acc;
    },
    {} as Record<string, Trigger[]>,
  );

  return (
    <Stack gridGap="0.5rem">
      {Object.entries(groupedTriggers).map(([type, triggers]) => (
        <Stack
          key={type}
          gridGap="0.25rem"
        >
          <Typography
            style={{ fontSize: "0.625rem" }}
            variant="h6"
          >
            {TypeToLabel[type]}
          </Typography>
          <Stack>
            {triggers.map((trigger, i) => (
              <TriggerInfo
                key={`${type}-${i}`}
                trigger={trigger}
              />
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

type AttributeInfoProps = {
  expression: AttributeExpression;
  parent: string;
};

type TabValue = {
  value: string;
  label: string;
  icon: JSX.Element;
  content: JSX.Element;
};

export const AttributeInfo = ({ expression, parent }: AttributeInfoProps) => {
  const { attributeId } = expression;
  const graph = useParsedGraph();

  const node = {
    ...graph.node(attributeId),
    nodeType: getNodeType(graph, attributeId),
  };

  // render special attribute info
  if (!node || node.nodeType === null) {
    return (
      <Attribute
        node={null}
        parent={parent}
      />
    );
  }

  const tabsRaw: Array<TabValue | null> = [
    expression.alias
      ? {
          value: "alias",
          label: "Alias",
          icon: <GlossaryIcon />,
          content: (
            <AliasInfo
              expression={expression as BaseExpression as AliasExpression}
              style={{ padding: 0 }}
            />
          ),
        }
      : null,
    node.definedIn || node.usedIn
      ? {
          value: "docs",
          label: "Usage",
          icon: <DocumentsIcon />,
          content: <AttributeUsage node={node} />,
        }
      : null,
    node.definedIn
      ? {
          value: "source",
          label: "Sources",
          icon: <FileTextIcon />,
          content: <SourceContent node={node} />,
        }
      : null,
    {
      value: "rules",
      label: "Rules",
      icon: <CompareIcon />,
      content: <RulesContent node={node} />,
    },
    node.tags && node.tags.length > 0
      ? {
          value: "tags",
          label: "Tags",
          icon: <LabelIcon />,
          content: (
            <Flex style={{ gap: "0.5rem", flexWrap: "wrap" }}>
              {node.tags.map((t) => (
                <Label
                  key={t}
                  color="purpleSlate"
                >
                  {t}
                </Label>
              ))}
            </Flex>
          ),
        }
      : null,
    node.explanation
      ? {
          value: "explanation",
          label: "Explanation",
          icon: <TooltipIcon />,
          content: <Typography>{node.explanation}</Typography>,
        }
      : null,
    node.enum
      ? {
          value: "enum",
          label: "Enum",
          icon: <BooleanIcon />,
          content: <Typography>{node.enum}</Typography>,
        }
      : null,
    node.publicId
      ? {
          value: "pubicName",
          label: "Public Name",
          icon: <SourcecodeIcon />,
          content: <Typography>{node.publicId}</Typography>,
        }
      : null,
    node.triggers && node.triggers.length > 0
      ? {
          value: "triggers",
          label: "Triggers",
          icon: <ExternallinkIcon />,
          content: <Triggers node={node} />,
        }
      : null,
    {
      value: "guid",
      label: "GUID",
      icon: <GuidIcon />,
      content: <AttributeIDField id={node.id} />,
    },
  ];

  const tabs = tabsRaw.reduce<TabValue[]>((a, it) => (it === null ? a : a.concat(it)), []);

  return (
    <>
      <TabRoot defaultValue={tabs[0].value}>
        {tabs.map((tab) => (
          <TabContent
            key={`content-${tab.value}`}
            value={tab.value}
          >
            {tab.content}
          </TabContent>
        ))}
        <TabToolbar>
          {tabs.map((tab) => (
            <TabsTrigger
              asChild
              key={`tab-${tab.value}`}
              value={tab.value}
            >
              <Tooltip
                title={tab.label}
                placement="bottom"
              >
                <IconButton>{tab.icon}</IconButton>
              </Tooltip>
            </TabsTrigger>
          ))}
        </TabToolbar>
      </TabRoot>
      <Attribute
        node={node}
        parent={parent}
      />
    </>
  );
};
