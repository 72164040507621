import React, { PropsWithChildren } from 'react';
import { findByIdInOtherData, type ContactOtherDataNodeDir } from './types'

export type ContactDataTreeCtxValue = {
  /**
   * separate from the value because this will be calculated in\
   * one of the parent components and passed down
   */
  tree: ContactOtherDataNodeDir;
  treeRef: { current: ContactDataTreeCtxValue['tree'] }
  expanded: string[];
  setExpanded: (cb: (prev: ContactDataTreeCtxValue['expanded']) => typeof prev) => unknown;
  expandedRef: { current: ContactDataTreeCtxValue['expanded'] }
  onSelectField: (field: string) => void;
}

export const defaultExpanded: ContactDataTreeCtxValue['expanded'] = [];
export const defaultTree: ContactDataTreeCtxValue['tree'] = {
  id: '',
  label: '',
  children: [],
  type: 'dir',
  path: '',
  pathArr: [],
};

export const defaultContactDataTreeCtxValue: ContactDataTreeCtxValue = {
  tree: defaultTree,
  treeRef: { current: defaultTree },
  expanded: defaultExpanded,
  setExpanded: () => console.warn('SZHya3A2bu | Default set expanded, should be overriden'),
  expandedRef: { current: defaultExpanded },
  onSelectField: () => console.warn('SZHya3A2bu | Default onSelectField, should be overriden'),
};

export const ContactDataTreeCtx = React.createContext(defaultContactDataTreeCtxValue);
export const useContactDataTree = () => React.useContext(ContactDataTreeCtx);

export const useContactDataTreeNode = (id: string) => {
  const { tree } = useContactDataTree();

  return React.useMemo(() => findByIdInOtherData({ node: tree, id }), [tree, id]);
}

export const useToggleExpanded = (): (id: string) => unknown => {
  const { setExpanded } = useContactDataTree();

  return React.useCallback(
    id => setExpanded(prev => {
      if (prev.indexOf(id) === -1) return prev.concat(id);

      return prev.filter(it => it !== id);
    }),
    [setExpanded]
  );
};

export type ContactDataTreeProps = {
  tree: ContactDataTreeCtxValue['tree'];
  onSelectField: ContactDataTreeCtxValue['onSelectField'];
} & PropsWithChildren;

export const ContactDataTreeProvider: React.FC<ContactDataTreeProps> = ({ tree, onSelectField, children }) => {
  const treeRef = React.useRef(tree);
  if (treeRef.current !== tree) treeRef.current = tree;

  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const expandedRef = React.useRef(expanded);
  if (expandedRef.current !== expanded) expandedRef.current = expanded;

  const ctxProviderValue = React.useMemo<ContactDataTreeCtxValue>(
    () => ({
      tree,
      treeRef,
      expanded,
      setExpanded,
      expandedRef,
      onSelectField
    }),
    [
      tree,
      treeRef,
      expanded,
      setExpanded,
      expandedRef,
      onSelectField
    ]
  );

  return (
    <ContactDataTreeCtx.Provider value={ctxProviderValue}>
      {children}
    </ContactDataTreeCtx.Provider>
  );
};
