import produce from 'immer';
import { v4 as uuid } from 'uuid';
import type { ContactOtherDataNodeDir, ContactOtherDataNodeLeaf } from './core';
import { findByPathInOtherData } from './findByPath';


//# region

function createNestingUsingSegments({ segments, mutNode, indx = 0 }: {
  mutNode: ContactOtherDataNodeDir,
  segments: string[];
  indx?: number;
}): void {
  const first = segments[indx];
  if(first === undefined) return;

  const pathArr = segments.slice(0, indx + 1);
  const path = pathArr.join('/');
  const maybeExisitngNode = findByPathInOtherData({ node: mutNode, path });
  const nodeToRecurseOn: ContactOtherDataNodeDir = maybeExisitngNode || {
    id: uuid(),
    label: first,
    type: 'dir',
    pathArr,
    path,
    children: [],
  };
  if(maybeExisitngNode === null) mutNode.children.push(nodeToRecurseOn);

  createNestingUsingSegments({
    mutNode: nodeToRecurseOn,
    segments,
    indx: indx + 1
  });
}

//# endregion

export type BootstrapDirsInOtherDataArg = {
  node: ContactOtherDataNodeDir;
  paths: string[];
  /**
   * when we work with contact entries, there are two common variants:\
   * we either process contact related to global entity, or contact that\
   * is related to some entity instance. In the first case we don't \
   * really need to do anything else and for that baseNesting is 0, but \
   * for instance based contact we need to remove some levels of nesting\
   * near the root as those don't make sense. For example if we process\
   * "people/1", we can remove first 2 levels of nesting in our "other \
   * data tree" because those would correspond to dir-s "people" and "1"\
   * and we won't have any important attributes on those.\
   * So
   * - for "global" baseNesting = 0
   * - for "people/2" baseNesting = 2
   * - for "people/3/hats/4" baseNesting = 4
   */
  baseNesting: number;
}
export type BootstrapDirsInOtherDataRtrn = ContactOtherDataNodeDir;

/**
 * we generally expect to map rule graph nodes that belong to a \
 * particular contact into "other data tree". But one of the typical\
 * scenarios is that we have nested attributes (i.e. that belong \
 * to entity instances). And it is also possible to have deep nesting\
 * (2, 3 or more levels of depth, e.g. \
 * employees/1/timesheets/1/workdays/2024-10-03/work_periods/<uuid>).\
 * To properly prepare our tree for insertion of attributes in deeply\
 * nested nodes we need to frist create empty dir nodes with full \
 * hierarchy that mirrors one presented in rule graph
 */
export const bootstrapDirsInOtherData = (arg: BootstrapDirsInOtherDataArg): BootstrapDirsInOtherDataRtrn => {
  const { node, paths, baseNesting } = arg;

  const tree = produce(node, draft => {
    paths.forEach(path => {
      if(path === 'global') return;

      const segments = path.split('/').filter(Boolean);

      createNestingUsingSegments({ mutNode: draft, segments });
    });
  });

  const withoutBaseNesting = (function removeBaseNesting(
    treeInner: ContactOtherDataNodeDir | ContactOtherDataNodeLeaf,
    baseNesting: number,
  ): typeof treeInner {
    if(treeInner.type === 'leaf' || baseNesting <= 0) return treeInner;
    const firstChild = treeInner.children[0];

    if(firstChild === undefined) return treeInner;

    return removeBaseNesting(firstChild, baseNesting - 1)
  })(tree, baseNesting);

  return withoutBaseNesting.type === 'leaf' ? tree : withoutBaseNesting;
}
