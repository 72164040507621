/* eslint-disable no-param-reassign */
// Note slice in redux toolkit uses immer so we mutate state
import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

/**
 * LEGACY DOCUMENT REDUX
 */

const initialState = {
  document: null,
  contents: [],
  errors: [],
  validations: [],
  lastSaved: null,
  library: {
    collapsed: false,
    // default width of 330px
    width: 330,
    // TODO move library expanded code into this
    expanded: [],
  },
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocument: (state, action) => {
      state.document = action.payload;
    },
    setContents: (state, action) => {
      state.contents = action.payload;
    },
    toggleCollapsed: state => {
      state.library.collapsed = !state.library.collapsed;
    },
    setWidth: (state, action) => {
      state.library.width = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setValidations: (state, action) => {
      state.validations = action.payload;
    },
    setLastSaved: (state, action) => {
      state.lastSaved = action.payload;
    },
  },
});

export const {
  setDocument,
  setContents,
  toggleCollapsed,
  setWidth,
  setErrors,
  setValidations,
  setLastSaved,
} = documentsSlice.actions;

export const useSelectedDocument = () => useSelector(state => state.documents.document);

export default documentsSlice.reducer;
