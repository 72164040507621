import { moment } from "@common/util";

// TODO move this to packages/common?

export type PrimitiveValue = boolean | undefined | null | number | string;
export type IncrementValue = { increment: "months" | "years" | "days"; offset?: number };

export type RangeValue = PrimitiveValue | IncrementValue;

export type Range = {
  v: RangeValue;
  r: {
    start: string;
    end: string;
  }
};

export type Temporal = {
  ranges: Range[];
  latest?: PrimitiveValue;
};

export type TemporalValue = {
  temporal: Temporal;
};

export type IsTemporal = TemporalValue | Temporal;

export const isTemporal = (arg: any): arg is IsTemporal => (
  typeof arg === 'object' &&
  arg !== null &&
  ('temporal' in arg || 'ranges' in arg)
);

export const isIncrement = (arg: any): arg is IncrementValue => (
  typeof arg === 'object' &&
  arg !== null &&
  'increment' in arg
);

// export const isTemporal = (arg: any): arg is Temporal => (
//   typeof arg === 'object' &&
//   arg !== null &&
//   'ranges' in arg
// );

// export const isTemporalValue = (arg: any): arg is TemporalValue => (
//   typeof arg === 'object' &&
//   arg !== null &&
//   'temporal' in arg
// );

export const getValueAtDate = (temporalValue: TemporalValue | Temporal | PrimitiveValue | undefined, date: any): RangeValue => {
  let temporal: Temporal;
  if (temporalValue === undefined || !isTemporal(temporalValue)) {
    // it doesn't exist, or is a value, just return it
    return temporalValue as RangeValue;
  }
  // support being passed either value
  if ((temporalValue as TemporalValue)?.temporal) {
    temporal = (temporalValue as TemporalValue).temporal;
  } else {
    temporal = temporalValue as Temporal;
  }
  if (!temporal || !date || !temporal.ranges) return null;
  let value: RangeValue = null;
  let cmpdate = moment(date);
  for (let i = 0; i < temporal.ranges.length; i++) {
    const r = temporal.ranges[i];
    if (cmpdate <= moment(r.r.end) && cmpdate >= moment(r.r.start)) {
      value = r.v;
      break;
    }
  }
  return value;
};