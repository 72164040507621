import styled from "styled-components";
import { Card, CardContent, CardHeader, IconButton, Tooltip, Typography, useTheme } from "@material-ui/core";
import { getIndexForNode, getIndexForNodeTooltip, scrollable } from "@common";
import { filter, get } from "lodash";
import AttributeContext from "@pages/models/release/AttributeContext"; // TOOD: should move
import { CloseIcon } from "@icons";
import TabView from "@components/tabs/TabView";
import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import NodeInputs from "./NodeInputs";
import { fileRefsWrapClsnm } from "./NodeValue";
import AttributeExplanation from "./AttributeExplanation";
import { Flex, Label, Stack } from "@components/meta";
import { useTranslation } from "react-i18next";
import { AttributeUsage } from "@pages/models/release/DataModel/route/Attributes/AttributeUsage";
import { useNotification } from "@common/notifications";
import { AttributeValue } from "@common/graph/AttributeValue";

const AttributeDetail = styled(Card)`
  height: 100%;
  overflow: overlay;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: overlay;
  gap: 1.75rem;
  padding: 1rem 0;
`;

export interface AttributeInfoProps {
  showInputValues?: boolean;
  onClose?: () => void,
  showTitle?: boolean;
  showClose?: boolean;
}

export const displayNodePath = (node, graph) => {
  const theme = useTheme();

  if (!node.path) return;
  let elements = node.path.split('/');
  elements.pop(); // remove the last part (which is the id)
  if (elements.length < 2) {
    // The path is of this entity only, it's not actually contained by anything
    return;
  }
  elements.pop();
  elements.pop();

  const displayEl = (el, index) => {
    if (index % 2) {
      // Check if there is an identifier
      let info: any = getIndexForNode({
        entity: elements[index - 1],
        index: el,
        parent_path: elements.length > 2 ? elements.slice(0, index - 2).join('/') : undefined
      }, graph);
      if (info && info.hint) return (
        <Tooltip title={getIndexForNodeTooltip(info)}>
          <span>{info.value}</span>
        </Tooltip>
      )
      else return el;
    } else return el;
  }
  if (elements.length < 2) return; // It's an entity/index path. It's not contained by anything so return nothing
  return (
    <Flex gridGap="0.5rem">
      <Typography variant="caption" style={{ fontWeight: 700 }}>Contained by</Typography>
      {elements.map((el, index) => (
        <>
          <Label color={index % 2 === 0 ? (theme as any).labels.public : 'lightgray'}>{displayEl(el, index)}</Label>
          {index !== (elements.length - 1) ? '/' : ''}
        </>
      ))}
    </Flex>
  );
}

const Index = ({ value, hint, identifier }) => {
  // React components don't return undefined
  if (!value) return null;
  if (hint) {
    return (
      <Tooltip title={getIndexForNodeTooltip({ hint, identifier })} style={{ display: 'inline' }}>
        <div>
          {' '}&#40;<Label color="lightGray">{value}</Label>&#41;
        </div>
      </Tooltip>
    );
  } else {
    return (
      <>
        {' '}&#40;<Label color="lightGray">{value}</Label>&#41;
      </>
    )
  }
}

const DebugContentsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .${fileRefsWrapClsnm} {
    margin: 0.25rem 0;
  }
`;

const AttributeInfo = (props: AttributeInfoProps) => {
  const { showInputValues, onClose, showTitle, showClose } = props;
  const { selectedNode, setSelectedNodeId, debug, graph } = useGraphVisualisation();
  const notify = useNotification();
  const theme = useTheme();
  const { t } = useTranslation();
  if (!selectedNode) return null;

  const onDocumentClick = () => {
    onClose?.();
  };

  const resolvedShowInputValues = showInputValues ?? true;
  const resolvedShowTitle = showTitle ?? true;
  const resolvedShowClose = showClose ?? true;

  const indexInfo: any = getIndexForNode(selectedNode, graph) || {};
  return (
    <AttributeDetail className={scrollable}>
      {resolvedShowTitle && (<CardHeader
        title={`${t('attribute')}: '${get(selectedNode, "description")}'`}
        action={resolvedShowClose && (
          <IconButton
            onClick={() => {
              setSelectedNodeId(null);
              if (onClose) onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

      />)}
      <CardContent>
        <TabView key={selectedNode.id} content={[
          {
            label: t('explanation.rules'),
            component: () => {
              return (
                <Container className={scrollable}>
                  <Stack gridGap={"0.5rem"}>
                    {/* reference has been deprecated, but might return */}
                    {debug && (
                      <DebugContentsWrap>
                        <Typography variant="caption" style={{ fontWeight: 700 }}>{t('explanation.value')}</Typography>
                        <AttributeValue node={selectedNode} />
                      </DebugContentsWrap>
                    )}
                    {debug && selectedNode.justification && (
                      <div>
                        <Typography variant="caption" style={{ fontWeight: 700 }}>{t('explanation.justification')}</Typography>
                        {selectedNode.justification}
                      </div>
                    )}
                    <Flex gridGap="0.5rem">
                      <Typography variant="caption" style={{ fontWeight: 700 }}>{t('entity')}</Typography>
                      <Label color={(theme as any).labels.public}>{selectedNode.entity}</Label>
                      <Index {...indexInfo} />
                    </Flex>
                    {selectedNode.path ? displayNodePath(selectedNode, graph) : null}
                  </Stack>

                  <AttributeUsage node={selectedNode} onClick={onDocumentClick} />

                  <div>
                    <Typography variant="h5">{t('explanation.how_calculated_title')}</Typography>
                    <AttributeContext.Provider value={(node) => {
                      console.log('click', node)
                      if (debug && node.hidden) {
                        // See how many instances of this
                        let others = filter(graph._nodes, (n) => {
                          if (n.id === node.id && !n.hidden) return true;
                          else return false;
                        });
                        if (others.length === 1) setSelectedNodeId(others[0].path)
                        else {
                          notify("Can't determine which instance of this attribute to navigate to");
                        }
                      } else setSelectedNodeId(node.path || node.id);
                    }}>
                      <AttributeExplanation attr={selectedNode} />
                    </AttributeContext.Provider>
                  </div>
                  {debug && resolvedShowInputValues && (
                    <div>
                      <Typography variant="h5">{t('explanation.input_values')}</Typography>
                      <NodeInputs node={selectedNode} />
                    </div>
                  )}
                </Container>
              );
            },
          },
          {
            label: t('explanation.explanation'),
            disabled: !selectedNode.explanation,
            component: () => {
              return (
                <Container className={scrollable}>
                  <Typography variant="h4">{t('explanation.explanation')}</Typography>
                  {selectedNode.explanation}
                </Container>
              );
            },
          },
        ]} />
      </CardContent>
    </AttributeDetail>
  );
};


export const Explanation = ({ attribute, setCurrentAttr }) => {
  const { t } = useTranslation();
  if (!attribute) {
    return <span>{t('errors.error_reading_attribute')}</span>;
  }

  return (
    <>
      {attribute.reference ? (
        <Typography>
          {t('explanation.reference')}:
          {" "}
          {get(attribute, "reference.title")}
          {" "}
          (
          {get(attribute, "reference.document")}
          :
          {get(attribute, "reference.line")}
          )
        </Typography>
      ) : (
        get(attribute, "definedIn") && (
          <Typography>
            {t('defined_in')}:
            {" "}
            {get(attribute, "definedIn")}
          </Typography>
        )
      )}
      <Typography variant="h5">{t('explanation.explanation')}</Typography>
      <AttributeContext.Provider value={setCurrentAttr}>
        <AttributeExplanation attr={attribute} />
      </AttributeContext.Provider>
      <Typography variant="h5">{t('used_in')}</Typography>
      <ul>
        {get(attribute, "usedIn", []).map((used, index) => (
          <li key={index}>{used}</li>
        ))}
      </ul>
    </>
  );
};

export default AttributeInfo;
