import { useSelector } from "react-redux";
import { useFormik, FormikConfig } from "formik";
import { get, pick } from "lodash";
import { crudCreate, crudUpdate, useAsyncDispatch } from "@imminently/imminently_platform";
import { Resource, ResourceService } from "@imminently/immi-query";
import { UseMutationOptions } from "react-query";

// a function that recursively iterates over an object and removes any empty values
const removeEmpty = (obj: any, allowed: string[] = []): any => {
  const result: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const isAllowed = allowed.includes(key);
      if (typeof obj[key] === 'boolean' || (typeof obj[key] === 'string' && (obj[key].length > 0 || isAllowed)) || typeof obj[key] === 'number') {
        result[key] = obj[key];
      } else if (Array.isArray(obj[key])) {
        const res = obj[key].map(item => removeEmpty(item));
        if (res.length > 0 || isAllowed) {
          result[key] = res;
        }
      } else if (typeof obj[key] === 'object') {
        const res = removeEmpty(obj[key]);
        if (Object.keys(res).length > 0 || isAllowed) {
          result[key] = res;
        }
      }
    }
  }

  return result;
};

export type CrudFormOptions = {
  resource: string;
  id: string | undefined;
  meta?: ((values: any) => any) | any;
  formikValues: Omit<FormikConfig<any>, "onSubmit">;
  filter?: string[];
  preSubmit?: (values: any) => any;
  // if provided a string array, allow those keys to empty
  allowEmpty?: boolean | string[];
};

export const useCrudForm = ({ resource, id = undefined, meta = {}, formikValues, allowEmpty = true, filter = undefined, preSubmit = undefined }: CrudFormOptions) => {
  const dispatch = useAsyncDispatch();
  const initial = useSelector((state) => get(state, `resources.${resource}.data.${id}`, {}));
  const { initialValues, ...config } = formikValues;
  const onSubmit = (values) => {
    let data = preSubmit ? preSubmit(values) : values;
    data = filter ? pick(data, filter) : data;
    const isFilter = Array.isArray(allowEmpty);
    if (isFilter || !allowEmpty) {
      data = removeEmpty(data, isFilter ? allowEmpty : undefined);
    }
    const params = {
      refresh: true,
      hideModal: true,
      ...(typeof meta === "function" ? meta(values) : meta),
    };
    if (id === undefined) {
      // update
      return dispatch(crudCreate(resource, data, params));
    } else {
      // create
      return dispatch(crudUpdate(resource, id, data, params));
    }
  };
  // unpack config at end to allow override of onSubmit
  return useFormik({ initialValues: { ...initialValues, ...initial }, onSubmit, ...config });
};

export type ServiceFormOptions<T extends Resource> = Omit<CrudFormOptions, "resource" | "id" | "meta"> & {
  service: ResourceService<T>;
  resource?: T;
  options?: Omit<UseMutationOptions, "mutationFn">;
};

export const useServiceForm = <T extends Resource>({ service, resource, options = {}, formikValues, allowEmpty = true, filter = undefined, preSubmit = undefined }: ServiceFormOptions<T>) => {
  const query = service.useMutateResource(resource, options);
  const { initialValues, ...config } = formikValues;
  const onSubmit = (values) => {
    let data = preSubmit ? preSubmit(values) : values;
    data = filter ? pick(data, filter) : data;
    const isFilter = Array.isArray(allowEmpty);
    if (isFilter || !allowEmpty) {
      data = removeEmpty(data, isFilter ? allowEmpty : undefined);
    }
    return query.action(data);
  };
  // unpack config at end to allow override of onSubmit
  const res = useFormik({ initialValues, onSubmit, ...config, });
  // include internal query so we can access it
  return { ...res, query };
};
