import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
// import IconButton from '@material-ui/core/IconButton';
import { Block, BlockWithTopCtxMenu, blockClss } from './Block';
import { Table, TableProps } from './Table';
import { ContactName } from './ContactName';
import { scrollableMixin } from '@common';
import { ContactDataTree, ContactDataTreeProps } from './OtherDataTree';
import { useModal } from '@modals/context';
import { Dialog } from '@material-ui/core';
import { TemporalValueModal } from '@components/TemporalValue/TemporalValueModal';
import { ContactOtherDataNodeLeaf, findByPredicateInOtherData } from './types';


//# region OtherCases

const OtherCases = styled(Block)`
  .${blockClss.topRow} {
    padding: 0.75rem 1.25rem;
  }
`;

const OtherCasesTopRowWrapEnd = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

// const InstantMixIcon = () => (
//   <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M2.31071 13.4584V7.57696H0.644043V6.32696H5.22738V7.57696H3.56071V13.4584H2.31071ZM2.31071 4.33987V0.541748H3.56071V4.33987H2.31071ZM5.70821 4.33987V3.08987H7.37488V0.541748H8.62488V3.08987H10.2915V4.33987H5.70821ZM7.37488 13.4584V6.32696H8.62488V13.4584H7.37488ZM12.439 13.4584V10.9103H10.7724V9.66029H15.3557V10.9103H13.689V13.4584H12.439ZM12.439 7.67321V0.541748H13.689V7.67321H12.439Z" fill="#1C1B1F" />
//   </svg>
// );
// const OtherCasesTopRowBtn = styled(IconButton)`
//   border: 1px solid #E0DBDB;
//   border-radius: 0.5rem;
//   width: 2rem;
//   height: 2rem;
//   box-sizing: border-box;
// `;

type OtherCasesTopRowProps = {
  perPage: number;
  total: number;
}
const OtherCasesTopRow: React.FC<OtherCasesTopRowProps> = p => {
  const { perPage, total } = p;

  return (
    <>
      <Typography variant='h6'>Other cases</Typography>

      <OtherCasesTopRowWrapEnd>
        <Typography variant='caption' style={{ fontStyle: 'italic' }}>
          Showing {perPage} of {total}
        </Typography>

        {/* <OtherCasesTopRowBtn>
          <InstantMixIcon />
        </OtherCasesTopRowBtn> */}
      </OtherCasesTopRowWrapEnd>
    </>
  )
}

//# endregion


const StyledBlockWithTopCtxMenu = styled(BlockWithTopCtxMenu)`
  .${blockClss.content} {
    gap: 1.5rem;
  }
`;

const StyledTopTable = styled(Table)`
  padding: 0.25rem 1rem;
`;

const OtherData = styled(BlockWithTopCtxMenu)`
  background-color: #F9F9F980;
  border: 1px solid #E5E5E5;
  border-radius: 0.25rem;

  .${blockClss.topRow} {
    padding: 1rem 1.25rem;
  }

  .${blockClss.content} {
    max-height: calc(21.75rem - 2rem);
    padding: 0;
    margin: 1rem;

    ${scrollableMixin};
    &::-webkit-scrollbar {
      width: 0.25rem;
      height: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      border: none;
    }
  }
`;
const OtherDataTable = styled(Table)`
  tr td {
    padding-bottom: 0.125rem;
    border-bottom: 1px solid #F3F3F3;
  }
`;

const OtherDataLoadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;


export type CaseViewContactProps = {
  name: string;
  id: string;
  data: TableProps['items'];
  otherDataLoading?: boolean;
  otherCases: {
    perPage: number;
    total: number;
  };
  tree: ContactDataTreeProps['tree'];
};

export const CaseViewContact: React.FC<CaseViewContactProps> = p => {
  const {
    id,
    name,
    data,
    otherDataLoading,
    otherCases: {
      perPage,
      total,
    },
    tree,
  } = p;
  const { pushModal, popModal } = useModal();

  const openModal = (field: string) => {
    const data = findByPredicateInOtherData({ node: tree, predicate: (n) => n.id === field }) as ContactOtherDataNodeLeaf;
    const label = data.label;
    return pushModal({
      component: () => (
        <Dialog open onClose={popModal}>
          <TemporalValueModal
            close={popModal}
            node={{
              title: label,
              _contents: {
                id: '',
                title: label,
                status: 'Satisfied',
                _contents: {},
                value: data.allValues ?? data.value,
              }
            }}
            value={data.value}
          />
        </Dialog>
      )
    });
  };

  return (
    <StyledBlockWithTopCtxMenu
      startNode={<ContactName name={name} id={id} />}
      ctxMenuItems={[]}
      hideCtxMenu
    >
      {/* {data.length > 0 ? <StyledTopTable items={ data } /> : null} */}

      <OtherData
        ctxMenuItems={[]}
        hideCtxMenu
        startNode={<Typography variant='h6'>Other data</Typography>}
      >
        {
          Boolean(otherDataLoading) ? (
            <OtherDataLoadingWrap>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </OtherDataLoadingWrap>
          ) : <ContactDataTree tree={tree} onSelectField={openModal} />
        }
      </OtherData>

      {/* <OtherCases
        topRowNode={<OtherCasesTopRow
          perPage={perPage}
          total={total}
        />}
      >
        other cases block content
      </OtherCases> */}
    </StyledBlockWithTopCtxMenu>
  );
}
