/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import GraphTypeSelector from "./components/GraphTypeSelector";
import NodeLimitSelector from "./components/NodeLimitSelector";
import SearchRuleGraphNodes from "../search/SearchRuleGraphNodes";
import DatePicker from 'react-datepicker';
import { IconButton, Popover, Tooltip } from "@material-ui/core";
import { useGraphStatisticsModal } from "../GraphStatistics/GraphStatistics";
import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import { forEach, last } from "lodash";
import { getNodeValue } from "@common";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { CalendarIcon, ClearIcon } from "@icons";
import DepthSelector from "./components/DepthSelector";
import GoalMenu from "../../../../pages/models/release/GoalMenu";

import stl from "./GraphToolbar.module.scss";
import { Button } from "@components/buttons";
import styled from "styled-components";
import { TogglePaneButton } from "@common/SplitPane";
import { BarChartIcon } from "@radix-ui/react-icons";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const StyledPopover = withStyles(theme => ({
  root: {
    '& .MuiPopover-paper': {
    },
  },
}))(Popover);

const TemporalButton = styled(Button)`
  min-width: 12rem;
  justify-content: flex-start;
`;

const GraphStatsButton = () => {
  const { graph } = useGraphVisualisation();
  const openGraphStats = useGraphStatisticsModal();
  const handleClick = () => openGraphStats(graph);
  const { t } = useTranslation();
  return (
    <Tooltip title={t('debugger.stats_tooltip')}>
      <Button type="secondary" startIcon={<BarChartIcon />} onClick={handleClick}>
        {t('debugger.stats')}
      </Button>
    </Tooltip>
  );
};

const useGraphTemporals = () => {
  const { graph, temporalSlice, setTemporalSlice } = useGraphVisualisation();

  const resetSlice = () => {
    setTemporalSlice(null);
  }

  const setSlice = (date) => {
    // This is to get rid of the timezone - timezone suck! (more than TS)
    let newDate = (new Date(date.getTime() - date.getTimezoneOffset() * 60000))
    setTemporalSlice(newDate);
  }

  let hasTemporal = false;
  let minTemporal = new Date();
  let maxTemporal = new Date();

  // Check if the graph has any temporal values
  forEach(graph.nodes(), n => {
    let node = graph.node(n);
    let res = getNodeValue(node);
    if (res && res.temporal && res.temporal.ranges) {
      hasTemporal = true;
      if (res.temporal.ranges.length > 1) {
        let startRange = res.temporal.ranges[0];
        let endRange = last(res.temporal.ranges);
        // @ts-ignore - you don't know what you are talking about TS
        if (!startRange || !endRange || !startRange.r || !endRange.r) return; // keep processing - bad temporal
        let _minTemporal = new Date(startRange.r.end);
        // @ts-ignore
        let _maxTemporal = new Date(endRange.r.start);
        if (_minTemporal < minTemporal) minTemporal = _minTemporal;
        if (_maxTemporal > maxTemporal) maxTemporal = _maxTemporal;
      }
    }
  });

  return {
    hasTemporal,
    minTemporal,
    maxTemporal,
    resetSlice,
    setSlice,
    temporalSlice
  };
};

const TemporalSliceButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { temporalSlice, hasTemporal, minTemporal, maxTemporal, resetSlice, setSlice } = useGraphTemporals();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!hasTemporal) return null;

  const clearButton = temporalSlice ? <IconButton onClick={resetSlice}><ClearIcon /></IconButton> : null;
  const temporalButtonLabel = temporalSlice ? moment(temporalSlice).format('YYYY-MM-DD') : t('debugger.temporal_pick_date');
  return (
    <>
      <Tooltip title={t('debugger.temporal_slice_tooltip')}>
        <TemporalButton type="secondary" startIcon={<CalendarIcon />} endIcon={clearButton} onClick={handleClick}>
          {temporalButtonLabel}
        </TemporalButton>
      </Tooltip>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <DatePicker
          selected={temporalSlice || null}
          // startDate={startDate}
          // endDate={endDate}
          minDate={minTemporal}
          maxDate={maxTemporal}
          onChange={setSlice}
          inline
          showYearDropdown
          yearDropdownItemNumber={30}
          scrollableYearDropdown
          shouldCloseOnSelect
        // selectsRange
        />
      </StyledPopover>
    </>
  );
};

const FocusAndExpandControls = () => {
  const {
    debug,
    graph,
    showFocusAndExpandControls,
    setRestrictedViewRootPath,
    restrictedViewRootPath,
  } = useGraphVisualisation();
  const { t } = useTranslation();

  const show = (debug && !restrictedViewRootPath) ? false : showFocusAndExpandControls;
  if (!show) {
    if (debug) {
      // still show the NodeLimitSelector in debug
      return <NodeLimitSelector />
    }
    return null;
  }

  return (
    <div className={stl.container}>
      <div className={stl.goal_container}>
        <GoalMenu
          graph={graph}
          onGoalIdChange={setRestrictedViewRootPath}
          goalId={restrictedViewRootPath || undefined}
          allowEmpty={true}
          placeholder={t('debugger.focus_attribute')}
        />
      </div>
      {/* TODO it's a little bit confusing that this is disabled when something is not in focus,
       as you don't know what it's for, and then the NodeLimitSelector drops off...
       maybe this shouldn't be shown if there is no node in focus?
      */}
      <DepthSelector />
      {
        restrictedViewRootPath
          ? null
          : <NodeLimitSelector />
      }
    </div>
  )
};

const GraphToolbar = () => {
  const { debug } = useGraphVisualisation();
  const { pathname } = useLocation();
  const hideTogglePaneButton = pathname.includes('/monitor') || pathname === '/verify';

  return (
    <>
      <div className={stl.toolbar}>
        {debug && hideTogglePaneButton === false ? <TogglePaneButton style={{ borderRadius: "0.5rem" }} /> : null}
        <GraphTypeSelector />
        <GraphStatsButton />
        <SearchRuleGraphNodes />
        {debug ? <TemporalSliceButton /> : null}
        <FocusAndExpandControls />

      </div>

    </>
  );
};

export default (GraphToolbar);