import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { Flex, Stack } from "@components/meta";
import { ImplementationIcon } from "@icons";
import Typography from "@material-ui/core/Typography";
import type { RelationshipExpression } from "@packages/compiler/src/Parser";
import { capitalize } from "lodash";

const pascalToSpace = (str: string) => str.replace(/([A-Z])/g, " $1").trim();
const pascalToKebab = (str: string) => str.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();

export const RelationshipInfo = ({ expression }: { expression: RelationshipExpression }) => {
  const { relationshipId } = expression;
  const release = useFullReleaseStrict();
  const relationship = release.relationships.find((r) => r.id === relationshipId);
  if (!relationship) {
    return (
      <Stack padding="1rem">
        <Typography>Relationship (pending document save)</Typography>
      </Stack>
    );
  }

  const source =
    relationship.source === "global"
      ? relationship.source
      : release.relationships.find((r) => r.target === relationship.source)?.textSingular ?? "ERROR MISSING";
  const target =
    relationship.textSingular ??
    release.relationships.find((r) => r.target === relationship.target)?.textSingular ??
    "ERROR MISSING";

  return (
    <Stack
      gridGap="0.5rem"
      padding="0.5rem"
    >
      <Flex style={{ gap: "0.25rem" }}>
        <Typography>
          {source} ➝ {target}
        </Typography>
      </Flex>

      <Flex gridGap="0.25rem">
        <ImplementationIcon style={{ width: "1rem" }} />
        <Typography variant="caption">{capitalize(pascalToKebab(relationship.type))} relationship</Typography>
      </Flex>
    </Stack>
  );
};
