import { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import SearchAndSelect from "../SearchAndSelect";
import { isStrNotNullOrBlank, isStrNullOrBlank } from "../../util/UtilString";
import { escapeRegExp, isEqual, uniqWith } from "lodash";
import SearchAndSelectResultItem from "./SearchAndSelectResultItem";
import { trimDocName } from "../../../common/hooks/HooksDocuments";
import { SearchResult } from "./Search.types";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import { findReleaseDocument, normaliseText, normalizeText } from "@packages/commons";
import { useTranslation } from "react-i18next";
import { getNodeType, GraphNodeType } from "@common";
import { useFullRelease } from "@common/hooks_useFullRelease";

interface SearchDocumentsProps {
}

const useGraphNodes = () => {
  const release = useFullRelease();
  const graph = useParsedGraph();

  return useMemo(() => (release ? graph?.nodes() ?? [] : [])
    .map((id: any) => {
      const node = graph.node(id);
      // Don't include fk in the search. These are actually important, but confuse our rule authors. At some point I'll be asked to turn these back on :)
      if (node.fk) return;
      // first get path with no extension
      const fullDocPathNoExt: string = trimDocName(node.definedIn || "");
      const documentName = fullDocPathNoExt.substring(fullDocPathNoExt.lastIndexOf("/") + 1);
      const documentPath = fullDocPathNoExt.substring(0, fullDocPathNoExt.lastIndexOf("/"));

      const isInput = isStrNullOrBlank(node.definedIn || "");
      const document = isInput ? null : findReleaseDocument(release, (doc) => normaliseText(doc.name) === fullDocPathNoExt);

      const referencedBy = (node.usedIn || []).map((usedIn: any) => {
        // is just the path with the extension stripped
        // remove .docx or .xlsx from end of string using replace regex
        const usedInPathNoExt = trimDocName(usedIn);
        // doc.name is the full path without the extension
        const doc = findReleaseDocument(release, (doc) => normaliseText(doc.name) === usedInPathNoExt);
        return doc;
      }).filter(Boolean);

      return ({
        v: node.id, // TODO remove this in favour of just using id
        ...(node),
        documentName: documentName || "",
        documentPath: documentPath || "/",
        documentId: document?.reference || "",
        document,
        referencedBy,
        isInput,
        nodeType: getNodeType(graph, node.id) ?? GraphNodeType.INPUT, // default to input
      });
    }).filter(x => x), [graph, release]);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchDocuments = (props: SearchDocumentsProps) => {
  const ruleGraph = useParsedGraph();
  const [searchValue, setSearchValue] = useState<string>("");
  /** where [] is "no results" and null is "no search performed yet" */
  const [searchResults, setSearchResults] = useState<SearchResult[] | null>([]);
  const { t } = useTranslation();

  const graphNodes = useGraphNodes();

  // -- search

  const searchDocs_ = (srchStr: string) => {
    const searchRegExp = new RegExp(escapeRegExp(normalizeText(srchStr)), "i");
    const searchResultsArr = graphNodes
      .map<{ val: string; original: any }>(node => ({
        original: node,
        val: String(node.description || node.publicId || node.text || node.name || node.label)
      }))
      .filter(({ val, original }) => {
        return Boolean(val.match(searchRegExp)) || Boolean(original.id.match(searchRegExp));
      })
      .map<SearchResult>(it => ({
        index: 0,
        original: it.original,
        score: 1,
        string: it.val
      }));

    const resultsInputs = searchResultsArr.filter((result) => result.original.isInput);
    const resultsNonInputs = searchResultsArr.filter((result) => !result.original.isInput);
    const uniqueResultsNonInputs = uniqWith(resultsNonInputs, (a: any, b: any) =>
      a.original.documentId === b.original.documentId &&
      a.original.type === b.original.type &&
      a.original.entity === b.original.entity &&
      a.original.definedIn === b.original.definedIn &&
      isEqual(a.original.usedIn, b.original.usedIn) &&
      a.original.description === b.original.description
    );
    setSearchResults([
      ...resultsInputs,
      ...uniqueResultsNonInputs,
    ]);
  };

  const searchDocs = useCallback(
    debounce(searchDocs_, 500), [graphNodes]
  );

  const searchNodes = () => {
    if (isStrNotNullOrBlank(searchValue) && ruleGraph) {
      searchDocs(searchValue);
    }
  };

  useEffect(() => {
    if (isStrNotNullOrBlank(searchValue) && ruleGraph) {
      searchNodes();
    } else {
      setSearchResults(null);
    }
  }, [searchValue, ruleGraph]);

  // -- clear/reset

  const onClear = () => {
    setSearchValue("");
    setSearchResults(null);
  };

  // -- rendering

  return (
    <SearchAndSelect<SearchResult>
      // key={graphNodes?.[0]?.v ?? "no-graph"}
      appearance="default"
      placeholder={`${t('build.search_rules')}...`}
      value={searchValue}
      onChange={setSearchValue}
      onEnter={searchNodes}
      onClear={onClear}
      searchResults={searchResults}
      renderResultItem={(result, ref) =>
        <SearchAndSelectResultItem
          ref={ref}
          key={result.original.id}
          result={result}
          additionalInfo={{
            entityName: result.original.entity,
            type: result.original.type,
            matchingText: result.string,
            highlightStr: normalizeText(searchValue) || "",
          }}
          onDocumentOpen={onClear}
        />
      }
    />
  );
};

export default (SearchDocuments);
