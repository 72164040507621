import { createContext, useContext } from "react";
import { UseQueryResult } from "react-query";
import { mappingContactsService } from "services";

export type FieldMapping = {
  created: string;
  lastModified: string;
  disabled: boolean;
  type: string;
  workspaces: string[];
  projects: string[];
  releases: string[];
  mappedTo?: string;
  // workspaceNames: string[];
  // projectNames: string[];
  // releasesByEnv: {
  //   test: string[];
  //   prod: string[];
  // };
};

export type WorkspaceInfo = {
  name: string;
  projects: string[];
};

export type ProjectInfo = {
  name: string;
  workspace: string;
  releases: string[];
};

export type ReleaseInfo = {
  name: string;
  env: "test" | "prod";
  number: number;
  workspace: string;
  project: string;
};

export type ContactMappings = {
  entity: 'contacts';
  fields: Record<string, FieldMapping>;
  meta: {
    workspaceInfo: Record<string, WorkspaceInfo>;
    projectInfo: Record<string, ProjectInfo>;
    releaseInfo: Record<string, ReleaseInfo>;
  };
};

const useContactMappingsContext = () => {
  const { data, ...query } = mappingContactsService.useGetOne('contacts') as UseQueryResult<ContactMappings>;

  const getFieldMapping = (field: string) => {
    if (!data) return null;
    const mapping = data.fields[field];
    if (!mapping) return null;
    // extend mapping to include workspace, project, and release names
    const from = mapping.releases.map(r => {
      const release = data.meta.releaseInfo[r];
      return {
        release: { id: r, releaseNo: release.number, ...release },
        workspace: { id: release.workspace, ...data.meta.workspaceInfo[release.workspace] },
        project: { id: release.project, ...data.meta.projectInfo[release.project] },
      };
    });

    return { ...mapping, from };
  };

  return {
    mappings: data,
    query,
    getFieldMapping,
  };
};

type ContactMappingContextType = ReturnType<typeof useContactMappingsContext>;
const ContactMappingContext = createContext<ContactMappingContextType>(null as unknown as ContactMappingContextType);

export const useContactMappings = () => {
  const context = useContext(ContactMappingContext);
  if (!context) {
    throw new Error('useContactMappings must be used within ContactMappingProvider');
  }
  return context;
};

export const ContactMappingProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useContactMappingsContext();
  return (
    <ContactMappingContext.Provider value={value} >
      {children}
    </ContactMappingContext.Provider>
  );
};