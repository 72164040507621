import * as React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";
import { CheckIcon, ChevronrightIcon } from "@icons";
import cn from "clsx";
import { CircleIcon } from "@radix-ui/react-icons";

const DropdownMenu = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
const DropdownMenuGroup = DropdownMenuPrimitive.Group;
const DropdownMenuPortal = DropdownMenuPrimitive.Portal;
const DropdownMenuSub = DropdownMenuPrimitive.Sub;
const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

// "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent",
const SubTrigger = styled(DropdownMenuPrimitive.SubTrigger)`
  position: relative;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  outline: none;
  transition: color 0.2s, background-color 0.2s;

  &:focus, &[data-state="open"] {
    background-color: ${p => p.theme.palette.background.border};
  }

  .inset {
    padding-left: 2rem;
  }

  > svg {
    pointer-events: none;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }
`;

// "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
const SubContent = styled(DropdownMenuPrimitive.SubContent)`
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid ${p => p.theme.palette.background.border};
  background-color: ${p => p.theme.palette.background.default};
  padding: 0.25rem;
  font-size: 0.75rem;
  color: ${p => p.theme.palette.text.primary};
  transition: transform 0.2s, opacity 0.2s, filter 0.2s;

  &[data-state="open"] {
    transform: scale(1);
    opacity: 1;
    filter: none;
  }
  &[data-state="closed"] {
    transform: scale(0.95);
    opacity: 0;
    filter: blur(4px);
  }
  &[data-side="bottom"] {
    transform-origin: top;
    transform: translateY(0.25rem);
  }
  &[data-side="top"] {
    transform-origin: bottom;
    transform: translateY(-0.25rem);
  }
  &[data-side="left"] {
    transform-origin: right;
    transform: translateX(-0.25rem);
  }
  &[data-side="right"] {
    transform-origin: left;
    transform: translateX(0.25rem);
  }
`;

// "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
const Content = styled(DropdownMenuPrimitive.Content)`
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid ${p => p.theme.palette.background.border};
  background-color: ${p => p.theme.palette.background.default};
  padding: 0.25rem;
  font-size: 0.75rem;
  color: ${p => p.theme.palette.text.primary};
  transition: transform 0.2s, opacity 0.2s, filter 0.2s;

  &[data-state="open"] {
    transform: scale(1);
    opacity: 1;
    filter: none;
  }
  &[data-state="closed"] {
    transform: scale(0.95);
    opacity: 0;
    filter: blur(4px);
  }
  &[data-side="bottom"] {
    transform-origin: top;
    transform: translateY(0.25rem);
  }
  &[data-side="top"] {
    transform-origin: bottom;
    transform: translateY(-0.25rem);
  }
  &[data-side="left"] {
    transform-origin: right;
    transform: translateX(-0.25rem);
  }
  &[data-side="right"] {
    transform-origin: left;
    transform: translateX(0.25rem);
  }
`;

// "relative flex cursor-default select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
const Item = styled(DropdownMenuPrimitive.Item)`
  position: relative;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  outline: none;
  transition: color 0.2s, background-color 0.2s;

  &:focus {
    background-color: ${p => p.theme.palette.background.hover};
  }
  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
  > svg {
    pointer-events: none;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }
  &.inset {
    padding-left: 2rem;
  }
  &.error {
    color: ${p => p.theme.palette.error.main};

    &:hover, &:focus, &:focus-within {
      color: ${p => p.theme.palette.error.contrastText};
      background-color: ${p => p.theme.palette.error.main};
    }
  }
`;

// "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
const CheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem)`
  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem;
  font-size: 0.75rem;
  outline: none;
  transition: color 0.2s, background-color 0.2s;
  &:focus {
    background-color: #f9fafb;
    color: #3b82f6;
  }
  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
  > span {
    // absolute left-2 flex h-3.5 w-3.5 items-center justify-center
    position: absolute;
    left: 0.5rem;
    display: flex;
    width: 1rem;
    height: 1rem;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

// "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
const RadioItem = styled(DropdownMenuPrimitive.RadioItem)`
  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem;
  font-size: 0.75rem;
  outline: none;
  transition: color 0.2s, background-color 0.2s;
  &:focus {
    background-color: #f9fafb;
    color: #3b82f6;
  }
  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
  > span {
    // absolute left-2 flex h-3.5 w-3.5 items-center justify-center
    position: absolute;
    left: 0.5rem;
    display: flex;
    width: 1rem;
    height: 1rem;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 0.5rem;
    height: 0.5rem;
    fill: currentColor;
  }
`;

// px-2 py-1.5 text-sm font-semibold
const Label = styled(DropdownMenuPrimitive.Label)`
  padding: 0.375rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;

  .inset {
    padding-left: 2rem;
  }
`;

// "-mx-1 my-1 h-px bg-muted"
const Separator = styled(DropdownMenuPrimitive.Separator)`
  margin: 0.25rem -0.25rem;
  height: 1px;
  background-color: #e5e7eb;
`;

// ml-auto text-xs tracking-widest opacity-60
const Shortcut = styled.span`
  margin-left: auto;
  font-size: 0.625rem;
  letter-spacing: 0.025rem;
  opacity: 0.6;
`;

const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & { inset?: boolean }
>(({ className, inset, children, ...props }, ref) => (
  <SubTrigger
    ref={ref}
    className={cn(inset, className)}
    {...props}
  >
    {children}
    <ChevronrightIcon />
  </SubTrigger>
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <SubContent
      ref={ref}
      className={cn(className)}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(className)}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & { inset?: boolean; error?: boolean; }
>(({ className, inset, error, onClick, ...props }, ref) => (
  <Item
    ref={ref}
    className={cn({ inset, error }, className)}
    onClick={(e) => { e.stopPropagation(); onClick?.(e); }}
    {...props}
  />
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <CheckboxItem
    ref={ref}
    className={cn(className)}
    checked={checked}
    {...props}
  >
    <span>
      <DropdownMenuPrimitive.ItemIndicator>
        <CheckIcon />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </CheckboxItem>
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

const DropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <RadioItem
    ref={ref}
    className={cn(className)}
    {...props}
  >
    <span>
      <DropdownMenuPrimitive.ItemIndicator>
        <CircleIcon />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </RadioItem>
));
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean
  }
>(({ className, inset, ...props }, ref) => (
  <Label
    ref={ref}
    className={cn(inset, className)}
    {...props}
  />
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <Separator
    ref={ref}
    className={cn(className)}
    {...props}
  />
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const DropdownMenuShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <Shortcut
      className={cn(className)}
      {...props}
    />
  )
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
};