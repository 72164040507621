import styled from "styled-components";

const Container = styled.kbd`
  text-transform: capitalize;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  --color: ${props => props.theme.palette.text.primary};
  --bg-color: ${props => props.theme.palette.background.light};
  --border-color: ${props => props.theme.palette.background.border};

  &[data-color="dark"] {
    --color: ${props => props.theme.palette.background.default};
    --bg-color: ${props => props.theme.palette.background.dark};
    --border-color: ${props => props.theme.palette.background.darkBorder};
  }

  &[data-variant="contained"] {
    padding: 0 0.5rem;
    border-radius: 0.25rem;
    color: var(--color);
    background-color: var(--bg-color);
    &[data-color="light"] {
      box-shadow: inset 0 0 0 1px var(--border-color);
    }
  }

  &[data-variant="text"] {
    color: var(--color);
    background-color: unset;
    box-shadow: none;
  }

  &[data-variant="separate"] {
     > * {
      border-radius: 0.25rem;
      padding: 0 0.5rem;
      color: var(--color);
      background-color: var(--bg-color);
    }
    &[data-color="light"] {
      > * {
        box-shadow: inset 0 0 0 1px var(--border-color);
      }
    }
  }
`;

export const isMac = () => {
  if ("userAgentData" in navigator) {
    // @ts-ignore
    const platform = (navigator.userAgentData as any).platform;
    return platform.toLowerCase().indexOf("mac") !== -1 || platform === "iPhone";
  }
  if ("platform" in navigator) {
    return navigator.platform.toLowerCase().indexOf("mac") !== -1 || navigator.platform === "iPhone";
  }

  return false;
};

export const isMod = (e: React.MouseEvent) => {
  return isMac() ? e.metaKey : e.ctrlKey;
};

// constant, so pre-calculate
export const modKey = isMac() ? "⌘" : "ctrl";
export const altKey = isMac() ? "⌥" : "alt";
export const shiftKey = "⇧";

export type KbdProps = {
  className?: string;
  keys: string | string[];
  variant?: "contained" | "text" | "separate";
  color?: "light" | "dark";
  delimiter?: string;
  gap?: string;
  inline?: boolean;
};

export const Kbd = ({ keys, variant = "contained", color = "light", delimiter, gap = "0.25rem", inline = false, ...props }: KbdProps) => {
  const keyList = Array.isArray(keys) ? keys : [keys];

  // remap mod and alt to appropriate symbols
  let mappedKeys = keyList.map(k =>
      k.replace("mod", modKey)
      .replace("alt", altKey)
      .replace("shift", shiftKey)
  );

  if (delimiter) {
    // add a + between each key
    mappedKeys = mappedKeys.reduce((acc, k) => [...acc, k, delimiter], [] as string[]).slice(0, -1);
  }

  return (
    <Container {...props} style={{ gap, display: inline ? "inline-flex" : "flex" }} data-variant={variant} data-color={color}>

      {...mappedKeys.map(k => (<span key={k}>{k}</span>))}

    </Container>
  );
};
