import { Graph } from "graphlib";

/**
 * Eigenvector centrality is a measure of the influence a node has on a network.
 * If a node is pointed to by many nodes (which also have high eigenvector centrality)
 * then that node will have high eigenvector centrality.
 * 
 * https://en.wikipedia.org/wiki/Eigenvector_centrality
 */
export const eigenvectorCentrality = (graph, _opts = {}) => {
  let g = new Graph();
  graph.nodes().forEach(n => {
    let node = graph.node(n);
    if (node.index) return; // Ignore
    if (node.fk) return;
    g.setNode(n, {});
  });
  graph.edges().forEach(e => {
    if (g.hasNode(e.v) && g.hasNode(e.w)) {
      g.setEdge(e.v, e.w);
    }
  });
  let opts = {
    ..._opts,
    maxIter: 100,
    tolerance: 1e-6
  }
  let x = new Map();
  let zeroMap = new Map();
  let start = 1 / g._nodeCount;
  g.nodes().forEach(n => {
    x.set(n, start);
    zeroMap.set(n, 0);
  });
  let sum = 0;
  for (let v of x.values()) {
    sum += v;
  }
  sum = 1 / sum;

  for (let [k, v] of x) {
    x.set(k, v * sum);
  }

  let tolerance = g._nodeCount * opts.tolerance;
  //console.log('tol', tolerance, x);
  for (let i = 0; i < opts.maxIter; i++) {
    let xlast = x;
    x = new Map(zeroMap);

    // do the multiplication
    for (let [n, v] of x) {
      //console.log('proc', n)
      g.neighbors(n).forEach(neighbor => {
        //console.log('adding', xlast.get(n), 'to', x.get(neighbor), 'for', neighbor)
        x.set(neighbor, x.get(neighbor) + xlast.get(n));
      })
    }
    // Normalize the vector
    let sum = 0;
    for (let v of x.values()) {
      sum += Math.pow(v, 2);
    };
    sum = Math.sqrt(sum);
    sum = sum === 0 ? 1 : 1 / sum;

    let error = 0;
    //console.log('x', x, sum)

    for (let [n, v] of x) {
      v = v * sum;
      x.set(n, v);
      // Check error convergence
      error += Math.abs(v - xlast.get(n));
    }
    //console.log('convergence error', x, error)
    if (error < tolerance) return x;
  }
  console.log('failed to converge');
  return x;
};