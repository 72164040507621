import { User } from "oidc-client-ts";
import {
  Client,
  createImmiClient,
  createImmiProvider,
  createResourceService,
  CreateResourceServiceConfig,
  DataProvider,
  ImmiClientOptions,
  Resource,
} from "@imminently/immi-query";
import { config } from "./config";
import { QueryClient } from "react-query";
import authProvider from "./authProvider";
import { notify } from "@common/notifications";

export const getUser = (authority: string, clientId: string, storage = sessionStorage) => {
  const oidcStorage = storage.getItem(`oidc.user:${authority}:${clientId}`);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const getCurrentUser = () => {
  return getUser(config.AUTH_ENDPOINT, config.AUTH_CLIENT_ID);
};

export const azureAuthBearerHeader = async () => {
  try {
    const accessToken = sessionStorage.getItem("immi_access_token");
    if (accessToken && typeof authProvider.instance?.tokenExpired === "function" && typeof authProvider === "function") {
      if (authProvider.instance.tokenExpired(accessToken)) {
        await authProvider("silentAuthCheck");
      }
    }
  } catch (error: any) {
    console.error("Failed to do silent auth check", error);
  }

  const user = getUser(config.AUTH_ENDPOINT, config.AUTH_CLIENT_ID);
  const isB2C = config.AUTH_IS_B2C === "true";
  if (user) {
    return {
      Authorization: `Bearer ${isB2C ? user.id_token : user.access_token}`,
      "X-TENANCY": localStorage.getItem("decisively.tenant"),
    } as Record<string, string>;
  }

  return {};
};

export const global = (function () {
  const options: ImmiClientOptions = {
    host: config.BACKEND_URL ?? "",
    path: "",
    getAuthHeaders: azureAuthBearerHeader,
    enforceTrailingSlash: config.ENFORCE_URL_RESOURCE_SLASH === "true",
  };

  const client: Client = createImmiClient(options);
  const dataProvider: DataProvider = createImmiProvider(client);
  const queryClient = new QueryClient();

  return {
    client,
    dataProvider,
    queryClient,
    config,
  };
})();

// @ts-ignore
window.client = global.client;

type ResourceConfig = Omit<CreateResourceServiceConfig, "resource" | "client" | "dataProvider" | "queryClient">;

/**
 * A utility function to create a resource service with the global client, dataProvider, and queryClient.
 * @param resource The resource name
 * @param config Optional additional config parameters
 */
export const createAppResourceService = <T extends Resource>(resource: string, config: ResourceConfig = {}) =>
  createResourceService<T>({
    resource,
    client: global.client,
    dataProvider: global.dataProvider,
    queryClient: global.queryClient,
    defaultQueryOptions: {
      onError: (error: any) => {
        if(error.message) {
          notify.error(error.message, { autoClose: 5000 });
        } else {
          notify.error("An error has occurred in the backend. Contact Support", { autoClose: 5000 });
        }
      },
    },
    ...config,
  });
