import { get } from 'lodash';
import React, { useState } from 'react';
import { NodeRenderer, SortableTreeWithoutDndContext, TreeItem } from 'react-sortable-tree';
import { documentService } from 'services';
import styled from 'styled-components';
import { colors } from 'theme';

import {
  EmptyContent, getCategories, getNodeDisplayData, getNodeValue, GraphNodeWithChildren, scrollable, useCurrentRelease
} from '@common';
import { EditorStyles } from '@common/editor/components';
import { createSourcePlugins, SourceEditor } from '@common/editor/RuleAuthor/SourceEditor';
import { trimDocName } from '@common/hooks/HooksDocuments';
import { Button, Flex, Stack } from '@components';
import MainExplanation from '@components/GraphVisualisation/components/AttributeInfo/AttributeExplanation';
import {
  AttributeValueTabs
} from '@components/GraphVisualisation/components/AttributeInfo/AttributeValues';
import RuleGraphProvider from '@components/GraphVisualisation/providers/RuleGraphProvider';
import { Popover, PopoverContent } from '@components/radix';
import {
  ResizableHandle, ResizablePanel, ResizablePanelGroup
} from '@components/radix/Resizable/Resizable';
import TabView from '@components/tabs/TabView';
import { ArrowleftIcon, ChevronrightIcon, LoadingDots } from '@icons';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { useModalContext } from '@modals/Modals';
import { Breadcrumbs } from '@mui/material';
import { GraphNode, RuleDocContentsV2 } from '@packages/commons';
import { AttributeUsage } from '@pages/models/release/DataModel/route/Attributes/AttributeUsage';
import { useParsedGraph } from '@pages/models/release/GraphContext';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { PopoverTrigger } from '@radix-ui/react-popover';
import { PlateProvider } from '@udecode/plate-core';

import CategoryTheme from './CategoryTheme';
import { AttributeValue } from '@common/graph/AttributeValue';
import { useTranslation } from 'react-i18next';


const CommonTooltipLabelWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;


const useOutcomeData = (session: any) => {
  // const [node, setNode] = useState<GraphNodeWithType | null>(null);
  // node history, to allow for backtracking
  const [history, setHistory] = useState<GraphNode[]>(() => []);

  // node is top of history stack
  const node = history[history.length - 1];
  // this one hard overrides all history (useful for sidebar navigation)
  const selectNode = (node: GraphNode) => {
    setHistory([node]);
  };
  // history stack manipulation, useful for clicking within the panel
  const pushNode = (node: GraphNode) => {
    setHistory((value) => [...value, node]);
  };
  const popNode = () => {
    setHistory((value) => value.slice(0, -1));
  };
  const clearHistory = () => {
    setHistory([]);
  };
  const goTo = (index: number) => {
    setHistory((value) => value.slice(0, index + 1));
  };

  return { session, node, history, selectNode, pushNode, popNode, clearHistory, goTo };
};

type OutcomeData = ReturnType<typeof useOutcomeData>;

const OutcomeContext = React.createContext<OutcomeData>(null as unknown as OutcomeData);

const useOutcomeContext = () => {
  const context = React.useContext(OutcomeContext);
  if (!context) {
    throw new Error('useOutcomeContext must be used within a OutcomeProvider');
  }
  return context;
};

const OutcomeProvider = ({ children, session }) => {
  const outcomeData = useOutcomeData(session);
  return <OutcomeContext.Provider value={outcomeData}>{children}</OutcomeContext.Provider>;
};

const Panel = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.5rem;
  height: 100%;
  align-items: stretch;
  padding: 0.5rem;
  background-color: ${(p) => p.theme.palette.background.light};

  #outcome {
    grid-column: span 1;
    border-radius: 0.25rem;
    background-color: ${(p) => p.theme.palette.background.default};
    border: 1px solid ${(p) => p.theme.palette.background.border};
  }

  #overview {
    grid-column: span 3;
    border-radius: 0.25rem;
    background-color: ${(p) => p.theme.palette.background.default};
    border: 1px solid ${(p) => p.theme.palette.background.border};

    [class*="TabView-root"] {
      padding: 0.5rem 0.5rem 0 0.5rem;
    }
  }
`;

type CategoryData = {
  node: GraphNodeWithChildren;
  outcome: any;
};

type CategoryItem = TreeItem<CategoryData>;

const CategoryTree = styled(SortableTreeWithoutDndContext<CategoryData>)`
  padding: 0 0.5rem;
`;

const CategoryIndicator = styled.div`
  display: inline-flex;
  flex: 1;
  height: 0.5rem;
  border-radius: 0.25rem;
`;

const CategorySummary = ({ categories }: { categories: any[] }) => {
  console.log("summary", categories);
  return (
    <Flex gridGap="0.25rem" padding="1rem">
      {categories.map((category) => {
        const node = get(category, 'node');
        const { backgroundColor } = getNodeDisplayData(node);
        const value = ((): string | null => {
          if(typeof node !== 'object' || node == null) return null;

          const value = node.input || node.derived;
          if(typeof value !== 'number' && typeof value !== 'string' && typeof value !== 'boolean') {
            return null;
          }

          return String(value);
        })();
        const tooltipLabelJSX = (
          <CommonTooltipLabelWrap>
            <Typography variant='caption'>Category: {get(category, 'title', null)}</Typography>
            <Typography variant='caption'>Attribute: {get(node, ['description'], null)}</Typography>
            <Typography variant='caption'>Value: {value}</Typography>
          </CommonTooltipLabelWrap>
        );
        return (
          <Tooltip title={tooltipLabelJSX}>
            <CategoryIndicator key={category.node.id} style={{ backgroundColor }} />
          </Tooltip>
        )
      })}
    </Flex>
  );
};

const useCategoryTree = (goal: string) => {
  const graph = useParsedGraph();
  const categories = getCategories(goal, graph);

  const mapCategory = (node: GraphNodeWithChildren): CategoryItem => {
    // const outcome = node.derived ? node.value : node.input;
    const outcome = getNodeValue(node);
    return {
      title: node.categoryName || node.description,
      subtitle: node.id,
      expanded: node.children.length > 0,
      children: node.children.map((child) => mapCategory(child)),
      node: { ...node } as GraphNodeWithChildren,
      outcome,
    };
  };

  return useState(() => {
    // map categories into required tree format
    return categories.map(mapCategory);
  });
};

const CategoryListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 3rem;
  padding: 0 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  overflow: hidden;

  .index {
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    &[data-level="1"] {
      border-radius: 50%;
      border: 1px solid ${(p) => p.theme.palette.background.border};
      background-color: ${(p) => p.theme.palette.background.default};
    }
  }

  svg {
    // animate rotation
    transition: transform 0.2s;
  }

  &:hover {
    background-color: ${(p) => p.theme.palette.background.hover};
  }

  &[data-expanded="true"] {
    svg {
      transform: rotate(90deg);
    }
  }

  &[data-selected="true"] {
    position: relative;
    border: 1px solid ${(p) => p.theme.palette.background.darkBorder};
    ::before {
      content: "";
      position: absolute;
      left: 0;
      width: 0.25rem;
      height: 100%;
      background-color: ${(p) => p.theme.palette.primary.main};
    }
  }
`;

const CategoryOutcome = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: ${(p) => p.theme.palette.background.light};
  border: 1px solid ${(p) => p.theme.palette.background.border};
`;

const CategoryNode: NodeRenderer<CategoryData> = (props) => {
  const { node: current, selectNode } = useOutcomeContext();
  const {
    node, toggleChildrenVisibility, treeIndex, path,
  } = props;
  const { expanded, title } = node;

  // convert path into 1 indexed string
  const displayNum = path
    .map((p) => (typeof p === "string" ? Number(p) : p) + 1)
    // p after first needs to subtract previous index
    .map((p, i, arr) => (i === 0 ? p : p - arr[i - 1]))
    .join(".");

  const onToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleChildrenVisibility?.({ node, path, treeIndex });
  }

  const tooltipTitleJSX = (
    <CommonTooltipLabelWrap>
      <Typography variant='caption'>{title ?? ""}</Typography>
      <Typography variant='caption'>Attribute: {get(node, ['node', 'description'], null)}</Typography>
    </CommonTooltipLabelWrap>
  );

  return (
    <CategoryListItem data-selected={node.node.id === current?.id} data-expanded={expanded} onClick={() => selectNode(node.node)}>
      <span data-level={path.length} className="index">{displayNum}</span>
      <Tooltip title={tooltipTitleJSX} placement="right">
        <Typography noWrap>{title}</Typography>
      </Tooltip>
      <AttributeValue hideTemporal node={node.node} />
      {
        node.children && node.children.length > 0 ? (
          <IconButton onClick={onToggle}>
            <ChevronrightIcon />
          </IconButton>
        ) : null
      }
    </CategoryListItem>
  );
};

const OutcomePanel = () => {
  const graph = useParsedGraph();
  const { session, selectNode } = useOutcomeContext();
  const [categories, setCategories] = useCategoryTree(session.goal);
  const [info, setInfo] = useState(true);

  const goal = graph.node(session.goal) ?? { derived: undefined, description: "No goal" };

  return (
    <Stack id='outcome'>
      <Flex gridGap="1rem" alignItems="flex-start" padding="1rem" onClick={() => selectNode(goal)} style={{cursor: 'pointer'}}>
        <Stack flex={1}>
          <Typography variant="h3">Decision Outcome</Typography>
          {/* <DateTime value={session.lastModified} prefix="Last update:" style={{ color: colors.reallyGrey }} /> */}
          <Typography variant="caption" style={{ color: colors.reallyGrey }}>{goal.description}</Typography>
        </Stack>

        <AttributeValue size="lg" hideTemporal node={goal} labelProps={{ style: { width: "140px" } }} />
      </Flex>
      <CategorySummary categories={categories} />
      <Stack gridGap="0.5rem" padding="1rem">
        <Flex gridGap="0.5rem" alignItems="center">
          <Typography style={{ fontWeight: 600 }}>Assessment categories</Typography>
          <Tooltip title="Toggle info" placement="right">
            <IconButton onClick={() => setInfo(!info)}>
              <InfoCircledIcon color={colors.reallyGrey} />
            </IconButton>
          </Tooltip>
        </Flex>
        {
          info
            ? <Typography style={{ color: colors.reallyGrey }}>Below are the key categories that make up this assessment and their associated outcomes</Typography>
            : null
        }
      </Stack>
      <CategoryTree
        treeData={categories}
        onChange={setCategories}
        canDrag={false}
        // rowHeight={52}
        // scaffoldBlockPxWidth={32}
        nodeContentRenderer={(props) => <CategoryNode {...props} />}
        theme={CategoryTheme}
      />

    </Stack>
  );
};

const HistoryBreadcrumb = () => {
  const { history, popNode, goTo } = useOutcomeContext();
  const previous = history[history.length - 2];
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ height: "1.5rem" }}>
      <IconButton disabled={history.length <= 1} onClick={popNode}>
        <ArrowleftIcon />
      </IconButton>
      <Popover>
        <PopoverTrigger disabled={history.length <= 2}>
          <span>...</span>
        </PopoverTrigger>
        <PopoverContent>
          <Stack>
            {
              history.map((node, index) => (
                <Button key={node.id} variant="text" onClick={() => goTo(index)}>{node.description}</Button>
              ))
            }
          </Stack>
        </PopoverContent>
      </Popover>
      {
        previous ? (
          <span onClick={popNode}>{previous.description}</span>
        ) : null
      }
    </Breadcrumbs>
  )
}

const OverviewHeader = () => {
  const { close } = useModalContext();
  const { node, popNode } = useOutcomeContext();
  // const outcome = getNodeValue(node) ?? "pass";

  const onHistoryClick = () => {
    console.log("History click");
  };

  return (
    <Stack gridGap="0.25rem" padding="1rem" borderBottom="1px solid #e5e5e5">
      {/* <HistoryBreadcrumb /> */}
      <Flex style={{ gap: "1rem" }}>
        <IconButton disabled={history.length <= 1} onClick={popNode}>
          <ArrowleftIcon />
        </IconButton>
        <Stack gridGap="0.5rem" flex={1}>
          <Typography variant="h4">{node.description}</Typography>
          {
            node.definedIn ? <AttributeUsage node={node} showUsedIn={false} onClick={close} /> : null
          }
        </Stack>
        <Tooltip title="View history" placement="left">
          <CategoryOutcome onClick={onHistoryClick}>
            <Typography>Outcome</Typography>
            <AttributeValue hideTemporal node={node} />
          </CategoryOutcome>
        </Tooltip>
      </Flex>
    </Stack>
  );
};

const ATTRIBUTE_SOURCE_ID = "attribute-source";

const EmptyAttributeSourceWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AttributeSource = ({ node }: { node: GraphNode }) => {
  const release = useCurrentRelease();
  const { t } = useTranslation();

  // Find the document that this is defined in
  if (!node || !node.definedIn) return (<p>Invalid node</p>);
  let path = trimDocName(String(node.definedIn)).split('/');
  path.shift();
  let info = get(release.documents, path);
  const { data, isLoading, isError } = documentService.useGetOne(info.reference, {});

  if (isLoading) {
    return <EmptyContent key="loading" messages={["Loading source", <LoadingDots />]} />;
  }

  if (!data || isError) {
    return <EmptyContent key="error" messages={["Error getting document"]} />;
  }

  if (data.version === 1) {
    return <EmptyContent key="unsupported" messages={["Unsupported document version 1"]} />;
  }

  const contents = data.contents as RuleDocContentsV2;
  const source = contents.source;

  const isSourceEmpty = (() => {
    if(Array.isArray(source) === false || source.length !== 1) return false;

    const first: unknown = source[0];
    if(
      typeof first !== 'object'
      || first === null
      || !('type' in first)
      || first.type !== 'p'
      || !('children' in first)
      || Array.isArray(first.children) === false
      || first.children.length !== 1
    ) return false;

    const firstChild: unknown = first.children[0];
    if(typeof firstChild !== 'object' || firstChild === null || !('text' in firstChild) || firstChild.text !== '') {
      return false;
    }

    return true;
  })();

  return (
    <PlateProvider
      id={ATTRIBUTE_SOURCE_ID}
      plugins={createSourcePlugins()}
      initialValue={source}
    >
      <EditorStyles />

      {isSourceEmpty
        ? (
          <EmptyAttributeSourceWrap>
            <Typography>{t('sessions.source_section_is_blank')}</Typography>
          </EmptyAttributeSourceWrap>
        )
        : (
        <SourceEditor
          id={ATTRIBUTE_SOURCE_ID}
          editableProps={{ readOnly: true }}
        />
      )}
    </PlateProvider>
  )
};

const DocumentPanel = () => {
  const { node } = useOutcomeContext();
  return (
    <TabView content={[
      { label: "Rules", component: <MainExplanation attr={node} /> },
      { label: "Source", component: <AttributeSource node={node} /> },
      { label: "Usage", component: <AttributeUsage node={node} showUsedIn={true} /> },
    ]} tabPanelClassName={scrollable} />
  );
};

const InfoPanel = () => {
  return (
    <AttributeValueTabs />
  );
};

const ResizePanel = styled(ResizablePanel)`
  display: flex;
  flex-direction: column;
  overflow: auto !important;

  [role="tabpanel"] > div {
    padding: 0 0.5rem;
  }
`;

const OverviewPanel = () => {
  const graph = useParsedGraph();
  const { node, pushNode } = useOutcomeContext();
  const isInput = node.definedIn === undefined;

  const onNodeChange = (n: GraphNode) => {
    // block if its the same node
    if (n?.id !== node.id) {
      console.log('Node change', n);
      pushNode(n);
    }
  };

  return (
    <Stack id="overview" height='100%' overflow='auto'>
      <RuleGraphProvider onSelectedNodeChange={onNodeChange} startNode={node} debug={true} graph={graph} >
        <OverviewHeader />
        <ResizablePanelGroup direction="horizontal">
          <ResizePanel minSize={20} maxSize={80}>
            {
              isInput
                ? (
                  <Stack gridGap="1rem" padding="0 0.5rem" mt="1rem">
                    <AttributeUsage node={node} showUsedIn={true} />
                  </Stack>
                ) : <DocumentPanel />
            }
          </ResizePanel>
          <ResizableHandle />
          <ResizePanel minSize={20} maxSize={80}>
            <InfoPanel />
          </ResizePanel>
        </ResizablePanelGroup>
      </RuleGraphProvider>
    </Stack>
  );
};

export const DecisionPanel = ({ session }: { session: any }) => {
  return (
    <OutcomeProvider session={session}>
      <Panel>
        <OutcomePanel />
        <OutcomeContext.Consumer>
          {
            ({ node }) => node
              ? <OverviewPanel />
              : <EmptyContent id="overview" messages={["Select a category"]} />
          }
        </OutcomeContext.Consumer>
      </Panel>
    </OutcomeProvider>
  );
};
