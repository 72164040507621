import { sortBy } from "lodash";
import groupBy from "lodash/groupBy";
import setWith from "lodash/setWith";
import get from "lodash/get";
import set from "lodash/set";
import { getIndexForNode, getIndexForNodeTooltip } from "@common";
import { Tooltip } from "@material-ui/core";


const getNodeResult = (node) => {
  if (!node) return;
  if (typeof node.input !== "undefined") return node.input;
  if (typeof node.derived !== "undefined") return node.derived;

  return undefined;
};


export const mappingDataToTreeItem = (graph, debug, release) => {
  const mappingData = {
  };
  let entityPaths = {
    global: ''
  };

  if (!debug) {
    // Build the hierarchy of relationships
    // We do this because when not in the debug mode our entity nodes won't
    // have any information about their parents (but in debug mode they do)
    // So we have to build that manually
    if (release.relationships && release.relationships.length > 0) {
      let rels = release.relationships;
      let bySource = groupBy(rels, 'source');
      if (!bySource.global) return; // Shouldn't happen
      const buildEntities = (entities, path = '') => {
        entities.forEach(rel => {
          if (!rel.containment) return; // Inferred entities don't have attributes to show
          entityPaths[rel.name] = `${path}${rel.name}.0.`;

          if (bySource[rel.target]) {
            buildEntities(bySource[rel.target], `${entityPaths[rel.name]}`);
          }
        });
      }
      buildEntities(bySource.global);
    }
  }
  graph.nodes().forEach(n => {
    const node = graph.node(n);
    if (!node) return;
    let path;
    if (debug) {
      if (node.hidden) return;
      if (node.entity === 'global') path = node.id;
      else {
        path = node.path.split('/');
      }
    } else {
      if (node.hidden) {
        // In non-debug mode we just have hidden nodes for the instance, and they have no parent/index infomation
        // All the work we did above was so that we would have an index for them
        // We just assume they are at 0th index for everything and all belong to the same instance
        path = `${entityPaths[node.entity]}${node.id}`;
      } else path = node.id;
    }
    const val = debug ? getNodeResult(node) : node.type;
    setWith(mappingData, path, {
      id: node.path || node.id,
      value: val,
      description: node.description
    }, Object)
  });

  const sort = (data) => {
    return sortBy(Object.entries(data), (a) => {
      // We've coerced numeric ids into object keys above
      if (a[1].description) return a[1].description;
      else return `00${a[0]}`; // the 00 forces these to sort first
    })
  }
  // Now turn into child structure
  const processChildren = (attrs, parent) => {
    return attrs.map(([id, attr]) => {
      if (!attr.id) {
        // I'm an entity instance. This is because we get passed an object of instances
        // with the keys being the indexes, rather than an array. This is to handle
        // non numeric indexes that could be passed
        let sub_children = [];
        if (debug) {
          sub_children = Object.keys(attr).map(instance => {
            let info = getIndexForNode({
              entity: id,
              index: instance,
              parent_path: parent
            }, graph);
            console.log(info);
            let content = (info && info.hint !== undefined) ? (
              <Tooltip title={getIndexForNodeTooltip(info)}><span>{info.value}</span></Tooltip>
            ) : instance;
            return {
              id: `${parent ? `${parent}/` : ''}${id}/${instance}`,
              info: {
                text: content,
                value: 'Instance'
              },
              children: processChildren(sort(attr[instance]), `${id}/${instance}`)
            }
          })
        } else {
          // there is only one
          sub_children = processChildren(sort(attr[0]));
        }
        return {
          id: `${parent || ''}${id}`,
          info: {
            text: id,
            value: 'Entity'
          },
          children: sub_children
        };
      } else {
        return {
          id: attr.id,
          info: {
            text: attr.description,
            value: attr.value,
            nodeId: attr.id
          },
          children: []
        }
      }
    });
  }
  console.log(mappingData);
  let children = processChildren(sort(mappingData))
  return { children: children, id: "global", info: { text: "Global", value: "Entity" } };

}