import moment from 'moment';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'theme';

import {
  AttributeIcon, getGraphNodeTypeColor, getNodeType, GraphCounts, GraphNodeType, scrollable,
  useGraphStatistics
} from '@common';
import { useFullRelease } from '@common/hooks_useFullRelease';
import { Flex, Label, Stack } from '@components/meta';
import { VersionhistoryIcon } from '@icons';
import { showModal } from '@imminently/imminently_platform';
import { Tooltip, Typography } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { GraphNode } from '@packages/commons';

type StatChipProps = {
  label: ReactNode;
  value: number;
  color: string;
};

const StatChip = ({ label, value, color }: StatChipProps) => (
  <Stat color={color}>
    <span>{label}</span>
    <Count>{value}</Count>
  </Stat>
);

export const GraphStatisticsBar = ({ counts, ...props }: { counts: GraphCounts } & React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  return (
    <Flex gridGap="0.5rem" {...props}>
      <StatChip label={<span style={{ color: "white" }}>{t('debugger.stats_total_attributes')}</span>} value={counts.total} color={colors.kindaBlack} />
      <StatChip label={t('debugger.stats_inputs')} value={counts.input} color={getGraphNodeTypeColor(GraphNodeType.INPUT)} />
      <StatChip label={t('debugger.stats_derived')} value={counts.derived} color={getGraphNodeTypeColor(GraphNodeType.DERIVED)} />
      <StatChip label={t('debugger.stats_goals')} value={counts.goals} color={getGraphNodeTypeColor(GraphNodeType.GOAL)} />
    </Flex>
  );
};

const Statbar = styled(GraphStatisticsBar)`
  padding: 0.5rem 2rem;
  border-bottom: 1px solid ${p => p.theme.palette.background.border};
  border-top: 1px solid ${p => p.theme.palette.background.border};
`;

const Stat = styled(Label)`
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid ${p => p.theme.palette.background.border};
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 1rem;
  /* width: 1rem; */
  height: 1rem;
  padding: 0 0.25rem;
`;

const AttributeContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  align-items: center;
  border: 1px solid ${p => p.theme.palette.background.border};
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.background.hover};
  }

  #icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    border: 1px solid ${p => p.theme.palette.background.border};
    padding: 0.5rem;
  }

  /* svg {
    width: 2rem;
    height: 2rem;
  } */
`;

const Attribute = ({ node, graph }: { node: GraphNode, graph: any }) => {
  const type = getNodeType(graph, node.id) ?? GraphNodeType.INPUT; // default to input
  return (
    <AttributeContainer>
      <div id="icon" style={{ backgroundColor: getGraphNodeTypeColor(type) }}>
        <AttributeIcon type={node.type} />
      </div>
      <Typography>{node.description}</Typography>
    </AttributeContainer>
  );
};

export const GraphStatistics = ({ graph, debug = false }) => {
  const release = useFullRelease();
  const { t } = useTranslation();
  const { counts, interestingNodes } = useGraphStatistics(graph, debug);

  if (!graph) return <Typography>No graph available</Typography>;

  const lastUpdated = release ? moment(release.lastModified).format('DD/MM/YYYY - HH:mm:ss') : null;

  const helpTooltip = t('debugger.stats_help_tooltip');

  return (
    <Stack style={{ padding: "1rem 0 0 0", gap: 0 }}>
      <Statbar counts={counts} />

      <Flex style={{ padding: "3rem 0 0.5rem 0", alignItems: "flex-start" }}>
        <Stack style={{ flex: 1, paddingLeft: "2rem", gap: "0.5rem" }}>
          <Flex gridGap="0.5rem" >
            <Typography variant="h4">{t('debugger.important_nodes')}</Typography>
            <Tooltip title={helpTooltip}>
              <HelpOutline style={{ width: "1rem", height: "1rem" }} />
            </Tooltip>
          </Flex>
          <Typography>{t('debugger.important_nodes_description')}</Typography>
        </Stack>

        <Stack style={{ flex: 1, maxHeight: 300, paddingRight: "1rem", gap: "0.5rem" }} className={scrollable}>
          {
            interestingNodes.map(node => (
              <Attribute key={node.id} node={node} graph={graph} />
            ))
          }
        </Stack>
      </Flex>
      {
        lastUpdated ? (
          <Flex style={{ justifyContent: "flex-end", gap: "0.5rem", color: colors.reallyGrey, padding: "0.5rem 2rem 1rem 2rem" }}>
            <VersionhistoryIcon />
            <Typography variant="caption">{t('updated_at')}: {lastUpdated}</Typography>
          </Flex>
        ) : null
      }
    </Stack>
  );
};

export const useGraphStatisticsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (graph: any) => {
    dispatch(showModal({
      open: true,
      title: t('debugger.graph_stats_title'),
      maxWidth: 'md',
      style: { padding: 0 }, // we want control of padding on the body
    }, <GraphStatistics graph={graph} />
    ));
  };
};