import { State, REDUX_PROP, aCreators } from "../redux/fullRelease/reducer";
import { useDispatch, useSelector } from "react-redux";
import { Release } from "@packages/commons";
import { crudGetOne } from "@imminently/imminently_platform";

export const useFullRelease = (): Release | null => useSelector(s => {
  const { release } = s['scope'];
  const fullReleaseState: State = s[REDUX_PROP];
  // if we don't have a release in scope, or its not the one loaded, return null
  if (!release || release !== fullReleaseState.value?.id) return null;
  return fullReleaseState.value;
});

export const useFullReleaseStrict = (): Release => {
  const maybeRelease = useFullRelease();
  if (maybeRelease === null) throw new Error("Must be used strictly when we have full release");

  return maybeRelease;
};

export const useUpdateFullRelease = () => {
  const dispatch = useDispatch();
  const release = useFullRelease();

  return () => {
    if (release) {
      // update legacy release as well
      dispatch(crudGetOne("releases", release.id));
    }
    dispatch(aCreators.requestToActualize());
  };
};
