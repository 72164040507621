import type { ContactOtherDataNodeLeaf, ContactOtherDataNodeDir } from './core';


export type FindByPredicateInOtherDataArg = {
  node: ContactOtherDataNodeLeaf | ContactOtherDataNodeDir;
  predicate: (node: FindByPredicateInOtherDataArg['node']) => boolean
}
export type FindByPredicateInOtherDataRtrn = null | ContactOtherDataNodeLeaf | ContactOtherDataNodeDir;

export const findByPredicateInOtherData = (arg: FindByPredicateInOtherDataArg): FindByPredicateInOtherDataRtrn => {
  const { node, predicate } = arg;
  if(predicate(node)) return node;

  if(node.type !== 'dir') return null;

  return node.children.reduce< FindByPredicateInOtherDataRtrn >(
    (a, child) => a === null ? findByPredicateInOtherData({ node: child, predicate }) : a,
    null
  );
}
