import React from "react"
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import { PrimaryButton } from "@components/buttons";
import { TextField } from "@components/input";
import { Switch } from "@components/switch"
import { scrollableMixin } from "@common";
import get from 'lodash/get';
import Typography from "@material-ui/core/Typography";
import { useModalContext } from "@modals/Modals";
import { Skeleton } from "@material-ui/lab";
import { notify } from "@common/notifications";
import { LoadingDotsJSX } from "@icons";


const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem;
  /* width: 50vw;
  height: 500px;
  max-height: 65vh; */
`;

const HeadingRow = styled.div`
  display: flex;

  >* {
    width: 50%;
  }
`;
const DataWrap = styled.div`
  display: flex;
  /* height: 19rem; */
  flex-grow: 1;

  ${scrollableMixin};
`;


const Table = styled.table`
  align-self: flex-start;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0.25rem 0.5rem;

  td, th {
    width: 50%;
  }

  th {
    font-weight: 700;
    font-size: 1.25rem;
    text-align: left;
    position: sticky;
    top: 0;
  }
`;

const AutocompleteLoading = styled(Skeleton)`
  height: 2.5rem;
  transform: none;
`;

/**
 * This section will describe some fundamental assumptions and
 * guidelines used when developing this component.
 *
 * Basically there are two "sources" (kinda) of information:
 * - we first extract all attributes from the graph that are related to
 * an entity that is currently considered to be a contact
 * - then we query special endpoint that returns a mapping stating which
 * attributes are mapped and which are not
 * It seems taht mapping from BE might return containing only subset of
 * all attributes in a graph and potentially might not have attributes that
 * we extracted directly from the graph using entity name. But to build
 * exhaustiv set of options we need to have all possible attributes and
 * then select only those that have no `mappedTo` property set or (I guess)
 * are not yet selected in current modal.
 *
 * Based on this there seems to be two data structures reqired for this
 * component to function properly:
 * - set of all possible values (from BE combined with extracted from
 * graph) with their corresponding mappings
 * - local editable mapping state that is based on attributes extracted from
 * current rule graph
 *
 * With these two structures we should be able to get all the available
 * options: first select those BE attributes that have no "mappedTo", then
 * additionally remove those options that are currently mapped to something
 * using local editable state.
 *
 * NOTE: two described structures are really coupled with one another, so
 * it seems best to have them inside the same state. That way we would be
 * able to derive mapFromBE using attributes from graph and set mapping
 * of attributes from graph using mapFromBE
 */
/** */

export type AttrMetadataMap = Record<string, { mappedTo?: string, disabled?: boolean } | undefined>;
export type ContactsMappingModalProps = {
  /**
   * basically array of descriptions of all the nodes that belong to\
   * current contact entity
   */
  fieldsOnContactObject: string[];
  getMapping: () => Promise<{
    entity: string;
    fields: AttrMetadataMap;
  }>;
  save: (v: AttrMetadataMap) => unknown;
};

type LocalMapping = {
  loading: boolean;
  mapFromBE: AttrMetadataMap;
  editableMap: AttrMetadataMap;
};
const getLocalMapping = (): LocalMapping => ({
  loading: false,
  mapFromBE: {},
  editableMap: {},
});

export const ContactsMappingModal: React.FC<ContactsMappingModalProps> = p => {
  const {
    fieldsOnContactObject,
    getMapping,
    save,
  } = p;
  const { t } = useTranslation();
  // const mappedAsIsStr = t('contacts_mapping_modal.mapped_as_is');
  const specialOptions = [{
    label: t('contacts_mapping_modal.mapped_as_is'),
    value: undefined, // TODO - consider using "mappedAsIs" as the value (the backend does support this now)
  }, {
    label: t('contacts_mapping_modal.first_name'),
    value: "firstName",
  }, {
    label: t('contacts_mapping_modal.last_name'),
    value: "lastName",
  }, {
    label: t('contacts_mapping_modal.name'),
    value: "name",
  }]
  const { close } = useModalContext();

  const [isSaving, setIsSaving] = React.useState(false);
  const [localMapping, setLocalMapping] = React.useState<LocalMapping>(
    fieldsOnContactObject.reduce(
      (a, attr) => {
        // a.mapFromBE[attr] = undefined;
        a.editableMap[attr] = undefined;

        return a;
      },
      getLocalMapping(),
    )
  );

  React.useEffect(() => {
    (async () => {
      try {
        setLocalMapping(prev => ({ ...prev, loading: true }));

        const { fields } = await getMapping();

        setLocalMapping(prev => {
          const nextMapFromBE = Object.keys(fields).reduce<LocalMapping['mapFromBE']>(
            (a, key) => {
              const v = fields[key];
              a[key] = v && { mappedTo: v.mappedTo, disabled: v.disabled };

              return a;
            },
            { ...prev.mapFromBE }
          );
          const nextEditableMap = Object.keys(prev.editableMap).reduce<LocalMapping['editableMap']>(
            (a, key) => {
              const v = fields[key];
              a[key] = v && { mappedTo: v.mappedTo, disabled: v.disabled };

              return a;
            },
            { ...prev.editableMap }
          );


          return {
            loading: false,
            editableMap: nextEditableMap,
            mapFromBE: nextMapFromBE,
          };
        });
      } catch (e) {
        console.error('0C6pz0Ojh6 | Smth went wrong', e);
        notify(get(e, 'message') || 'Error. Please contact support.', { type: 'error' });
      }
    })();
  }, [setLocalMapping, getMapping]);

  // /**
  //  * attributes that have no mappedTo value neither in BE map, nor\
  //  * in editable map
  //  */
  // const attrsThatHaveNoMappedTo = React.useMemo(
  //   () => {
  //     const merged = {
  //       ...localMapping.mapFromBE,
  //       ...localMapping.editableMap
  //     };
  //     return Object.entries(merged)
  //       .filter(([_, value]) => value && value.mappedTo === undefined && value.disabled !== true)
  //       .map(([attr]) => attr);
  //     // A valid target is one where the mapping exists (value is truthy) and the mappedTo value is not defined, and the mapping is not disabled
  //     // if value is undefined (falsy) then this means this mapping does not exist (either locally or on the server)
  //   },
  //   [localMapping]
  // );
  // The below code replaces the functionality of the above code,
  // Rather than defining valid mapping targets as other mappings that are mapped as is,
  // we look directly at all the mappedTo values of all mappings, as these will be the actual names of properties on created contacts.
  const specialOptionValues = specialOptions.map(o => o.value);
  const mappedToTargets = React.useMemo(() => {
    const targets: Set<string> = new Set();
    for (const [attr, mapping] of Object.entries(localMapping.mapFromBE)) {
      if (mapping && mapping.disabled !== true) {
        const target = mapping.mappedTo ?? attr;
        if (!specialOptionValues.includes(target)) {
          targets.add(target);
        }
      }
    }
    return [...targets];
  }, [localMapping]);

  // We don't need this code anymore as the back-end will now fix double mappings through the transfer principle
  // We should allow the user to edit a mapping that is the target of another mapping as to prevent this would
  // make their life harder as they search for the other mapping that maps to the target they want to change.
  // /**
  //  * attributes that are not set as a value of mappedTo property \
  //  * for any attribute
  //  */
  // const attrsThatAreNotMappedTo = React.useMemo(
  //   () => {
  //     const merged = { ...localMapping.mapFromBE, ...localMapping.editableMap };

  //     const attrsThatAreMappedTo = Object.values(merged).reduce< Record< string, true > >(
  //       (a, value) => {
  //         if(value !== undefined && value.mappedTo !== undefined) {
  //           a[value.mappedTo] = true;
  //         }

  //         return a;
  //       },
  //       {}
  //     );

  //     return Object.keys(merged).reduce< Record< string, true > >(
  //       (a, attr) => {
  //         if( attrsThatAreMappedTo[attr] !== true ) {
  //           a[attr] = true;
  //         }

  //         return a;
  //       },
  //       {}
  //     );
  //   },
  //   [localMapping]
  // );
  const specialOptionLabels = specialOptions.map(o => o.label)
  const options = React.useMemo(
    () => specialOptionLabels.concat(mappedToTargets),
    [localMapping, specialOptions]
  );

  return (
    <Wrap>
      {/* <HeadingRow>
        <Typography variant='h5'>{t('contacts_mapping_modal.project_data')}</Typography>
        <Typography variant='h5'>{t('contacts_mapping_modal.central_person_data')}</Typography>
        <Typography variant="h5">{t('contacts_mapping_modal.disabled')}</Typography>
      </HeadingRow> */}

      <DataWrap>
        <Table>
          <thead>
            <td><Typography variant='h5'>{t('contacts_mapping_modal.project_data')}</Typography></td>
            <td><Typography variant='h5'>{t('contacts_mapping_modal.central_person_data')}</Typography></td>
            <td><Typography variant='h5'>{t('contacts_mapping_modal.active')}</Typography></td>
          </thead>
          <tbody>
            {
              Object.entries(localMapping.editableMap).map(([key, mapping]) => {
                const value = mapping ? (specialOptions.find((o) => o.value === mapping.mappedTo)?.label ?? mapping.mappedTo) : "";
                // If we have a mapping first check if it's a special mapping and use the label, otherwise just use the mappedTo value
                // This supports { mappedTo: undefined } being set to "mapped as is" label as this is included in the specialOptions with value 'undefined'

                return (
                  <tr key={key}>
                    <td>{key}</td>

                    <td>
                      {localMapping.loading ? <AutocompleteLoading /> : (
                        <Autocomplete
                          disableClearable
                          freeSolo={true}
                          options={options.filter(it => it !== key)}
                          groupBy={(o) => specialOptionLabels.includes(o) ? t('contacts_mapping_modal.special') : t('contacts_mapping_modal.other')}
                          value={value}
                          onChange={(_, option, reason) => {
                            if (option === null) return;
                            if (reason === 'select-option' || reason === 'create-option') {
                              let mappedToValue: string | undefined = option;
                              if (reason === 'select-option') {
                                const isSpecialOption = specialOptions.find(it => it.label === option);
                                mappedToValue = isSpecialOption ? isSpecialOption.value : option
                                // If option is a special option then definitely use the special option value, even if it is undefined.
                                // (If its undefined then the special option was "mapped as is" and undefined is the valid value for this)
                              }
                              setLocalMapping(prev => ({
                                ...prev,
                                editableMap: {
                                  ...prev.editableMap,
                                  [key]: {
                                    ...prev.editableMap[key],
                                    mappedTo: mappedToValue
                                  }
                                },
                              }));
                              return;
                            }
                          }}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                          disabled={mapping?.disabled ?? false}
                        />
                      )}
                    </td>

                    <td>
                      {mapping === undefined ? null : (
                        <Tooltip title={t('contacts_mapping_modal.activate_deactivate_mapping')} placement='right' >
                          <Switch
                            color="secondary"
                            label=""
                            checked={!(mapping?.disabled ?? false)}
                            disabled={value === undefined}
                            onChange={(_, val) => {
                              setLocalMapping(prev => ({
                                ...prev,
                                editableMap: {
                                  ...prev.editableMap,
                                  [key]: {
                                    ...prev.editableMap[key],
                                    disabled: (!val)
                                  }
                                },
                              }));
                            }}
                          />
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </DataWrap>

      <PrimaryButton
        style={{ alignSelf: 'flex-end' }}
        disabled={isSaving}
        onClick={async () => {
          try {
            setIsSaving(true);
            // console.log('saving editable map', localMapping.editableMap);
            await save(localMapping.editableMap);
            close();
          } catch (e) {
            console.error('Nt6Yz29jhQ | Smth went wrong', e);
            notify(get(e, 'message') || 'Error. Please contact support.', { type: 'error' });
          } finally {
            setIsSaving(false);
          }
        }}
      >
        {isSaving ? LoadingDotsJSX : t('save')}
      </PrimaryButton>
    </Wrap>
  )
}
