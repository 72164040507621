import { useState } from "react";
import { Typography } from "@material-ui/core";
import { AttributeCommunicationForm } from "./AttributeCommunicationForm";
import { Button, Dropdown, Flex, Stack, useContextMenu, ContextMenuItem, MenuC as Menu } from "@components";
import { useFormikContext } from "formik";
import { AttributeInfoFormValues } from "./useAttributeForm";
import { Trigger, TriggerTask, TriggerEnrichment, TriggerComms } from "@packages/commons";
import { useTranslation } from "react-i18next";
import { AttributeTaskForm } from "./AttributeTaskForm";
import { AttributeEnrichmentForm } from "./AttributeEnrichmentForm";
import { get } from "lodash";
import { scrollable } from "@common";
import { DeleteIcon } from "@icons";
import { v4 as uuidv4 } from "uuid";

const defaultTrigger = (type: string): Trigger => {
  switch (type) {
    case "task":
      return {
        type: "task",
        title: "",
        interview: "",
        assignment: "",
        dueDate: "",
        approvalLabel: "Approve",
        approvalOutcome: "true",
        rejectionLabel: "Reject",
        rejectionOutcome: "false",
        aiFirstPass: false,
        guidance: "",
      } as TriggerTask;
    case "communication":
      return {
        type: "comms",
        title: "",
        method: "email",
        who: "",
        subject: "",
        body: ""
      } as TriggerComms;
    case "enrichment":
      return {
        type: "enrichment",
        title: "",
        connection: {
          type: "attribute",
          value: "",
        },
        mapping: {
          inbound: {
            type: "goal",
            value: "",
            useDescriptions: false,
          },
          outbound: {
            type: "default",
            value: "",
            useDescriptions: false,
          },
        },
        transform: {
          inbound: {
            type: "file",
            useDescriptions: false,
            value: "",
          },
          outbound: {
            type: "file",
            useDescriptions: false,
            value: "",
          },
        },
      } as TriggerEnrichment;
    default:
      throw new Error(`Unknown trigger type: ${type}`);
  };
};

const TriggerForm = ({ scope }: { scope: string }) => {
  const { values } = useFormikContext<AttributeInfoFormValues>();
  const trigger = get(values, scope) as Trigger;
  if (!trigger) {
    return <Typography>Trigger not found</Typography>;
  }
  switch (trigger.type) {
    case "task":
      return <AttributeTaskForm scope={scope} />;
    case "comms":
      return <AttributeCommunicationForm scope={scope} />;
    case "enrichment":
      return <AttributeEnrichmentForm scope={scope} />;
    default:
      // @ts-ignore, we should never get here
      return <Typography>Unknown trigger type: {trigger.type}</Typography>;
  }
};

export const AttributeTriggers = () => {
  const { t } = useTranslation();
  const { onContextMenu, isOpen, props, close } = useContextMenu();
  const { values, setFieldValue } = useFormikContext<AttributeInfoFormValues>();

  const options = Object.keys(values.triggers).map((t, i) => ({
    key: values.triggers[t].title.length > 0 ? values.triggers[t].title : `Trigger ${i + 1}`,
    value: `triggers.${t}`
  }));

  // the current active trigger scope (string with index)
  // options.length > 0 ? options[0].value : ""
  const [trigger, setTrigger] = useState<string>("");

  const addTrigger = (type: string) => () => {
    const id = uuidv4();
    const newTrigger = defaultTrigger(type);
    setFieldValue(`triggers.${id}`, newTrigger);
    setTrigger(`triggers.${id}`);
    close(); // close the popup
  };

  const deleteTrigger = ([key, value]) => {
    const id = value.split(".")[1];
    const newTriggers = { ...values.triggers };
    delete newTriggers[id];
    setFieldValue("triggers", newTriggers);
    // if the deleted trigger is the current trigger, select first, otherwise unset
    if (trigger === value) {
      setTrigger(Object.keys(newTriggers)[0] ?? "");
    }
  };

  const label = trigger.length > 0
    ? undefined
    : options.length > 0 ? "Select a trigger" : "No triggers";

  // console.log('trigger options', trigger, options, label);

  return (
    <Stack gridGap="1rem" style={{ maxHeight: "50vh" }} className={scrollable}>
      <Typography>Triggers are used to initiate system flows when the attribute value is determined.</Typography>
      <Flex gridGap="0.5rem">
        <Dropdown
          fullWidth
          label={label}
          value={trigger}
          items={options}
          disabled={options.length === 0}
          onChange={(e) => {
            setTrigger(e.target.value);
          }}
          renderValue={value => options.find(it => it.value === value)?.key}
          itemActions={{
            actions: [
              {
                icon: DeleteIcon,
                onClick: deleteTrigger,
              },
            ],
          }}
        />
        <Button variant="contained" color="primary" onClick={onContextMenu}>Add</Button>
      </Flex>
      {/* {values.triggers.map((t, i) => (<div>trigger {i}</div>))} */}
      {
        trigger.length > 0 ? (
          <TriggerForm scope={trigger} />
        ) : (
          <Typography>Select a trigger to edit</Typography>
        )
      }
      {
        isOpen && (
          <Menu {...props}>
            <ContextMenuItem button key="task" onClick={addTrigger("task")}>
              {t("flow.task")}
            </ContextMenuItem>
            <ContextMenuItem button key="communication" onClick={addTrigger("communication")}>
              {t("flow.communication")}
            </ContextMenuItem>
            <ContextMenuItem button key="enrichment" onClick={addTrigger("enrichment")}>
              {t('flow.integration')}
            </ContextMenuItem>
          </Menu>
        )
      }
    </Stack>
  );
};