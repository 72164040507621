import { t } from "i18next";
import type React from "react";

import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { Flex, Stack } from "@components/meta";
import Typography from "@material-ui/core/Typography";
import { FloatingVerticalDivider } from "@udecode/plate";

import type { AliasExpression } from "@packages/compiler/src/Parser";
export const AliasInfo = ({
  expression,
  ...props
}: { expression: AliasExpression } & React.HTMLAttributes<HTMLDivElement>) => {
  const { alias } = expression;
  const release = useFullReleaseStrict();
  const relationship = release.relationships.find((r) => r.id === alias.relationshipId);

  if (!relationship) {
    return (
      <Stack style={{ padding: "1rem" }}>
        <Typography>Relationship alias (pending document save)</Typography>
      </Stack>
    );
  }

  const target =
    relationship.textSingular ??
    release.relationships.find((r) => r.target === relationship.target)?.textSingular ??
    "ERROR MISSING";

  return (
    <Stack
      style={{ padding: "0.5rem 1rem" }}
      {...props}
    >
      <Flex style={{ gap: "0.25rem" }}>
        <Typography>{t("alias")}</Typography>
        <FloatingVerticalDivider />
        <Typography style={{ fontWeight: 500 }}>{target}</Typography>
      </Flex>
    </Stack>
  );
};
