import { useTranslation } from 'react-i18next';
import { useFullRelease, useUpdateFullRelease } from '@common/hooks_useFullRelease';
import { useNotify } from '@common/notifications';
import { documentService, releaseService } from 'services';
import { DocumentType, findReleaseDocument, flattenReleaseDocuments, Release, ReleaseDocument } from '@packages/commons';
import { useScope } from 'redux/scope';
import { getNewFileContents } from './utils';
import { useDispatch } from 'react-redux';
import { showConfirmation } from '@modals/ConfirmationModal';
import get from "lodash/get";

const ensureRootPath = (path: string | string[]) => {
  if (Array.isArray(path)) {
    path = (path[0] === "/" ? path : ["", ...path]).join("/");
  }
  return path.startsWith("/") ? path : `/${path}`;
};

// POST documents
export const useCreateFile = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { release } = useScope();
  const updateRelease = useUpdateFullRelease();
  const { mutateAsync, ...query } = documentService.useCreate();

  const createFile = async (path: string, type: DocumentType, version = 1) => {
    const strPath = ensureRootPath(path);
    const contents = getNewFileContents(type, version);

    try {
      // @ts-ignore type is correct, don't know what TS is complaining about
      const res = await mutateAsync({ release, name: strPath, type, contents, version });
      return res;
    } catch (e) {
      notify.error(t('errors.failed_to_create_document'));
      throw e;
    } finally {
      // update the release object
      updateRelease();
    }
  };

  return { createFile, ...query };
};

// DELETE documents/id?release=id
export const useDeleteFile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { release } = useScope();
  const updateRelease = useUpdateFullRelease();
  const { mutateAsync, ...query } = documentService.useDelete({ meta: { release } });

  const _deleteFile = async (id: string) => {
    try {
      const res = await mutateAsync({ id, payload: {}, params: { release } });
      return res;
    } catch (e) {
      notify.error(t('errors.failed_to_delete_document'));
      throw e;
    } finally {
      // update the release object
      updateRelease();
    }
  };

  const deleteFile = (id: string, onAccept?: () => void) => {
    return new Promise<boolean>((resolve) => {
      dispatch(
        showConfirmation({
          title: t('are_you_sure'),
          body: t('documents.delete_message'),
          onDismiss: () => resolve(false),
          actions: (close) => ({
            primary: {
              name: t('documents.confirm_delete'),
              error: true,
              onClick: () => {
                // notify the caller that the user accepted
                onAccept?.();
                _deleteFile(id)
                  // resolve true as we were successful
                  .then(() => resolve(true))
                  // failed, so act as if it dismissed
                  .catch(() => resolve(false));
                close();
              },
            },
          }),
        }),
      );
    });
  };

  return { deleteFile, ...query };
};

// PATCH documents/id/custom/rename
export const useRenameFile = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const updateRelease = useUpdateFullRelease();
  const { mutateAsync, ...query } = documentService.useUpdate();

  const renameFile = async (id: string, name: string) => {
    try {
      const res = await mutateAsync({ id: `${id}/custom/rename`, payload: { name } });
      return res;
    } catch (e) {
      notify.error(t('errors.failed_to_rename_document'));
      throw e;
    } finally {
      // update the release object
      updateRelease();
    }
  };

  return { renameFile, ...query };
};

// PATCH documents/id/custom/move
export const useMoveFile = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const updateRelease = useUpdateFullRelease();
  const { mutateAsync, ...query } = documentService.useUpdate();

  const moveFile = async (id: string, name: string) => {
    try {
      const res = await mutateAsync({ id: `${id}/custom/move`, payload: { name } });
      return res;
    } catch (e) {
      notify.error(t('errors.failed_to_move_document'));
      throw e;
    } finally {
      // update the release object
      updateRelease();
    }
  };

  return { moveFile, ...query };
};

// PATCH documents/id/custom/lock
export const useLockFile = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const release = useFullRelease();
  const updateRelease = useUpdateFullRelease();
  const { mutateAsync, ...query } = documentService.useUpdate();

  const lockFile = async (id: string, lock: boolean = true) => {
    if (!release) {
      throw new Error('Release not found');
    }
    const doc = findReleaseDocument(release.documents, (doc) => doc.reference === id);
    if (!doc) {
      console.error('Document not found in release', { id, documents: release.documents });
      throw new Error('Document not found in release');
    }
    const { name } = doc as ReleaseDocument
    const payload = { release: release.id, lock, name: ensureRootPath(name) };

    try {
      const res = await mutateAsync({ id: `${id}/custom/lock`, payload });
      return res;
    } catch (e) {
      notify.error(t('errors.failed_to_lock_document'));
      throw e;
    } finally {
      // update the release object
      updateRelease();
    }
  };

  return { lockFile, ...query };
};

// TODO add export file

// POST releases/id/custom/addfolder
export const useCreateFolder = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { release } = useScope();
  const updateRelease = useUpdateFullRelease();
  const { mutateAsync, ...query } = releaseService.useCreate();

  const createFolder = async (path: string) => {
    try {
      // note path is not in the Release definition, as this is a custom action
      const payload = { path: ensureRootPath(path) } as Partial<Release>;
      // this has to use path override, as create doesn't support providing an id
      const res = await mutateAsync({ path: `releases/${release}/custom/addfolder`, payload });
      return res;
    } catch (e) {
      notify.error(t('errors.failed_to_create_folder'));
      throw e;
    } finally {
      // update the release object
      updateRelease();
    }
  };

  return { createFolder, ...query };
};

// DELETE releases/id/custom/removefolder
export const useDeleteFolder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notify = useNotify();
  const release = useFullRelease()
  const updateRelease = useUpdateFullRelease();
  const { mutateAsync, ...query } = releaseService.useDelete();

  const _deleteFolder = async (path: string) => {
    try {
      if (!release) {
        throw new Error('Release not found');
      }
      // note path is not in the Release defintion, as this is a custom action
      const payload = { path: ensureRootPath(path) };
      const res = await mutateAsync({ id: `${release.id}/custom/removefolder`, payload });
      return res;
    } catch (e) {
      notify.error(t('errors.failed_to_delete_folder'));
      throw e;
    } finally {
      // update the release object
      updateRelease();
    }
  };

  const deleteFolder = (path: string, onAccept?: () => void) => {
    return new Promise<boolean>((resolve) => {
      const p = path.split("/").filter(Boolean);
      const folder = get(release?.documents, p, {});
      const num = flattenReleaseDocuments(folder).length;
      dispatch(
        showConfirmation({
          title: t('are_you_sure'),
          body: num === 0 ? t('documents.delete_empty_folder_message') : t('documents.delete_folder_message', { num }),
          onDismiss: () => resolve(false),
          actions: (close) => ({
            primary: {
              name: t('documents.confirm_delete'),
              error: true,
              onClick: () => {
                // notify the caller that the user accepted
                onAccept?.();
                _deleteFolder(path)
                  // resolve true as we were successful
                  .then(() => resolve(true))
                  // failed, so act as if it dismissed
                  .catch(() => resolve(false));
                close();
                resolve(true);
              },
            },
          }),
        }),
      );

    });
  };

  return { deleteFolder, ...query };
};

// PATCH documents/custom/move-folder
export const useMoveFolder = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { release } = useScope();
  const updateRelease = useUpdateFullRelease();
  const { mutateAsync, ...query } = documentService.useUpdate();

  const moveFolder = async (path: string, newPath: string) => {
    try {
      const payload = {
        release,
        oldName: ensureRootPath(path),
        newName: ensureRootPath(newPath),
      };
      const res = await mutateAsync({ id: `custom/move-folder`, payload });
      return res;
    } catch (e) {
      notify.error(t('errors.failed_to_move_folder'));
      throw e;
    } finally {
      // update the release object
      updateRelease();
    }
  };

  return { moveFolder, ...query };
};