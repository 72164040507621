import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { getNodeLabel, scrollable, isTemporal, getNodeValue } from '@common';
import { ModalRoot, WithHeading } from './__modalCommon';
import { formatValue } from './TempTimeline';
import NodeValue from "@components/GraphVisualisation/components/AttributeInfo/NodeValue";
import { StyledTable, StyledTableHead } from '@components/GraphVisualisation/components/AttributeInfo/AttributeExplanation';

export const TemporalValueTable = ({ value, hideHeader = false }) => {
  const { ranges } = isTemporal(value) ? value.temporal ?? value : { ranges: [] };

  return (
    <StyledTable>
      {
        !hideHeader ? (
          <StyledTableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </StyledTableHead>
        ) : null
      }
      <TableBody>
        {
          ranges.map(it => (
            <TableRow key={it.r.start}>
              <TableCell>
                {formatValue(it.r.start, 'start')}
              </TableCell>
              <TableCell>
                {formatValue(it.r.end, 'end')}
              </TableCell>
              <TableCell>
                <NodeValue node={{ derived: it.v }} />
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </StyledTable>
  );
};

/**
 * @param node 
 * @param value Temporal value
 */
export const TemporalValueModal = ({ close, node, value }) => {
  if (!value || !value.temporal) {
    return (
      <ModalRoot title='Timeline' close={close}>
        <Box margin='2rem'>
          <Typography>Cannot show timeline, value is not temporal</Typography>
        </Box>
      </ModalRoot>
    );
  }

  return (
    <ModalRoot title='Timeline' close={close}>
      <Box margin='2rem' overflow='auto' display='flex' flexDirection='column'>
        <WithHeading h='Attribute'>
          <Typography>{getNodeLabel(node)}</Typography>
        </WithHeading>

        <Box marginTop='1.5rem' className={scrollable} paddingRight='1rem'>
          <TemporalValueTable value={value} />
        </Box>
      </Box>
    </ModalRoot>
  );
};